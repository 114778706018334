import React, { useEffect, useState } from 'react';
import 'react-reflex/styles.css';
import {
    faCheckCircle,
    faCircleExclamation,
    faDatabase,
    faEyeSlash,
    faFolder,
    faGears,
    faQuestionCircle,
    faTag,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    alert,
    AlertType,
    Button,
    ConfirmModal,
    Dropdown,
    Input,
    LoadingView,
    Modal,
    TextArea,
} from '../../reusableComponents';
import Toggle from '../../reusableComponents/Toggle';
import { getUrl } from '../../pdfDisplay/utils';
import axios from 'axios';
import { ChartWithControls } from './chartWithControls';
import { iconColor } from './constants';
import { CardEditView } from './chartEditView';
import { isNumeric, toSentenceCase } from '../../reportLayouts/utils';

const maxTitleLength = 60;

const isValidWidgetData = ({
    widgetName,
    selectedRepo,
    queryText,
    chartData,
}) => {
    const result = { isValid: true, reason: '' };
    if (!widgetName) {
        result.isValid = false;
        result.reason = 'Widget Title is required';
    } else if (!selectedRepo) {
        result.isValid = false;
        result.reason = 'Please select a repository';
    } else if (!queryText) {
        result.isValid = false;
        result.reason = 'Query is required';
    } else if (!chartData) {
        result.isValid = false;
        result.reason = 'Please select valid result view';
    } else if (queryText && queryText.length < 15) {
        result.isValid = false;
        result.reason = 'Query should be greater than 15 characters';
    }

    return result;
};

const getRepoIcon = (selectedRepo) => {
    const icon = faDatabase;
    return icon;
};

const getWidgetData = ({
    user,
    selectedRepo,
    selectedDashboard,
    widgetName,
    widgetId,
    isWidgetNameHidden,
    queryText,
    dbQuery,
    queryModifiedAt,
    queryGeneratedAt,
    layoutData,
    selectedChart,
    chartOptions,
}) => {
    const modifiedChartData = {
        chart: selectedChart,
        options: chartOptions,
    };

    const widgetData = {
        email: user.email,
        repoId: selectedRepo.id,
        dashboardId: selectedDashboard.id,
        cardId: widgetId,
        cardName: widgetName,
        isNameHidden: isWidgetNameHidden,

        queryText: queryText,
        query: dbQuery,
        queryModifiedAt: queryModifiedAt,
        queryGeneratedAt: queryGeneratedAt,

        chartData: modifiedChartData,

        layoutDesign: layoutData,
    };

    return widgetData;
};

const Container = ({
    widgetName,
    isModified,
    showConfirmCloseModal,
    setShowConfirmCloseModal,
    setViewWidgetOptions,
    width = 70,
    children,
}) => {
    return (
        <Modal
            title={`Widget Options`}
            titleIcon={faGears}
            iconColor={'backgrounds-settings100'}
            onClose={() => {
                if (isModified) {
                    setShowConfirmCloseModal(true);
                } else {
                    setViewWidgetOptions(false);
                }
            }}
        >
            <div className={`flex-col w-[${width}vw] h-[79vh]`}>
                {showConfirmCloseModal && (
                    <ConfirmModal
                        modalText={
                            'Unsaved changes present. Are you sure you want to close widget Options?'
                        }
                        onConfirm={() => {
                            setViewWidgetOptions(false);
                        }}
                        onCancel={() => {
                            setShowConfirmCloseModal(false);
                        }}
                    />
                )}
                {children}
            </div>
        </Modal>
    );
};

export const WidgetOptions = ({
    user,
    selectedDashboard,
    repositoryList,
    metaData,
    repoData,
    queryData,
    chartData,
    layoutData,
    onRepoUpdate,
    onWidgetMetaUpdate,
    onQueryDataUpdate,
    onChartUpdate,
    setViewWidgetOptions,
}) => {
    // console.log('###############################################');
    // console.log('Meta Data', metaData);
    // console.log('Layout Data', layoutData);
    // console.log('Repo Data', repoData);
    // console.log('Query Data', queryData);
    // console.log('chart Data', chartData);

    const previousQueryText = queryData.queryText;
    const previousModifiedAt = queryData.queryModifiedAt;

    const chartName = metaData ? metaData.name : 'Sample Chart';

    const [widgetName, setWidgetName] = useState(metaData.name);
    const [isWidgetNameHidden, setIsWidgetNameHidden] = useState(
        metaData.isWidgetNameHidden
    );
    const [selectedRepo, setSelectedRepo] = useState(repoData);
    const [dbQuery, setDbQuery] = useState(queryData.dbQuery);
    const [queryText, setQueryText] = useState(queryData.queryText);
    const [selectedChart, setSelectedChart] = useState(chartData.chart);
    const [localChartData, setLocalChartData] = useState(chartData.data);
    const [chartOptions, setChartOptions] = useState(chartData.options);
    const [repoDropDownList, setRepoDropDownList] = useState([]);
    const [showDbQuery, setShowDbQuery] = useState(false);
    const [closeDropdownSignal, setCloseDropdownSignal] = useState(false);
    const [showChartInFullScreen, setShowChartInFullScreen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [queryModifiedAt, setQueryModifiedAt] = useState(
        queryData.queryModifiedAt
    );
    const [enableChartEditing, setEnableChartEditing] = useState(false);
    const [isModified, setIsModified] = useState(false);
    const [showConfirmCloseModal, setShowConfirmCloseModal] = useState(false);
    const [error, setError] = useState('');

    const endRef = React.createRef();

    const scrollToBottom = (ref) => {
        ref.current?.scrollIntoView({
            behavior: 'instant',
        });
    };

    // const timeout = setTimeout(() => {
    //     setCloseDropdownSignal(false);
    // }, 1000);

    // const toggleDropdownSignal = () => {
    //     clearTimeout(timeout);
    //     setCloseDropdownSignal(true);
    //     setTimeout(timeout);
    // };

    const toggleWidgetNameView = () => {
        setIsWidgetNameHidden(!isWidgetNameHidden);
    };

    const toggleDbQueryView = () => {
        setShowDbQuery(!showDbQuery);
    };

    const toggleChartViewInFullScreen = (value) => {
        setShowChartInFullScreen(value ? value : !showChartInFullScreen);
    };

    const handleQueryChange = (e) => {
        if (e.target.value) {
            if (e.target.value.trim() === previousQueryText.trim()) {
                setQueryModifiedAt(previousModifiedAt);
            } else {
                setQueryModifiedAt(Date.now());
            }
        }

        setQueryText(e.target.value);
    };

    const handleWidgetUpdatedData = (data) => {
        const { cardId, query, chartData, queryModifiedAt, queryGeneratedAt } =
            data;

        // Meta Update
        const updatedMeta = {
            id: cardId,
            name: widgetName,
            isWidgetNameHidden: isWidgetNameHidden,
        };
        onWidgetMetaUpdate(updatedMeta);

        // Repo Data update
        onRepoUpdate(selectedRepo);

        // Query Data Update
        const updatedQueryData = {
            dbQuery: query,
            queryText: queryText,
            queryModifiedAt: queryModifiedAt,
            queryGeneratedAt: queryGeneratedAt,
        };
        setDbQuery(query);
        onQueryDataUpdate(updatedQueryData);

        // Chart Data Update
        const payload = chartData.data;
        if (payload && payload.columns && payload.data) {
            const columns = payload.columns.map((column) =>
                isNumeric(column) ? column : toSentenceCase(column)
            );
            const modifiedData = [columns, ...payload.data];
            const modifiedChartData = {
                chart: selectedChart,
                options: chartOptions,
                data: modifiedData,
            };

            setLocalChartData(modifiedData);
            onChartUpdate(modifiedChartData);
        }
    };

    const onWidgetUpdate = async () => {
        try {
            setError('');
            const isValidForm = isValidWidgetData({
                widgetName,
                selectedRepo,
                queryText,
                chartData: {
                    chart: selectedChart,
                    data: chartData.data,
                    options: chartOptions,
                },
            });
            const { isValid, reason } = isValidForm;
            if (!isValid) {
                setError(reason);
                return;
            }

            setIsLoading(true);

            const dynamicUrl = getUrl(
                `http://${process.env.REACT_APP_FAST_API_HOST}:${process.env.REACT_APP_FAST_API_PORT}/dashboard_routes/save-card`
            );

            const body = getWidgetData({
                user,
                selectedRepo,
                selectedDashboard,
                widgetName,
                widgetId: metaData.id,
                isWidgetNameHidden,
                queryData,
                queryText,
                dbQuery,
                queryModifiedAt,
                queryGeneratedAt: queryData.queryGeneratedAt,
                layoutData,
                selectedChart,
                chartOptions,
            });

            const encodedToken = encodeURIComponent(user.token);
            const response = await axios.post(dynamicUrl, body, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: encodedToken,
                },
            });

            if (response) {
                const data = response.data;

                if (data) {
                    handleWidgetUpdatedData(data);
                }
            }
        } catch (error) {
            alert(AlertType.ERROR, error.message);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (endRef && !enableChartEditing && !showChartInFullScreen)
            setTimeout(() => {
                scrollToBottom(endRef);
            }, 1);
    }, [enableChartEditing, showChartInFullScreen, selectedChart]);

    useEffect(() => {
        const hasChanges =
            widgetName.trim() !== metaData.name.trim() ||
            selectedRepo !== repoData ||
            queryText.trim() !== queryData.queryText.trim() ||
            selectedChart !== chartData.chart ||
            chartOptions !== chartData.options;

        if (hasChanges) {
            if (!isModified) {
                setIsModified(true);
            }
        } else {
            if (isModified) {
                setIsModified(false);
            }
        }
    }, [
        widgetName,
        selectedRepo,
        queryText,
        selectedChart,
        chartOptions,
        metaData.name,
        repoData,
        queryData.queryText,
        chartData.chart,
        chartData.options,
        isModified,
    ]);

    useEffect(() => {
        if (repositoryList && repositoryList.length) {
            const dropDownData = repositoryList.map((repoData) => {
                return {
                    label: repoData.name,
                    icon: faDatabase,
                    iconColor: 'lightblue',
                    action: () => {
                        setSelectedRepo(repoData);
                    },
                    selected: selectedRepo && repoData.id === selectedRepo.id,
                };
            });

            setRepoDropDownList(dropDownData);
        }
    }, [repositoryList, selectedRepo]);

    if (isLoading) {
        return (
            <Container
                widgetName={widgetName}
                isModified={isModified}
                showConfirmCloseModal={showConfirmCloseModal}
                setShowConfirmCloseModal={setShowConfirmCloseModal}
                setViewWidgetOptions={setViewWidgetOptions}
                width={50}
            >
                <div className='w-full h-full flex justify-center items-center'>
                    <LoadingView
                        loadingText={'Updating Widget'}
                        inLine={false}
                    />
                </div>
            </Container>
        );
    }

    if (enableChartEditing) {
        return (
            <Container
                widgetName={widgetName}
                isModified={isModified}
                showConfirmCloseModal={showConfirmCloseModal}
                setShowConfirmCloseModal={setShowConfirmCloseModal}
                setViewWidgetOptions={setViewWidgetOptions}
            >
                <CardEditView
                    selectedChart={selectedChart}
                    setSelectedChart={setSelectedChart}
                    chartName={chartName}
                    chartData={localChartData}
                    chartOptions={chartOptions}
                    setChartOptions={setChartOptions}
                    // closeDropdownSignal={closeDropdownSignal}
                    showChartInFullScreen={showChartInFullScreen}
                    toggleChartViewInFullScreen={toggleChartViewInFullScreen}
                    enableEditing={enableChartEditing}
                    setEnableEditing={setEnableChartEditing}
                />
            </Container>
        );
    }

    if (showChartInFullScreen) {
        return (
            <Container
                widgetName={widgetName}
                isModified={isModified}
                showConfirmCloseModal={showConfirmCloseModal}
                setShowConfirmCloseModal={setShowConfirmCloseModal}
                setViewWidgetOptions={setViewWidgetOptions}
            >
                <ChartWithControls
                    selectedChart={selectedChart}
                    setSelectedChart={setSelectedChart}
                    chartData={localChartData}
                    chartOptions={chartOptions}
                    chartName={chartName}
                    showChartInFullScreen={showChartInFullScreen}
                    // closeDropdownSignal={closeDropdownSignal}
                    toggleChartViewInFullScreen={toggleChartViewInFullScreen}
                    enableEditing={enableChartEditing}
                    setEnableEditing={setEnableChartEditing}
                />
            </Container>
        );
    }

    const disableUpdateButton = isLoading || !isModified;
    let disableReason = '';
    if (!isModified) {
        disableReason = 'Disabled as no modification detected';
    } else {
        disableReason = 'Disabled due to updating the widget';
    }

    return (
        <Container
            widgetName={widgetName}
            isModified={isModified}
            showConfirmCloseModal={showConfirmCloseModal}
            setShowConfirmCloseModal={setShowConfirmCloseModal}
            setViewWidgetOptions={setViewWidgetOptions}
            width={50}
        >
            <>
                <div className='w-full flex items-center justify-between mb-2 border-b py-2 px-3 shadow-sm'>
                    <div>
                        {error ? (
                            <>
                                <FontAwesomeIcon
                                    icon={faCircleExclamation}
                                    className='mr-2 text-texts-red400'
                                />
                                <span className='text-texts-red400'>
                                    {error}
                                </span>
                            </>
                        ) : (
                            <></>
                        )}
                    </div>
                    <div>
                        <Button
                            type='success'
                            icon={faCheckCircle}
                            text={'Update'}
                            hoverText={
                                !disableUpdateButton
                                    ? 'Update Widget'
                                    : disableReason
                            }
                            className=''
                            onClick={async () => await onWidgetUpdate()}
                            disabled={disableUpdateButton}
                        />
                    </div>
                </div>
                <div
                    className='w-full h-[70vh] flex-col overflow-auto p-6'
                    onScroll={() => {
                        // toggleDropdownSignal();
                    }}
                >
                    <div className='w-full h-full flex-col'>
                        <div className='w-full flex items-center mb-6'>
                            <div className='w-1/4'>
                                <label>
                                    <div className='font-semibold text-texts-secondary300 '>
                                        <FontAwesomeIcon
                                            icon={faTag}
                                            color={iconColor}
                                            className='mr-2'
                                        />
                                        <span>Widget Title</span>
                                    </div>
                                </label>
                            </div>

                            <div className='w-3/4'>
                                <Input
                                    className={
                                        'w-full text-texts-secondary300 truncate'
                                    }
                                    value={widgetName}
                                    type={'string'}
                                    onChange={(e) =>
                                        setWidgetName(e.target.value)
                                    }
                                    maxLength={maxTitleLength}
                                />
                            </div>
                        </div>

                        <div className='w-full flex items-center mb-6'>
                            <div className='w-1/4 font-semibold text-texts-secondary300'>
                                <label>
                                    <div className='font-semibold text-texts-secondary300 '>
                                        <FontAwesomeIcon
                                            icon={faEyeSlash}
                                            color={iconColor}
                                            className='mr-2'
                                        />
                                        <span>Hide Title</span>
                                    </div>
                                </label>
                            </div>

                            <div className='w-3/4'>
                                <div
                                    className='w-fit'
                                    title={'Hide widget title from the widget'}
                                >
                                    <Toggle
                                        // toggleText={'Hide'}
                                        toggleTextClassName={`w-full cursor-pointer font-semibold text-texts-secondary300`}
                                        disabled={false}
                                        checked={isWidgetNameHidden}
                                        onChange={toggleWidgetNameView}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className='w-full flex items-center mb-6'>
                            <div className='w-1/4 font-semibold text-texts-secondary300'>
                                <label>
                                    <div className='font-semibold text-texts-secondary300 '>
                                        <FontAwesomeIcon
                                            icon={faFolder}
                                            color={iconColor}
                                            className='mr-2'
                                        />
                                        <span>Repository</span>
                                    </div>
                                </label>
                            </div>

                            <div className='w-fit'>
                                <Dropdown
                                    dropDownList={repoDropDownList}
                                    showButtonText={true}
                                    buttonText={
                                        selectedRepo && selectedRepo.name ? (
                                            <>
                                                <FontAwesomeIcon
                                                    icon={getRepoIcon(
                                                        selectedRepo
                                                    )}
                                                    className='mr-2'
                                                />
                                                {selectedRepo.name}
                                            </>
                                        ) : (
                                            'Select Repository'
                                        )
                                    }
                                    buttonHoverText={
                                        'Select Repository to perform actions on'
                                    }
                                    titleText={'Dashboard Actions'}
                                    buttonClassName={'w-60'}
                                    backgroundColor={'white'}
                                    textColor={'green'}
                                    placement={'rightTop'}
                                    listTitleText={'Repositories'}
                                    // closeSignal={closeDropdownSignal}
                                />
                            </div>
                        </div>

                        <div className='w-full flex-col items-center mb-6'>
                            <div className='w-full flex justify-between items-center pb-3'>
                                <div className='flex items-center font-semibold text-texts-secondary300 '>
                                    <FontAwesomeIcon
                                        icon={faQuestionCircle}
                                        color={iconColor}
                                        className='mr-2'
                                    />
                                    <span>Query</span>
                                </div>
                                <div>
                                    <Toggle
                                        toggleText={
                                            <>
                                                <FontAwesomeIcon
                                                    icon={faDatabase}
                                                    className='mr-2'
                                                />
                                                <span>DB Query</span>
                                            </>
                                        }
                                        toggleTextClassName={`w-full mr-2 cursor-pointer font-semibold text-texts-secondary300`}
                                        disabled={!dbQuery}
                                        checked={showDbQuery}
                                        onChange={toggleDbQueryView}
                                    />
                                </div>
                            </div>
                            <div className='w-full'>
                                <TextArea
                                    className='p-4 h-48 max-h-48 min-h-48 '
                                    placeholder='Write your query here'
                                    rows={10}
                                    value={showDbQuery ? dbQuery : queryText}
                                    onChange={handleQueryChange}
                                    resize={'none'}
                                    disabled={showDbQuery}
                                />
                            </div>
                        </div>

                        <div className='w-full flex items-center'>
                            <div className='w-full'>
                                <ChartWithControls
                                    selectedChart={selectedChart}
                                    setSelectedChart={setSelectedChart}
                                    chartData={localChartData}
                                    chartOptions={chartOptions}
                                    chartName={chartName}
                                    showChartInFullScreen={
                                        showChartInFullScreen
                                    }
                                    // closeDropdownSignal={closeDropdownSignal}
                                    toggleChartViewInFullScreen={
                                        toggleChartViewInFullScreen
                                    }
                                    enableEditing={enableChartEditing}
                                    setEnableEditing={setEnableChartEditing}
                                />
                            </div>
                        </div>
                        <div ref={endRef}></div>
                    </div>
                </div>
            </>
        </Container>
    );
};
