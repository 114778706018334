import React, { useEffect, useState } from 'react';
import '/node_modules/react-grid-layout/css/styles.css';
import '/node_modules/react-resizable/css/styles.css';
import {
    alert,
    AlertType,
    ConfirmModal,
    Container,
    LoadingView,
} from '../../reusableComponents';
import {
    faAdd,
    faBoxes,
    faHandPointUp,
    faRefresh,
    faTableCellsLarge,
} from '@fortawesome/free-solid-svg-icons';
import { generateUniqueId } from '../../../utils';
import { WidgetOptions } from './widgetOptions';
import ReactGridLayout from 'react-grid-layout';
import { Widget } from './widget';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ChartTypes, defaultChartOptions } from './constants';
import { getUrl } from '../../pdfDisplay/utils';
import axios from 'axios';
import { isNumeric, toSentenceCase } from '../../reportLayouts/utils';
import { FullScreenResultView } from './fullScreenResultView';

const cardSize = 4;
const maxUnits = 12;
const minCardHeight = 3;

const defaultQueryData = {
    queryText: '',
    dbQuery: '',
    queryModifiedAt: null,
    queryGeneratedAt: null,
};

const defaultChartData = {
    chart: ChartTypes.TABLE,
    options: defaultChartOptions,
    data: [],
};

const onSaveFormation = async (user, widgetLayoutsData) => {
    try {
        // setIsLoading(true);
        const dynamicUrl = getUrl(
            `http://${process.env.REACT_APP_FAST_API_HOST}:${process.env.REACT_APP_FAST_API_PORT}/dashboard_routes/save-layouts`
        );

        const body = widgetLayoutsData;
        const encodedToken = encodeURIComponent(user.token);
        const response = await axios.post(dynamicUrl, body, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: encodedToken,
            },
        });

        if (response) {
            const data = response.data;

            if (data) {
                console.log(data);
            }
        }
    } catch (error) {
        alert(AlertType.ERROR, error.message);
    } finally {
        // setIsLoading(false);
    }
};

const NoCardsView = () => {
    return (
        <div className='flex flex-col w-full h-[78vh] items-center justify-center'>
            <div className='flex items-center justify-center text-texts-secondary300 text-xl'>
                <h1>Add widgets from "+ Widget" button</h1>
                <FontAwesomeIcon
                    icon={faHandPointUp}
                    className='ml-3 text-icons-primary'
                />
            </div>
            <div className='flex h-2/3 w-full items-center justify-center text-texts-secondary300'>
                <div className='flex flex-col items-center justify-center'>
                    <FontAwesomeIcon
                        icon={faBoxes}
                        //color='gold'
                        className='w-[8vw] h-[8vw] mb-2 drop-shadow text-icons-primary'
                    />
                    <div>No Widget</div>
                </div>
            </div>
        </div>
    );
};

const scrollToCard = (cardRef) => {
    cardRef.current?.scrollIntoView({ behavior: 'smooth' });
};

export const DashboardContainer = ({
    user,
    selectedDashboard,
    layout,
    repositoryList,
    isLoading,
    loadingText,
    onDeleteWidget,
    getWidgetsData,
}) => {
    const [completeLayout, setCompleteLayout] = useState(layout);
    const [activeWidget, setActiveWidget] = useState(null);
    const [metaData, setMetaData] = useState(new Map());
    const [layoutData, setLayoutData] = useState([]);
    const [reposData, setReposData] = useState(new Map());
    const [queriesData, setQueriesData] = useState(new Map());
    const [chartsData, setChartsData] = useState(new Map());
    const [viewWidgetOptions, setViewWidgetOptions] = useState(false);
    const [viewFullScreenResult, setViewFullScreenResult] = useState(false);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [showConfirmRefreshModal, setShowConfirmRefreshModal] =
        useState(false);
    const [newCardId, setNewCardId] = useState(null);
    const [movingCardId, setMovingCardId] = useState(null);
    const [flash, setFlash] = useState(false);
    const [isFirstFetch, setIsFirstFetch] = useState(true);

    // Creating card reference
    const newCardRef = React.createRef();
    const movingCardRef = React.createRef();

    useEffect(() => {
        if (selectedDashboard) {
            if (!isFirstFetch) {
                setIsFirstFetch(true);
            }
        }
    }, [selectedDashboard]);

    useEffect(() => {
        if (flash) {
            setTimeout(() => {
                setFlash(false);
            }, 3000); // Stop flashing after 3 second
        }
    }, [flash]);

    useEffect(() => {
        if (newCardRef) {
            scrollToCard(newCardRef);
        }

        return () => {};
    }, [newCardRef, newCardId]);

    useEffect(() => {
        if (movingCardRef) {
            scrollToCard(movingCardRef);
        }

        return () => {};
    }, [movingCardRef, movingCardId]);

    useEffect(() => {
        if (layout) {
            setCompleteLayout(layout);
        }
    }, [layout]);

    useEffect(() => {
        if (completeLayout) {
            const newMetaData = new Map();
            const newReposData = new Map();
            const newQueriesData = new Map();
            const newChartsData = new Map();

            const newLayoutData = [];

            if (completeLayout) {
                completeLayout.forEach((data) => {
                    const uuid = data.layoutDesign.i;

                    // Meta related data
                    const metaData = {
                        id: data.cardId,
                        name: data.cardName,
                        isWidgetNameHidden: data.isNameHidden,
                    };
                    newMetaData.set(uuid, metaData);

                    // Repo related data
                    const repoData = repositoryList.find(
                        (repo) => repo.id === data.repoId
                    );
                    newReposData.set(uuid, repoData);

                    // Query related data
                    const queryData = {
                        dbQuery: data.query,
                        queryText: data.queryText,
                        queryModifiedAt: data.queryModifiedAt,
                        queryGeneratedAt: data.queryGeneratedAt,
                    };
                    newQueriesData.set(uuid, queryData);

                    // Charts related data
                    const payload = data.chartData.data;
                    const columns = payload.columns.map((column) =>
                        isNumeric(column) ? column : toSentenceCase(column)
                    );
                    const chartData = {
                        chart: data.chartData.chart,
                        options: data.chartData.options,
                        data: [columns, ...payload.data],
                    };
                    newChartsData.set(uuid, chartData);

                    // layout related data
                    newLayoutData.push(data.layoutDesign);
                });

                setMetaData(newMetaData);
                setReposData(newReposData);
                setQueriesData(newQueriesData);
                setChartsData(newChartsData);
                setLayoutData(newLayoutData);
            }
        }
    }, [completeLayout]);

    // Function to add new card
    const addNewCard = (widgetName) => {
        let posX = 0;
        let posY = 0;
        let LocalMaxX = 0;
        let LocalMaxY = 0;

        if (layoutData.length) {
            layoutData.forEach((data) => {
                if (data.y > LocalMaxY) {
                    LocalMaxY = data.y;
                }
            });

            const filteredData = layoutData.filter(
                (data) => data.y === LocalMaxY
            );
            filteredData.forEach((data) => {
                if (data.x > LocalMaxX) {
                    LocalMaxX = data.x;
                }
            });

            if (LocalMaxX === maxUnits) {
                posX = 0;
                posY = LocalMaxY + cardSize;
            } else {
                posX = LocalMaxX + cardSize;
                posY = LocalMaxY;
            }
        }

        const id = generateUniqueId();

        const newMeta = {
            id: id,
            name: widgetName ? widgetName : 'Untitled Widget',
            isWidgetNameHidden: false,
        };

        const newCardData = {
            i: id,
            x: posX,
            y: posY,
            w: cardSize,
            h: cardSize,
            minW: cardSize,
            minH: minCardHeight,
        };

        const copyMetaData = new Map(metaData);
        copyMetaData.set(id, newMeta);
        setMetaData(copyMetaData);

        const copyReposData = new Map(reposData);
        copyReposData.set(id, null);
        setReposData(copyReposData);

        const copyQueriesData = new Map(queriesData);
        copyQueriesData.set(id, defaultQueryData);
        setQueriesData(copyQueriesData);

        const copyChartsData = new Map(chartsData);
        copyChartsData.set(id, defaultChartData);
        setChartsData(copyChartsData);

        const newLayoutData = [...layoutData, newCardData];
        setLayoutData(newLayoutData);

        setNewCardId(id);

        // Flash the newly created card
        setFlash(true);
    };

    // Function to remove a card from view.
    const removeCard = (id) => {
        const copyMetaData = new Map(metaData);
        copyMetaData.delete(id);
        setMetaData(copyMetaData);

        const copyReposData = new Map(reposData);
        copyReposData.delete(id);
        setReposData(copyReposData);

        const copyQueriesData = new Map(queriesData);
        copyQueriesData.delete(id);
        setQueriesData(copyQueriesData);

        const copyChartsData = new Map(chartsData);
        copyChartsData.delete(id);
        setChartsData(copyChartsData);

        const newLayoutData = layoutData.filter((data) => data.i !== id);
        setLayoutData(newLayoutData);

        // Setting card reference to null to avoid unnecessary scrolling
        setNewCardId(null);
        setFlash(false);
    };

    const getMetaData = (activeWidget) => {
        const defaultMetaData = { name: '', isCardNameHidden: false };
        if (!activeWidget) return defaultMetaData;

        const meta = metaData.get(activeWidget.i);
        if (meta) {
            return meta;
        }
        return defaultMetaData;
    };

    const getRepoData = (activeWidget) => {
        if (!activeWidget) return null;

        const repo = reposData.get(activeWidget.i);
        if (repo) {
            return repo;
        }
        return null;
    };

    const getQueryData = (activeWidget) => {
        if (!activeWidget) return defaultQueryData;

        const queryData = queriesData.get(activeWidget.i);
        if (queryData) {
            return queryData;
        }
        return defaultQueryData;
    };

    const getChartData = (activeWidget) => {
        if (!activeWidget) return defaultChartData;

        const chartData = chartsData.get(activeWidget.i);
        if (chartData) {
            return chartData;
        }
        return defaultChartData;
    };

    const onWidgetMetaUpdate = (data) => {
        if (activeWidget && metaData && data) {
            const copyMetaData = new Map(metaData);
            const newData = copyMetaData.get(activeWidget.i);
            if (newData) {
                newData.id = data.id;
                newData.name = data.name;
                newData.isWidgetNameHidden = data.isWidgetNameHidden;
            }

            copyMetaData.set(activeWidget.i, newData);
            setMetaData(copyMetaData);
        }
    };

    const onRepoUpdate = (repoData) => {
        if (activeWidget && repoData) {
            const copyReposData = new Map(reposData);
            copyReposData.set(activeWidget.i, repoData);
            setReposData(copyReposData);
        }
    };

    const onQueryDataUpdate = (queryData) => {
        if (activeWidget && queryData) {
            const copyQueriesData = new Map(queriesData);
            copyQueriesData.set(activeWidget.i, queryData);
            setQueriesData(copyQueriesData);
        }
    };

    // Function to update the chart.
    const onChartUpdate = (updatedChartData) => {
        if (updatedChartData) {
            // Updating chat data as selected fields updated.
            const existingChartData = chartsData.get(activeWidget.i);
            const newChartData = { ...existingChartData };
            newChartData.chart = updatedChartData.chart;
            newChartData.data = updatedChartData.data;
            if (updatedChartData.options) {
                newChartData.options = updatedChartData.options;
            }

            const copyChartsData = new Map(chartsData);
            copyChartsData.set(activeWidget.i, newChartData);
            setChartsData(copyChartsData);
        }
    };

    const getWidgetLayoutsData = (updatedLayoutData) => {
        const widgetsFormations = [];
        updatedLayoutData.forEach((data) => {
            const query = queriesData.get(data.i);

            if (query.queryGeneratedAt) {
                const meta = metaData.get(data.i);
                const widgetLayout = {
                    cardId: meta.id,
                    layoutData: data,
                };

                widgetsFormations.push(widgetLayout);
            }
        });

        return widgetsFormations;
    };

    const handleLayoutChange = async (updatedLayout) => {
        const needToSaveLayout = !isFirstFetch;

        // Updating the layout
        setLayoutData(updatedLayout);

        if (isFirstFetch) {
            // Fetching and setting done
            setIsFirstFetch(false);
        }

        if (needToSaveLayout) {
            const widgetLayoutsData = getWidgetLayoutsData(updatedLayout);
            await onSaveFormation(user, widgetLayoutsData);
        }
    };

    const handleRefresh = async () => {
        const dataArr = [...queriesData.values()];
        let hasNonExecutedCard = false;

        for (let index = 0; index < dataArr.length; index++) {
            const element = dataArr[index];
            if (!element.queryGeneratedAt) {
                hasNonExecutedCard = true;
                break;
            }
        }

        if (hasNonExecutedCard) {
            setShowConfirmRefreshModal(true);
        } else {
            await getWidgetsData();
        }
    };

    // console.log('###############################################');
    // console.log('Meta Data', metaData);
    // console.log('Layout Data', layoutData);
    // console.log('Repo Data', reposData);
    // console.log('Query Data', queriesData);
    // console.log('chart Data', chartsData);

    const widgets = React.useMemo(() => {
        return layoutData.map((layout, idx) => (
            <div
                key={layout.i}
                data-grid={{
                    x: layout.x,
                    y: layout.y,
                    w: layout.w,
                    h: layout.h,
                    minW: layout.minW ? layout.minW : cardSize,
                    minH: layout.minH ? layout.minH : minCardHeight,
                    isDraggable: true,
                    isResizable: true,
                }}
                className={`w-fit h-fit shadow rounded-xl z-10 select-none bg-backgrounds-white border cursor-move ${
                    layout.i === newCardId && flash ? 'flash' : ''
                }`}
            >
                {/* Reference div for auto scrolling to new card */}
                {layout.i === newCardId && <div ref={newCardRef} />}

                {/* Reference div for auto scrolling to dragging card  */}
                {layout.i === movingCardId && <div ref={movingCardRef} />}

                <Widget
                    layout={layout}
                    metaData={metaData}
                    reposData={reposData}
                    queriesData={queriesData}
                    chartsData={chartsData}
                    setActiveWidget={setActiveWidget}
                    setViewWidgetOptions={setViewWidgetOptions}
                    setViewFullScreenResult={setViewFullScreenResult}
                    setShowConfirmationModal={setShowConfirmationModal}
                />
            </div>
        ));
    }, [layoutData, metaData, reposData, queriesData, chartsData]);

    const actions = [
        {
            text: 'Refresh',
            icon: faRefresh,
            action: async () => await handleRefresh(),
            hoverText: 'Refresh all widgets data',
            type: 'success',
            disabled: isLoading,
        },
        {
            text: 'Widget',
            icon: faAdd,
            action: () => addNewCard(),
            hoverText: 'Add new Widget',
            type: 'success',
            disabled: isLoading,
        },
    ];

    const dashboardTitle = (
        <div className='flex justify-center items-center'>
            <div className='mr-4 truncate'>
                {selectedDashboard && selectedDashboard.name
                    ? selectedDashboard.name
                    : 'Dashboard'}
            </div>
        </div>
    );

    return (
        <Container
            title={dashboardTitle}
            icon={faTableCellsLarge}
            iconColor={'backgrounds-settings100'}
            actions={actions}
            backgroundColor={'white'}
        >
            {isLoading ? (
                <div className='w-full h-full flex justify-center items-center'>
                    <LoadingView loadingText={loadingText} inLine={false} />
                </div>
            ) : (
                <div className='w-full h-fit flex bg-backgrounds-slate justify-center'>
                    <div className='w-full h-fit'>
                        {layoutData.length ? (
                            <ReactGridLayout
                                className='layout'
                                rowHeight={40}
                                width={1705}
                                cols={18}
                                resizeHandles={['se', 'sw']}
                                containerPadding={[20, 20]}
                                onLayoutChange={async (updatedLayout) =>
                                    await handleLayoutChange(updatedLayout)
                                }
                                onDragStart={(
                                    layout,
                                    oldItem,
                                    newItem,
                                    placeholder,
                                    e,
                                    element
                                ) => {
                                    setMovingCardId(newItem.i);
                                }}
                                onDrag={(
                                    layout,
                                    oldItem,
                                    newItem,
                                    placeholder,
                                    e,
                                    element
                                ) => scrollToCard(movingCardRef)}
                                onDrop={(layout, item, e) =>
                                    scrollToCard(movingCardRef)
                                }
                            >
                                {widgets}
                            </ReactGridLayout>
                        ) : (
                            <NoCardsView />
                        )}

                        {viewWidgetOptions && (
                            <WidgetOptions
                                user={user}
                                repositoryList={repositoryList}
                                selectedDashboard={selectedDashboard}
                                metaData={getMetaData(activeWidget)}
                                repoData={getRepoData(activeWidget)}
                                queryData={getQueryData(activeWidget)}
                                chartData={getChartData(activeWidget)}
                                layoutData={activeWidget}
                                onWidgetMetaUpdate={onWidgetMetaUpdate}
                                onQueryDataUpdate={onQueryDataUpdate}
                                onRepoUpdate={onRepoUpdate}
                                onChartUpdate={onChartUpdate}
                                setViewWidgetOptions={setViewWidgetOptions}
                            />
                        )}

                        {viewFullScreenResult && (
                            <FullScreenResultView
                                chartData={getChartData(activeWidget)}
                                chartName={getMetaData(activeWidget).name}
                                setViewFullScreenResult={
                                    setViewFullScreenResult
                                }
                            />
                        )}

                        {showConfirmationModal && (
                            <ConfirmModal
                                modalText={`Are you sure you want to delete "${
                                    getMetaData(activeWidget).name
                                }" widget?`}
                                onConfirm={async () => {
                                    if (
                                        getQueryData(activeWidget)
                                            .queryGeneratedAt
                                    ) {
                                        const widgetId =
                                            getMetaData(activeWidget).id;
                                        await onDeleteWidget(widgetId);
                                    }
                                    removeCard(activeWidget.i);
                                    setShowConfirmationModal(false);
                                }}
                                onCancel={() => setShowConfirmationModal(false)}
                            />
                        )}

                        {showConfirmRefreshModal && (
                            <ConfirmModal
                                modalText={`Non executed card(s) will be lost on refresh. Are you sure you want to continue ?`}
                                onConfirm={async () => {
                                    setShowConfirmRefreshModal(false);
                                    await getWidgetsData();
                                }}
                                onCancel={() =>
                                    setShowConfirmRefreshModal(false)
                                }
                            />
                        )}
                    </div>
                </div>
            )}
        </Container>
    );
};
