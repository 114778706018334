import { getFileFormat } from './getFileFormat';

const fileColorMap = {
    pdf: 'red',
    doc: '#38bdf8',
    docx: '#38bdf8',
    txt: '#22c55d',
    csv: '#22c55d',
    py: '#22c55d',
    json: '#ffd602',
    db: 'gold',
    jpg: '#6bb0fa',
    jpeg: '#6bb0fa',
    png: '#6bb0fa',
    bmp: '#6bb0fa',
    svg: '#6bb0fa',
};

export const getFileIconColor = (fileName) => {
    const fileFormat = getFileFormat(fileName).toLowerCase();
    return fileColorMap[fileFormat] || 'texts-secondary';
};
