import React, { useEffect, useState } from 'react';
import { getFileFormat, isImageFile } from '../utils';
import { FILE_EXTENSIONS } from '../constants/fileExtensions';
import { PdfDebugContainer } from './pdfDisplay/debug/pdfDebugContainer';
import { SingleImageContainer } from './imageViewer/singleImageContainer';

export const DebugViewerContainer = ({
    repoId,
    email,
    folderName,
    fileName,
    mode,
    isAdmin,
}) => {
    const extension = getFileFormat(fileName);

    if (isImageFile(extension)) {
        return (
            <SingleImageContainer
                repoId={repoId}
                email={email}
                folderName={folderName}
                fileName={fileName}
            />
        );
    }

    return (
        <PdfDebugContainer
            repoId={repoId}
            email={email}
            folderName={folderName}
            fileName={fileName}
            mode={mode}
            isAdmin={isAdmin}
        />
    );
};
