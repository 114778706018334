import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { DashboardContainer } from './dashboardComponents';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faMobileAlt,
    faExclamationTriangle,
} from '@fortawesome/free-solid-svg-icons';
import { getUrl } from '../pdfDisplay/utils';
import { alert, AlertType } from '../reusableComponents';

const DashboardMainView = ({
    user,
    repositoryList,
    selectedDashboard,
    isSmallScreen,
}) => {
    const [dashboardData, setDashboardData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [loadingText, setLoadingText] = useState('');

    const getWidgetsData = async () => {
        try {
            setIsLoading(true);
            setLoadingText('Loading Dashboard Data...');

            const dynamicUrl = getUrl(
                `http://${process.env.REACT_APP_FAST_API_HOST}:${process.env.REACT_APP_FAST_API_PORT}/dashboard_routes/get-widgets`
            );

            const body = {
                dashId: `${selectedDashboard.id}`,
                email: user.email,
            };
            const encodedToken = encodeURIComponent(user.token);
            const response = await axios.post(dynamicUrl, body, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: encodedToken,
                },
            });

            if (response) {
                const data = response.data;

                if (data) {
                    setDashboardData(data);
                }
            }
        } catch (error) {
            alert(AlertType.ERROR, error.message);
        } finally {
            setIsLoading(false);
            setLoadingText('');
        }
    };

    useEffect(() => {
        (async () => {
            if (selectedDashboard) {
                await getWidgetsData();
            }
        })();

        return () => {};
    }, [selectedDashboard]);

    const handleDeleteWidget = async (widgetId) => {
        try {
            setIsLoading(true);
            setLoadingText('Deleting Widget');
            const dynamicUrl = getUrl(
                `http://${process.env.REACT_APP_FAST_API_HOST}:${process.env.REACT_APP_FAST_API_PORT}/dashboard_routes/delete-widget/${widgetId}?email=${user.email}`
            );

            const encodedToken = encodeURIComponent(user.token);

            const response = await axios.delete(`${dynamicUrl}`, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: encodedToken,
                },
            });

            if (response) {
                const data = response.data;
            }
        } catch (error) {
            alert(AlertType.ERROR, 'Error deleting the widget');
            // console.error('Error deleting the widget:', error);
        } finally {
            setIsLoading(false);
            setLoadingText('');
        }
    };

    if (isSmallScreen) {
        return (
            <div className='bg-backgrounds-slate w-full h-full flex items-center justify-center'>
                <div className='flex flex-col items-center text-center text-texts-secondary300 p-4'>
                    <FontAwesomeIcon
                        icon={faMobileAlt}
                        className='w-16 h-16 mb-4 text-icons-primary100'
                    />
                    <FontAwesomeIcon
                        icon={faExclamationTriangle}
                        className='w-12 h-12 mb-2 text-icons-tertiary200'
                    />
                    <h1 className='text-xl font-semibold'>
                        Page not available on small screen mode
                    </h1>
                    <p className='text-lg mt-2'>
                        Please switch to a larger screen to access this content.
                    </p>
                </div>
            </div>
        );
    }

    return (
        <DashboardContainer
            user={user}
            selectedDashboard={selectedDashboard}
            layout={dashboardData}
            repositoryList={repositoryList}
            isLoading={isLoading}
            loadingText={loadingText}
            onDeleteWidget={handleDeleteWidget}
            getWidgetsData={getWidgetsData}
        />
    );
};

export default DashboardMainView;
