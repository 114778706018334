import React from 'react';
import 'react-reflex/styles.css';
import { ReflexContainer, ReflexElement } from 'react-reflex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from './button';
import { Dropdown } from './dropDown';

export const Container = ({
    rootRef,
    title,
    icon,
    iconColor,
    actions,
    children,
    backgroundColor,
}) => {
    const bgColor = backgroundColor ? `bg-${backgroundColor}-100` : '';
    return (
        <div className='w-full h-full border border-1 rounded-xl'>
            <ReflexContainer orientation='horizontal'>
                <ReflexElement
                    className='header border-b shadow-sm'
                    style={{
                        minHeight: '3.5rem',
                        height: '3.5rem',
                        maxHeight: '3.5rem',
                    }}
                >
                    <div
                        ref={rootRef}
                        className='pane-content w-full h-full p-2'
                    >
                        <div className='flex h-full items-center justify-between px-2'>
                            <div className='flex w-full h-full items-center justify-start sm:w-auto text-texts-secondary300 truncate'>
                                <FontAwesomeIcon
                                    icon={icon}
                                    className={`mr-2 drop-shadow-sm ${
                                        iconColor
                                            ? `text-${iconColor}`
                                            : 'text-icons-primary'
                                    }`}
                                />

                                <div className='w-full font-semibold truncate text-base'>
                                    {title}
                                </div>
                            </div>

                            <div className='flex items-center'>
                                {actions &&
                                    actions.length &&
                                    actions.map((actionData, index) => {
                                        if (
                                            actionData.type &&
                                            actionData.type.toLowerCase() ===
                                                'dropdown'
                                        ) {
                                            return (
                                                <div key={index}>
                                                    <Dropdown
                                                        dropDownList={
                                                            actionData.dropDownList
                                                        }
                                                        showButtonText={
                                                            actionData.showButtonText
                                                        }
                                                        buttonText={
                                                            actionData.buttonText
                                                        }
                                                        buttonTitle={
                                                            actionData.buttonTitle
                                                        }
                                                        buttonHoverText={
                                                            actionData.buttonHoverText
                                                        }
                                                        icon={actionData.icon}
                                                        buttonClassName={
                                                            actionData.buttonClassName
                                                        }
                                                        listClassName={
                                                            actionData.listClassName
                                                        }
                                                        textColor={
                                                            actionData.textColor
                                                        }
                                                        backgroundColor={
                                                            actionData.backgroundColor
                                                        }
                                                        disabled={
                                                            actionData.disabled
                                                        }
                                                        placement={
                                                            actionData.placement
                                                        }
                                                    />
                                                </div>
                                            );
                                        } else {
                                            return (
                                                <div key={index}>
                                                    <Button
                                                        key={index}
                                                        className={
                                                            index ===
                                                            actions.length - 1
                                                                ? `${actionData.className}`
                                                                : `mr-3 ${actionData.className}`
                                                        }
                                                        text={
                                                            actionData.text ? (
                                                                <div className='hidden md:block'>
                                                                    {
                                                                        actionData.text
                                                                    }
                                                                </div>
                                                            ) : (
                                                                ''
                                                            )
                                                        }
                                                        icon={actionData.icon}
                                                        onClick={
                                                            actionData.action
                                                        }
                                                        hoverText={
                                                            actionData.hoverText
                                                        }
                                                        type={actionData.type}
                                                        disabled={
                                                            actionData.disabled
                                                        }
                                                    />
                                                </div>
                                            );
                                        }
                                    })}
                            </div>
                        </div>
                    </div>
                </ReflexElement>

                <ReflexElement flex={1}>
                    <div className={`pane-content w-full h-full ${bgColor}`}>
                        {children}
                    </div>
                </ReflexElement>
            </ReflexContainer>
        </div>
    );
};
