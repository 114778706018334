import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Brand } from '../brand';
import { UserDetailAndAction } from '../userDetailAndAction';
import { ReflexContainer, ReflexElement } from 'react-reflex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCaretLeft,
    faEllipsisV,
    faPlus,
    faTrash,
    faTableCells,
    faPencil,
    faTableCellsLarge,
} from '@fortawesome/free-solid-svg-icons';
import {
    alert,
    AlertType,
    Button,
    ConfirmModal,
    Dropdown,
    LoadingView,
    Modal,
} from '../reusableComponents';
import { useGlobalCredits } from '../GlobalStateContext';
import { getUrl } from '../pdfDisplay/utils';
import AddDashboardView from './addDashboardView';
import EditDashboardView from './editDashboardView';

const DashboardSideView = (props) => {
    const {
        user,
        selectedDashboard,
        setSelectedDashboard,
        setIsSideViewExpanded,
        closeSidebar,
        addNewDashboardMode,
        setAddNewDashboardMode,
        editDashboardMode,
        setEditDashboardMode,
    } = props;

    const actionDashboardRef = React.createRef();

    const [dashboardList, setDashboardList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [loadingText, setLoadingText] = useState('');
    const { transactionMode, setTransactionMode } = useGlobalCredits();
    const [dashboardWithAction, setDashboardWithAction] = useState(null);
    const [closeDropdownSignal, setCloseDropdownSignal] = useState(false);
    const [dashboardToDelete, setDashboardToDelete] = useState(null);
    const [isConfirmDashboardModalOpen, setIsConfirmDashboardModalOpen] =
        useState(false);

    const getDashboardList = async () => {
        try {
            setLoadingText('Loading Dashboard List...');
            setIsLoading(true);

            const dynamicUrl = getUrl(
                `http://${process.env.REACT_APP_FAST_API_HOST}:${process.env.REACT_APP_FAST_API_PORT}/dashboard_routes/get-user-dashboards/${user.email}`
            );

            const encodedToken = encodeURIComponent(user.token);
            const response = await axios.get(dynamicUrl, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: encodedToken,
                },
            });

            if (response) {
                const updatedDashboardsList = response.data;

                setDashboardList(updatedDashboardsList);
                return updatedDashboardsList;
            }
        } catch (error) {
            alert(AlertType.ERROR, error.message);
        } finally {
            setIsLoading(false);
            setLoadingText('');
        }
    };

    useEffect(() => {
        (async () => {
            await getDashboardList();
        })();
    }, []);

    const handleDashboardDelete = async (dashboard) => {
        setLoadingText('Deleting The dashboard');
        setIsLoading(true);
        try {
            const user = JSON.parse(localStorage.getItem('current'));
            const authToken = encodeURIComponent(user.token);
            const response = await axios.delete(
                getUrl(
                    `http://${process.env.REACT_APP_FAST_API_HOST}:${process.env.REACT_APP_FAST_API_PORT}/dashboard_routes/delete-dashboard/${dashboard.id}`
                ),
                {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: authToken,
                    },
                }
            );

            if (response.data) {
                const newList = dashboardList.filter(
                    (data) => data.id !== dashboard.id
                );
                setDashboardList(newList);

                if (selectedDashboard.id === dashboard.id) {
                    setSelectedDashboard(null);
                }
            }
        } catch (error) {
            console.error('Error deleting dashboard:', error);
        } finally {
            setIsConfirmDashboardModalOpen(false);
            setIsLoading(false);
        }
    };

    const scrollToCurrentDashboard = (ref) => {
        ref.current?.scrollIntoView();
    };

    useEffect(() => {
        if (actionDashboardRef && dashboardWithAction) {
            scrollToCurrentDashboard(actionDashboardRef);
        }

        return () => {};
    }, [actionDashboardRef, dashboardWithAction]);

    const Collections = ({ dashboardList }) => {
        return dashboardList.map((item, index) => {
            const DashboardActionList = [
                {
                    label: 'Edit Dashboard',
                    icon: faPencil,
                    iconColor: 'lightblue',
                    action: () => {
                        setDashboardWithAction(item);
                        setEditDashboardMode(true);
                    },
                },
                {
                    label: 'Delete Dashboard',
                    icon: faTrash,
                    iconColor: 'lightblue',
                    action: () => {
                        setDashboardToDelete(item); // Set the selected dashboard to delete
                        setIsConfirmDashboardModalOpen(true);
                    },
                    disabled: false,
                },
            ];

            return (
                <div className='w-full text-texts-secondary300' key={index}>
                    {dashboardWithAction &&
                        dashboardWithAction.id === item.id && (
                            <div ref={actionDashboardRef} />
                        )}
                    <button
                        className={`w-full h-full flex items-center justify-between py-0.5 px-2 border border-transparent hover:bg-backgrounds-primary100 hover:border hover:border-backgrounds-primary200 text-base ${
                            selectedDashboard &&
                            selectedDashboard.id === item.id
                                ? 'bg-backgrounds-primary100 hover:border-backgrounds-primary200 shadow-inner'
                                : ''
                        } ${'rounded-xl my-2'}`}
                        onClick={async (event) => {
                            if (selectedDashboard !== item) {
                                setSelectedDashboard(item);
                            }

                            if (transactionMode) {
                                setTransactionMode(!transactionMode);
                            }
                            event.stopPropagation();

                            if (window.innerWidth < 1024) {
                                closeSidebar(); // Call the closeSidebar function passed from navbar.jsx
                            }
                        }}
                    >
                        <div className='h-full flex items-center justify-center truncate'>
                            <FontAwesomeIcon
                                icon={faTableCellsLarge}
                                //color={getFileIconColor(item.dashboardName)}
                                className='mr-2 drop-shadow-sm text-icons-primary'
                            />
                            <div
                                className='h-full flex items-center truncate'
                                title={item.name}
                            >
                                <span className='truncate text-base'>
                                    {item.name}
                                </span>
                            </div>
                        </div>

                        <div className='flex items-center justify-center'>
                            <Dropdown
                                dropDownList={DashboardActionList}
                                icon={<FontAwesomeIcon icon={faEllipsisV} />}
                                showButtonText={false}
                                buttonText={null}
                                buttonHoverText={'More Dashboard Actions'}
                                additionalButtonAction={() => {
                                    setCloseDropdownSignal(false);
                                }}
                                titleText={'Dashboard Actions'}
                                buttonClassName={`px-3.5 py-2 ml-2`}
                                backgroundColor={'white'}
                                textColor={'green'}
                                placement={'right'}
                                closeSignal={closeDropdownSignal}
                            />
                        </div>
                    </button>
                </div>
            );
        });
    };

    return (
        <div
            className={`z-0 h-full w-full transition-all duration-700 ease-in-out bg-transparent p-1 m-0 rounded-xl`}
        >
            <ReflexContainer orientation='horizontal'>
                <ReflexElement
                    className='header border-b-2 border-b-backgrounds-white'
                    style={{
                        minHeight: '8rem',
                        height: '8rem',
                        maxHeight: '8rem',
                    }}
                >
                    <div className='pane-content w-full h-full pb-2 px-1'>
                        <div className='w-full h-full flex flex-col justify-between items-center text-texts-secondary300'>
                            <Brand />

                            <div className='w-full flex items-center justify-between'>
                                <div className='w-full flex items-center truncate'>
                                    <FontAwesomeIcon
                                        icon={faTableCells}
                                        className='mr-2 drop-shadow-sm text-icons-primary'
                                    />
                                    <div className='font-semibold truncate text-base'>
                                        Dashboards
                                    </div>
                                </div>
                                <div className='flex items-center justify-center'>
                                    <Button
                                        icon={faPlus}
                                        className={
                                            'mr-2 w-9 h-9 text-icons-primary'
                                        }
                                        onClick={() => {
                                            setAddNewDashboardMode(true);
                                        }}
                                        hoverText={'Create New Dashboard'}
                                        type={'success'}
                                    />
                                    <Button
                                        icon={faCaretLeft}
                                        className={'w-9 h-9 text-icons-primary'}
                                        onClick={() => {
                                            if (window.innerWidth > 1024) {
                                                // Adjust the breakpoint as per your design
                                                setIsSideViewExpanded(false);
                                            } else {
                                                closeSidebar(); // Call the closeSidebar function passed from navbar.jsx
                                            }
                                        }}
                                        hoverText={
                                            'Collapse and hide Collections view'
                                        }
                                        type={'warning'}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </ReflexElement>

                <ReflexElement flex={1}>
                    <div className='pane-content p-1'>
                        <div className='h-full w-full overflow-auto'>
                            {isLoading ? (
                                <div className='w-full h-32 flex justify-center items-center py-2'>
                                    <LoadingView loadingText={loadingText} />
                                </div>
                            ) : (
                                <Collections dashboardList={dashboardList} />
                            )}
                        </div>
                    </div>
                </ReflexElement>

                <ReflexElement
                    className='footer border-t-2 border-t-backgrounds-white'
                    style={{
                        minHeight: '9.5rem',
                        height: '9.5rem',
                        maxHeight: '9.5rem',
                    }}
                >
                    <div className='pane-content w-full h-full'>
                        <UserDetailAndAction />
                    </div>
                </ReflexElement>
            </ReflexContainer>

            {addNewDashboardMode && (
                <Modal
                    title={'Add New Dashboard'}
                    titleIcon={faPlus}
                    iconColor={'icons-primary'}
                    onClose={() => {
                        setAddNewDashboardMode(false);
                    }}
                >
                    <AddDashboardView
                        addNewMode={addNewDashboardMode}
                        setAddNewMode={setAddNewDashboardMode}
                        dashboardList={dashboardList}
                        setSelectedDashboard={setSelectedDashboard}
                        getDashboardList={getDashboardList}
                    />
                </Modal>
            )}
            {editDashboardMode && (
                <Modal
                    title={'Edit Dashboard'}
                    titleIcon={faPencil}
                    iconColor={'icons-primary'}
                    onClose={() => {
                        setEditDashboardMode(false);
                    }}
                >
                    <EditDashboardView
                        dashboardData={dashboardWithAction}
                        dashboardList={dashboardList}
                        selectedDashboard={selectedDashboard}
                        setSelectedDashboard={setSelectedDashboard}
                        setEditMode={setEditDashboardMode}
                        getDashboardList={getDashboardList}
                    />
                </Modal>
            )}
            {isConfirmDashboardModalOpen && (
                <ConfirmModal
                    modalText={`Are you sure you want to delete "${dashboardToDelete?.name}" dashboard?`}
                    onConfirm={() => handleDashboardDelete(dashboardToDelete)}
                    onCancel={() => {
                        setDashboardToDelete(null);
                        setIsConfirmDashboardModalOpen(false);
                    }}
                />
            )}
        </div>
    );
};

export default DashboardSideView;
