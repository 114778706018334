import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import axios from 'axios';
import { getFileIcon, getFileIconColor } from '../../utils';
import { faBoxOpen, faClone, faTrash } from '@fortawesome/free-solid-svg-icons';
import { Button, ConfirmModal, LoadingView } from '../reusableComponents';
import { getFileNameWithoutExt } from './utils';
import { getUrl } from '../pdfDisplay/utils';

const NoFileView = () => {
    return (
        <div className='w-full h-14 flex justify-center items-center'>
            <FontAwesomeIcon
                icon={faBoxOpen}
                className='mr-2 text-texts-secondary300'
            />
            <div className='text-texts-secondary300'>Collection Empty</div>
        </div>
    );
};

const FilesListLoadingView = ({ loadingText }) => {
    return (
        <div className='w-full h-24 flex justify-center items-center'>
            <LoadingView loadingText={loadingText} inLine={false} />
        </div>
    );
};

const LayoutFilesListView = ({
    email,
    currentRepo,
    allTemplatesFilesData,
    selectedTemplate,
    setSelectedTemplate,
    selectedLayout,
    setSelectedLayout,
    getLayoutsList,
    closeSidebar,
    setTemplateWithAction,
    setAddNewTemplateMode,
    setRetrievedTemplateData,
}) => {
    const user = JSON.parse(localStorage.getItem('current'));
    const [toDeleteLayout, setToDeleteLayout] = useState(null);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [loadingText, setLoadingText] = useState(false);

    const deleteLayout = async () => {
        try {
            setShowDeleteModal(false);

            const layoutName = toDeleteLayout.name;
            const templateName = currentRepo.filename;

            setLoadingText(`Deleting ${layoutName}...`);
            setIsLoading(true);

            const encodedToken = encodeURIComponent(user.token);
            const dynamicUrl = getUrl(
                `http://${process.env.REACT_APP_NODE_SERVER_HOST}:${process.env.REACT_APP_NODE_SERVER_PORT}/deleteLayout?email=${email}&templateName=${templateName}&layoutName=${layoutName}`
            );

            const response = await axios.delete(dynamicUrl, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: encodedToken,
                },
            });

            if (response) {
                console.log(response.data.message);

                if (
                    selectedLayout &&
                    selectedLayout.name === toDeleteLayout.name
                ) {
                    setSelectedLayout(null);
                }

                setLoadingText('Loading Layout List...');
                await getLayoutsList(currentRepo.filename);
            }

            setIsLoading(false);
            setLoadingText(``);
            setTemplateWithAction(currentRepo.filename);
        } catch (error) {
            console.error('An error occurred while deleting layout:', error);
            setIsLoading(false);
            setLoadingText(``);
            setTemplateWithAction(currentRepo.filename);
        }
    };

    const copyLayout = async (layout) => {
        try {
            const layoutName = layout.name;
            const templateName = currentRepo.filename;

            setLoadingText(`Copying ${layoutName}...`);
            setIsLoading(true);

            const encodedToken = encodeURIComponent(user.token);
            const dynamicUrl = getUrl(
                `http://${process.env.REACT_APP_NODE_SERVER_HOST}:${process.env.REACT_APP_NODE_SERVER_PORT}/copyLayout?email=${email}&templateName=${templateName}&layoutName=${layoutName}`
            );

            const response = await axios.get(dynamicUrl, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: encodedToken,
                },
            });

            if (response) {
                console.log(response.data.message);

                setLoadingText('Loading Layout List...');
                await getLayoutsList(currentRepo.filename);
            }

            setIsLoading(false);
            setLoadingText(``);
            setTemplateWithAction(currentRepo.filename);
        } catch (error) {
            console.error('An error occurred while copying layout:', error);
            setIsLoading(false);
            setLoadingText(``);
            setTemplateWithAction(currentRepo.filename);
        }
    };

    const repoFileData =
        allTemplatesFilesData && allTemplatesFilesData.size
            ? allTemplatesFilesData.get(currentRepo.filename)
            : null;

    if (repoFileData) {
        const isExpanded = repoFileData.isExpanded;
        const filesData = repoFileData.filesData;

        const list = filesData.map((item, index) => {
            return (
                <div
                    key={`${item.document_id} ${index}`}
                    className={`flex justify-between items-end`}
                >
                    <div className='overflow-ellipsis overflow-hidden pt-4 border-l-2 pr-2 flex justify-start items-center'>
                        <span
                            className='font-bold'
                            style={{ color: '#e6e6e8' }}
                        >
                            -
                        </span>
                        <div
                            className={`cursor-pointer border border-transparent hover:bg-backgrounds-primary100 hover:rounded-lg hover:border-backgrounds-primary200 px-2 truncate ${
                                selectedTemplate &&
                                currentRepo &&
                                selectedLayout &&
                                selectedTemplate.filename ===
                                    currentRepo.filename &&
                                selectedLayout.name === item.name
                                    ? 'bg-backgrounds-primary100 rounded-lg shadow-inner border'
                                    : 'bg-backgrounds-white'
                            }`}
                            onClick={(e) => {
                                setSelectedLayout(item);
                                setSelectedTemplate(currentRepo);
                                setAddNewTemplateMode(false);
                                setRetrievedTemplateData(null);
                                e.stopPropagation();
                                if (window.innerWidth < 1024) {
                                    closeSidebar(); // Call the closeSidebar function passed from navbar.jsx
                                }
                            }}
                        >
                            <FontAwesomeIcon
                                icon={getFileIcon(item.name)}
                                color={getFileIconColor(item.name)}
                                className='mr-2 drop-shadow-sm bg-backgrounds-white rounded-full'
                            />
                            <span
                                className='truncate text-base'
                                title={getFileNameWithoutExt(item.name)}
                            >
                                {getFileNameWithoutExt(item.name)}
                            </span>
                        </div>
                    </div>

                    <div className='mt-2 flex justify-end items-center'>
                        <Button
                            className='ml-2'
                            onClick={(event) => {
                                copyLayout(item);
                                event.stopPropagation();
                            }}
                            hoverText={`Copy ${getFileNameWithoutExt(
                                item.name
                            )} layout file`}
                            icon={faClone}
                            type={'info'}
                        />
                        <Button
                            className='ml-2'
                            onClick={(event) => {
                                setShowDeleteModal(true);
                                setToDeleteLayout(item);
                                event.stopPropagation();
                            }}
                            hoverText={`Delete ${getFileNameWithoutExt(
                                item.name
                            )} layout file`}
                            icon={faTrash}
                            type={'danger'}
                        />
                    </div>
                </div>
            );
        });

        if (isExpanded) {
            return (
                <div>
                    <div
                        className={`bg-backgrounds-white rounded-b-xl pl-4 pr-2 pb-3 truncate shadow-sm border`}
                    >
                        {isLoading ? (
                            <FilesListLoadingView loadingText={loadingText} />
                        ) : (
                            <>
                                {repoFileData &&
                                repoFileData.filesData &&
                                repoFileData.filesData.length ? (
                                    list
                                ) : (
                                    <NoFileView />
                                )}
                            </>
                        )}
                    </div>
                    {showDeleteModal && (
                        <ConfirmModal
                            modalText={`Delete '${getFileNameWithoutExt(
                                toDeleteLayout.name
                            )}' layout file?`}
                            onConfirm={async () => await deleteLayout()}
                            onCancel={() => setShowDeleteModal(false)}
                        />
                    )}
                </div>
            );
        }
    }
    return <></>;
};

export default LayoutFilesListView;
