import React, { useEffect, useState, useCallback } from 'react';
import {
    faBook,
    faEnvelope,
    faHeadset,
    faMoneyBillWave,
    faQuestionCircle,
    faUserGear,
    faDownload,
    faMessage,
    faFileExport,
    faCog,
    faReceipt,
} from '@fortawesome/free-solid-svg-icons';
import { AllArticles } from './allArticles';
import 'react-reflex/styles.css';
import { ReflexContainer, ReflexElement, ReflexSplitter } from 'react-reflex';
import { CollapsedSideBar } from '../collapsedSideBar';
import { SupportSideBar } from './supportSidebar';
import { SupportBeforeSelect } from './supportBeforeSelect';
import { Button } from '../reusableComponents';
import Navbar from '../navbar';
import { useLocation, useNavigate } from 'react-router';
import { useGlobalCredits } from '../GlobalStateContext';
import TransactionList from '../Transactionlist';
import { getUrl } from '../pdfDisplay/utils';

export const SupportSections = Object.freeze({
    START: 'Getting Started',
    BILLING: 'Accounts and Billing',
    REPORTDESINGER: 'Report Designer Features',
    CHATFEATURES: 'Chat Section Features',
    EXTRACTFEATURES: 'Extraction Section Features',
    SETTINGFEATURES: 'Setting Section Features',
});

const getHelpSupportData = async (screenWidth, screenHeight) => {
    const user = JSON.parse(localStorage.getItem('current'));
    const encodedToken = encodeURIComponent(user.token);

    try {
        const response = await fetch(
            getUrl(
                `http://${process.env.REACT_APP_NODE_SERVER_HOST}:${process.env.REACT_APP_NODE_SERVER_PORT}/helpAndSupport`
            ),
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: encodedToken,
                },
                body: JSON.stringify({ screenWidth, screenHeight }),
            }
        );

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const result = await response.text();
        const temporary = JSON.parse(result);
        return result;
    } catch (error) {
        console.error('Error:', error);
    }
};

const topics = [
    {
        name: SupportSections.START,
        icon: faBook,
        hoverText: 'Basic information on getting started with software',
    },
    {
        name: SupportSections.REPORTDESINGER,
        icon: faReceipt,
        hoverText: 'All Features of Report Designer',
    },
    {
        name: SupportSections.BILLING,
        icon: faMoneyBillWave,
        hoverText: 'All Features of Billing Section',
    },
    {
        name: SupportSections.CHATFEATURES,
        icon: faMessage,
        hoverText: 'All Features of Chats Section',
    },
    {
        name: SupportSections.EXTRACTFEATURES,
        icon: faFileExport,
        hoverText: 'All Features of Extraction Section',
    },
    {
        name: SupportSections.SETTINGFEATURES,
        icon: faCog,
        hoverText: 'All Features of Setting Section',
    },
];

const gettingStartedQueriesData = [
    {
        query: 'Basic Introduction of Chat Feature.',
        heading: 'Introduction',
        subHeading: 'Chat Feature',
        raw: 'raw',
    },
    {
        query: 'Basic Introduction of Extraction Feature.',
        heading: 'Introduction',
        subHeading: 'Extraction Feature',
        raw: 'raw',
    },
    {
        query: 'What are our Purpose and Goals?',
        heading: 'Introduction',
        subHeading: 'Purpose and Goals',
        raw: 'raw',
    },
    {
        query: 'Who are our Target Audience?',
        heading: 'Introduction',
        subHeading: 'Target Audience',
        raw: 'raw',
    },
];

const reportDesignerQueries = [
    {
        query: 'What is Report designer ?',
        heading: 'User Access and Usage',
        subHeading1: 'Main Interface - Report Designer:',
        subHeading2: 'Basics of Report Designer',
        raw: 'raw',
    },
    {
        query: 'How to design a layout for Report ?',
        heading: 'User Access and Usage',
        subHeading1: 'Main Interface - Report Designer:',
        subHeading2: 'Layout Design',
        raw: 'raw',
    },
    {
        query: 'How to add different cards in Report ?',
        heading: 'User Access and Usage',
        subHeading1: 'Main Interface - Report Designer:',
        subHeading2: 'Cards in Layout',
        raw: 'raw',
    },
];

const chatFeaturesQueriesData = [
    {
        query: 'How to create a Repository?',
        heading: 'User Access and Usage',
        subHeading1: 'Main Interface - Chats Page:',
        subHeading2: 'Creating a Repository:',
        raw: 'raw',
    },
    {
        query: 'How to ask question and answers?',
        heading: 'User Access and Usage',
        subHeading1: 'Main Interface - Chats Page:',
        subHeading2: 'Asking Questions and Providing Answers:',
        raw: 'raw',
    },
    {
        query: 'Different types of feature in option menu for Repositories',
        heading: 'User Access and Usage',
        subHeading1: 'Main Interface - Chats Page:',
        subHeading2: 'Options Menu on repo side:',
        raw: 'raw',
    },
];
const extractionFeaturesQueriesData = [
    {
        query: 'How to select a template?',
        heading: 'User Access and Usage',
        subHeading1: 'Main Interface-Extraction Section:',
        subHeading2: 'Selecting a Template:',
        raw: 'raw',
    },
    {
        query: 'How to extract data?',
        heading: 'User Access and Usage',
        subHeading1: 'Main Interface-Extraction Section:',
        subHeading2: 'Data Extraction:',
        raw: 'raw',
    },
    {
        query: 'Features of Extraction Table',
        heading: 'User Access and Usage',
        subHeading1: 'Main Interface-Extraction Section:',
        subHeading2: 'Display Table for Extraction Results:',
        raw: 'raw',
    },
];
const settingFeaturesQueriesData = [
    {
        query: 'General settings.',
        heading: 'User Access and Usage',
        subHeading1: 'Settings Section Overview:',
        subHeading2: 'General Settings:',
        raw: 'raw',
    },
    {
        query: 'Chats section related settings.',
        heading: 'User Access and Usage',
        subHeading1: 'Settings Section Overview:',
        subHeading2: 'Chats Section:',
        raw: 'raw',
    },
    {
        query: 'Extraction section related settings.',
        heading: 'User Access and Usage',
        subHeading1: 'Settings Section Overview:',
        subHeading2: 'Extraction Section:',
        raw: 'raw',
    },
    {
        query: 'Saving the changed settings.',
        heading: 'User Access and Usage',
        subHeading1: 'Settings Section Overview:',
        subHeading2: 'Save settings',
        raw: 'raw',
    },
];

const billingQueriesData = [
    {
        query: 'Billing Details.',
        heading: 'User Access and Usage',
        subHeading1: 'Main Interface-Billing Section:',
        subHeading2: 'General overview',
        raw: 'raw',
    },
    {
        query: 'How to add credits ?',
        heading: 'User Access and Usage',
        subHeading1: 'Main Interface-Billing Section:',
        subHeading2: 'Add credits',
        raw: 'raw',
    },
];

export const Support = ({
    newNotification,
    setNewNotification,
    viewNotifications,
    setViewNotifications,
    messages,
    socket,
    isSideViewExpanded,
    setIsSideViewExpanded,
    displayFloatingButton,
    setDisplayFloatingButton,
}) => {
    const [activeSection, setActiveSection] = useState(null);
    const [queriesData, setQueriesData] = useState([]);
    const [helpSupportData, setHelpSupportData] = useState(null);
    const [isLoading, setLoading] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    const { transactionMode, setTransactionMode } = useGlobalCredits();

    const navigate = useNavigate();
    const location = useLocation();
    const path = location.pathname;

    useEffect(() => {
        const fetchDataAndSetQueriesData = async () => {
            setLoading(true);
            const helpdata = await getHelpSupportData();
            setHelpSupportData(helpdata);
            setLoading(false);
        };

        (async () => {
            await fetchDataAndSetQueriesData();
        })();

        return () => {};
    }, []);

    const fetchDataAndSetQueriesData = useCallback(async () => {
        setLoading(true);
        const screenWidth = window.innerWidth;
        const screenHeight = window.innerHeight;
        const helpdata = await getHelpSupportData(screenWidth, screenHeight);
        setHelpSupportData(helpdata);
        setLoading(false);
    }, []);

    useEffect(() => {
        fetchDataAndSetQueriesData();
    }, [fetchDataAndSetQueriesData]);

    useEffect(() => {
        const queriesData = getQueriesData(activeSection);
        setQueriesData(queriesData);
    }, [activeSection, helpSupportData]);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 1024); // Change 1024 to your desired breakpoint
            fetchDataAndSetQueriesData(); // Fetch new data on resize
        };

        handleResize(); // Initial check
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [fetchDataAndSetQueriesData]);

    const getQueriesData = (activeSection) => {
        const helpDataParsed = helpSupportData
            ? JSON.parse(helpSupportData)
            : null;
        if (activeSection === SupportSections.START && helpDataParsed) {
            return gettingStartedQueriesData.map((query) => {
                const solutionHTML =
                    helpDataParsed[query.heading][query.subHeading][query.raw];
                return {
                    ...query,
                    solution: {
                        __html: solutionHTML, // Return as HTML string
                    },
                };
            });
        } else if (
            activeSection === SupportSections.REPORTDESINGER &&
            helpDataParsed
        ) {
            return reportDesignerQueries.map((query) => {
                const solutionHTML =
                    helpDataParsed[query.heading][query.subHeading1][
                        query.subHeading2
                    ][query.raw];
                return {
                    ...query,
                    solution: {
                        __html: solutionHTML, // Return as HTML string
                    },
                };
            });
        } else if (
            activeSection === SupportSections.CHATFEATURES &&
            helpDataParsed
        ) {
            return chatFeaturesQueriesData.map((query) => {
                const solutionHTML =
                    helpDataParsed[query.heading][query.subHeading1][
                        query.subHeading2
                    ][query.raw];
                return {
                    ...query,
                    solution: {
                        __html: solutionHTML, // Return as HTML string
                    },
                };
            });
        } else if (
            activeSection === SupportSections.EXTRACTFEATURES &&
            helpDataParsed
        ) {
            return extractionFeaturesQueriesData.map((query) => {
                const solutionHTML =
                    helpDataParsed[query.heading][query.subHeading1][
                        query.subHeading2
                    ][query.raw];
                return {
                    ...query,
                    solution: {
                        __html: solutionHTML, // Return as HTML string
                    },
                };
            });
        } else if (
            activeSection === SupportSections.SETTINGFEATURES &&
            helpDataParsed
        ) {
            return settingFeaturesQueriesData.map((query) => {
                const solutionHTML =
                    helpDataParsed[query.heading][query.subHeading1][
                        query.subHeading2
                    ][query.raw];
                return {
                    ...query,
                    solution: {
                        __html: solutionHTML, // Return as HTML string
                    },
                };
            });
        } else if (
            activeSection === SupportSections.BILLING &&
            helpDataParsed
        ) {
            return billingQueriesData.map((query) => {
                const solutionHTML =
                    helpDataParsed[query.heading][query.subHeading1][
                        query.subHeading2
                    ][query.raw];
                return {
                    ...query,
                    solution: {
                        __html: solutionHTML, // Return as HTML string
                    },
                };
            });
        }
    };

    return (
        <div className='z-0 w-[99vw] h-[99vh] m-auto pt-2'>
            <ReflexContainer orientation='vertical'>
                {!isMobile && (
                    <ReflexElement
                        className='left-pane mr-0.5'
                        minSize={isSideViewExpanded ? 250 : 50}
                        size={isSideViewExpanded ? 350 : 50}
                        maxSize={isSideViewExpanded ? 700 : 50}
                    >
                        <div className='pane-content w-full h-full bg-transparent'>
                            {isSideViewExpanded ? (
                                <div className='w-full h-full border border-2 overflow-auto bg-backgrounds-slate rounded-xl p-2 z-10'>
                                    <SupportSideBar
                                        topics={topics}
                                        activeSection={activeSection}
                                        setActiveSection={setActiveSection}
                                        setIsLeftViewExpanded={
                                            setIsSideViewExpanded
                                        }
                                        displayFloatingButton={
                                            displayFloatingButton
                                        }
                                        setDisplayFloatingButton={
                                            setDisplayFloatingButton
                                        }
                                    />
                                </div>
                            ) : (
                                <CollapsedSideBar
                                    icon={faHeadset}
                                    iconColor={'icons-primary'}
                                    title={'Support Topics'}
                                    setIsRepoViewExpanded={
                                        setIsSideViewExpanded
                                    }
                                />
                            )}
                        </div>
                    </ReflexElement>
                )}

                <ReflexSplitter
                    style={{
                        border: 0,
                        background: 'white',
                        width: '6px',
                        zIndex: 10,
                        height: '98vh',
                    }}
                />

                <ReflexElement className='right-pane ml-0.5'>
                    <div className='pane-content w-full h-full bg-transparent'>
                        <div className='w-full h-full border border-2 overflow-auto bg-backgrounds-slate rounded-xl z-0'>
                            <ReflexContainer orientation='horizontal'>
                                <ReflexElement
                                    className='header'
                                    style={{
                                        minHeight: '4rem',
                                        height: '4rem',
                                        maxHeight: '4rem',
                                    }}
                                >
                                    <div className='pane-content w-full h-full p-2'>
                                        <Navbar
                                            //changes in navbar required
                                            topics={topics}
                                            activeSection={activeSection}
                                            setActiveSection={setActiveSection}
                                            setIsLeftViewExpanded={
                                                setIsSideViewExpanded
                                            }
                                            newNotification={newNotification}
                                            setNewNotification={
                                                setNewNotification
                                            }
                                            viewNotifications={
                                                viewNotifications
                                            }
                                            setViewNotifications={
                                                setViewNotifications
                                            }
                                            messages={messages}
                                            socket={socket}
                                            displayFloatingButton={
                                                displayFloatingButton
                                            }
                                            setDisplayFloatingButton={
                                                setDisplayFloatingButton
                                            }
                                        />
                                    </div>
                                </ReflexElement>

                                <ReflexElement flex={1}>
                                    <div className='pane-content h-full w-full p-2'>
                                        {transactionMode ? (
                                            <>
                                                <TransactionList />
                                            </>
                                        ) : (
                                            <>
                                                {activeSection ? (
                                                    <AllArticles
                                                        queriesData={
                                                            queriesData
                                                        }
                                                    />
                                                ) : (
                                                    <SupportBeforeSelect />
                                                )}
                                            </>
                                        )}

                                        {/* Floating button */}
                                        {displayFloatingButton && (
                                            <div className='fixed z-50 bottom-10 right-8 w-fit h-fit rounded-full drop-shadow-lg flex justify-center items-center'>
                                                <Button
                                                    onClick={() => {
                                                        if (
                                                            path !==
                                                            '/contactUs'
                                                        ) {
                                                            navigate(
                                                                '/contactUs'
                                                            );
                                                        }
                                                    }}
                                                    text={'Contact Us'}
                                                    icon={faEnvelope}
                                                    hoverText={
                                                        'Contact us directly'
                                                    }
                                                    className={
                                                        'hover:scale-110'
                                                    }
                                                    type={'success'}
                                                    disabled={false}
                                                />
                                            </div>
                                        )}
                                    </div>
                                </ReflexElement>
                            </ReflexContainer>
                        </div>
                    </div>
                </ReflexElement>
            </ReflexContainer>
        </div>
    );
};
