import React, { useState, useEffect, useMemo } from 'react';
import { Switch } from 'antd';
import { Button, FolderListViewer } from './reusableComponents';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGoogleDrive } from '@fortawesome/free-brands-svg-icons';
import {
    faCircleXmark,
    faFolderPlus,
    faExclamationTriangle,
} from '@fortawesome/free-solid-svg-icons';
import GoogleDrivePicker from 'google-drive-picker';
import { PublicClientApplication } from '@azure/msal-browser';
import { PLATFORM } from '../enums';
import { ProcessType } from '../constants/processTypes';
import { TaskProgressType } from '../constants/taskProgressTypes';
import { getUrl } from './pdfDisplay/utils';
const user = JSON.parse(localStorage.getItem('current'));

const AddCloudRepo = ({
    reponame,
    setAddNewMode,
    getAllReposData,
    socket,
    isAdmin,
    visionRepo,
    onChangeVision,
    visionModel,
    setVisionModel,
    visionModelDropdown,
    error,
    user,
}) => {
    const [selectedFolder, setSelectedFolder] = useState(null);
    const [platform, setPlatform] = useState(null); // New state for platform
    const [authTocken, setauthTocken] = useState('');
    const [authToken, setauthToken] = useState('');
    const [openPicker, authRes] = GoogleDrivePicker();
    const [oneDriveRefreshToken, setOneDriveRefreshToken] = useState(null);
    // Dynamically load the OneDrive SDK script
    // Dynamically load the OneDrive SDK when the component mounts
    const msalInstance = useMemo(() => {
        const msalConfig = {
            auth: {
                clientId: process.env.REACT_APP_ONE_DRIVE_CLIENT_ID,
                authority: 'https://login.microsoftonline.com/common',
                redirectUri: window.location.origin,
            },
            cache: {
                cacheLocation: 'localStorage',
            },
        };
        return new PublicClientApplication(msalConfig);
    }, []);
    const loginToOneDrive = async () => {
        try {
            await msalInstance.initialize();
            const loginResponse = await msalInstance.loginPopup({
                scopes: ['Files.ReadWrite.All', 'offline_access'], // Request offline_access scope for refresh token
            });

            const accessToken = loginResponse.accessToken;
            //const refreshToken = loginResponse.idToken; // This contains the refresh token if offline_access is provided
            setauthToken(accessToken);
            // Store the refresh token in local storage (you can choose to store it elsewhere for security reasons)

            //setOneDriveRefreshToken(refreshToken);
            openOneDrivePicker(accessToken);
        } catch (error) {
            console.error('OneDrive login error:', error);
        }
    };

    const openOneDrivePicker = (accessToken) => {
        const odOptions = {
            clientId: process.env.REACT_APP_ONE_DRIVE_CLIENT_ID,
            action: 'share',
            multiSelect: false,
            viewType: 'all',
            accountSwitchEnabled: true,
            accessToken: accessToken,
            success: (files) => {
                const selectedItem = files;
                if (selectedItem) {
                    if (selectedItem.value[0].folder) {
                        // Check if the selected item is a folder
                        setSelectedFolder(selectedItem);
                    } else {
                        console.error('Only folder selection allowed.');
                        alert('Please select a folder, not a file.');
                    }
                } else {
                    console.error('No item selected.');
                }
            },
            cancel: () => {
                console.log('Folder selection cancelled.');
            },
            error: (err) => {
                console.error('OneDrive folder picker error:', err);
            },
        };

        window.OneDrive.open(odOptions);
    };

    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://js.live.net/v7.2/OneDrive.js';
        script.async = true;
        script.onload = () => console.log('OneDrive SDK loaded');
        document.body.appendChild(script);
        return () => {
            console.log('Cleaning up OneDrive SDK script...');
            document.body.removeChild(script);
        };
    }, []);

    const handleOpenPicker = async (platformName) => {
        try {
            setPlatform(platformName); // Set the platform (Google Drive or OneDrive)
            if (platformName === PLATFORM.GOOGLE_DRIVE) {
                openPicker({
                    clientId: process.env.REACT_APP_GOOGLE_CLIENT_DRIVE_ID,
                    developerKey: process.env.REACT_APP_GOOGLE_PICKER_API,
                    viewId: 'ALL', // Set view to folders
                    token: authTocken.acess_token,
                    showUploadView: false, // Disable file uploads
                    showUploadFolders: false,
                    supportDrives: true,
                    multiselect: false, // Allow only one folder selection
                    setSelectFolderEnabled: true,
                    setIncludeFolders: true,
                    callbackFunction: (data) => {
                        if (data.action === 'cancel') {
                            console.log('User clicked cancel/close button');
                        } else if (data.docs && data.docs.length > 0) {
                            const selectedItem = data.docs[0];
                            if (
                                selectedItem.mimeType ===
                                'application/vnd.google-apps.folder'
                            ) {
                                setSelectedFolder(selectedItem); // Update selected folder
                            } else {
                                // It's a file, so show an error and ask the user to select a folder
                                console.error(
                                    'Only folder selection is allowed.'
                                );
                                alert('Please select a folder, not a file.');
                            }
                        }
                    },
                });
            } else if (platformName === PLATFORM.ONE_DRIVE) {
                loginToOneDrive();
            }
        } catch (error) {
            console.error(`Error opening ${platformName} Picker:`, error);
        }
    };
    useEffect(() => {
        if (authRes) {
            setauthTocken(authRes);
        }
    }, [authRes]);

    const submitForm = async (e) => {
        e.preventDefault();

        if (!selectedFolder || error) {
            return;
        }

        const folderData = {
            id: user.email,
            // name:
            //     platform === 'OneDrive'
            //         ? selectedFolder.value[0].name
            //         : selectedFolder.name,
            name: reponame,
            visionRepo: visionRepo,
            visionModel:
                    visionModel === 'Percision Model'
                        ? 'gemini-1.5-pro'
                        : 'gemini-1.5-flash',
            vector_store_type: 'CHROMA',
            summary_flag: false,
        };

        const requestBody = {
            platform: platform,
            email: user.email,
            //repo_name: reponame,
            repo_name: reponame,
            // platform === 'OneDrive'
            //     ? selectedFolder.value[0].name
            //     : selectedFolder.name,
            token_file:
                platform === PLATFORM.ONE_DRIVE
                    ? authToken
                    : authTocken.access_token,
            folder_id:
                platform === PLATFORM.ONE_DRIVE
                    ? selectedFolder.value[0].id
                    : selectedFolder.id, // Get folder ID from selected folder
        };

        const encodedToken = encodeURIComponent(user.token);
        setAddNewMode(false); //close the form

        try {
            const status = {
                message: `Started repository creation with name ${reponame}`,
                percentageCompletion: 0,
                process: ProcessType.FILE_UPLOAD,
                progress: TaskProgressType.STARTED,
                repoId: null,
                repoName: reponame,
                statusCode: 200,
                timeStamp: new Date(),
                userMail: user.email,
            };

            socket.emit('statusUpdate', status);
            // Call the first API to store Google/OneDrive folder information
            const repoDataString = encodeURIComponent(
                JSON.stringify(folderData)
            );
            const repoResponse = await fetch(
                getUrl(
                    `http://${process.env.REACT_APP_NODE_SERVER_HOST}:${process.env.REACT_APP_NODE_SERVER_PORT}/new_empty_repo/${repoDataString}`
                ),
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: encodedToken,
                    },
                }
            );

            if (repoResponse.ok) {
                const folderResponse = await fetch(
                    getUrl(
                        `http://${process.env.REACT_APP_FAST_API_HOST}:${process.env.REACT_APP_FAST_API_PORT}/process_drive_info`
                    ),
                    {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(requestBody),
                        Authorization: encodedToken,
                    }
                );

                if (folderResponse.ok) {
                    // Both API calls were successful
                    console.log('Repository created successfully');
                    getAllReposData(); // Refresh the repository data
                } else {
                    // Handle error from repo creation
                    const errorData = await folderResponse.json();
                    console.error('Error adding repository folder:', errorData);
                }
            } else {
                // Handle error from folder upload
                const errorData = await repoResponse.json();
                console.error('Error adding repository folder:', errorData);
            }
        } catch (err) {
            console.error('Error during API requests:', err);
        }
    };

    return (
        <form onSubmit={submitForm}>
            <div className='flex flex-col justify-center items-center min-h-[25vh] bg-backgrounds-white rounded-xl my-4 border-2 border-dashed border-backgrounds-primary300'>
                <div className='text-center text-texts-secondary300 mb-4'>
                    Connect with either OneDrive or Google Drive to sync your
                    files with the repository.
                </div>
                <div className='flex space-x-6 items-center'>
                    <div
                        className='flex flex-col items-center cursor-pointer hover:border hover:border-backgrounds-primary300 p-4 rounded-lg transition-all duration-200'
                        onClick={() => handleOpenPicker(PLATFORM.GOOGLE_DRIVE)}
                    >
                        <FontAwesomeIcon
                            icon={faGoogleDrive}
                            size='3x'
                            className='text-backgrounds-primary300'
                        />
                        <span className='mt-2 font-semibold text-texts-secondary300'>
                            Google Drive
                        </span>
                    </div>
                    <div className='border-l-2 h-16 border-gray-300'></div>

                    <div
                        className='flex flex-col items-center cursor-pointer hover:border hover:border-backgrounds-primary300 p-4 rounded-lg transition-all duration-200'
                        onClick={() => handleOpenPicker(PLATFORM.ONE_DRIVE)}
                    >
                        <img
                            src='/OneDriveIcon.png'
                            alt='OneDrive'
                            className='w-12 h-12'
                        />
                        <span className='mt-2 font-semibold text-texts-secondary300'>
                            OneDrive
                        </span>
                    </div>
                </div>
            </div>
            <div className='pt-4'>
                <FolderListViewer
                    className='mt-5'
                    selectedFolder={selectedFolder}
                    platform={platform}
                />
                {/* {false && (
                    <div className='flex justify-between items-center mt-3'>
                        <div className='flex items-center justify-center mt-4'>
                            <div className='mr-2 font-semibold text-texts-secondary300'>
                                Vision Processing
                            </div>
                            <Switch
                                checked={visionRepo}
                                onChange={onChangeVision}
                            />
                        </div>
                    </div>
                )} */}
                    <div className='flex items-center mt-4 '>
                        <div className='w-1/8 font-semibold text-texts-secondary300'>
                            <label htmlFor='Vision Model'>Vision Model</label>
                        </div>
                        <div className='ml-2 w-full md:w-1/4'>
                            <div className='relative'>
                                <select
                                    id='Vision Model'
                                    className='block w-auto md:w-30 p-2 mt-1 rounded-xl shadow-sm focus:ring-backgrounds-primary500 focus:border-backgrounds-primary400 hover:outline-none focus:outline-backgrounds-primary200 border hover:border-backgrounds-primary300'
                                    value={visionModel}
                                    onChange={(e) =>
                                        setVisionModel(e.target.value)
                                    }
                                >
                                    <option disabled={true} value={null}>
                                        Select a Vision Model
                                    </option>
                                    {visionModelDropdown.map((method) => (
                                        <option key={method} value={method}>
                                            {method}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    </div>
            </div>
            {error && (
                <div className='flex justify-center items-center text-buttons-alert400 mt-3'>
                    <FontAwesomeIcon
                        icon={faExclamationTriangle}
                        className='mr-2'
                    />
                    {error}
                </div>
            )}
            <div className='flex items-center justify-center mt-4'>
                <div className='flex items-center justify-between w-full'>
                    <Button
                        type='danger'
                        icon={faCircleXmark}
                        text={'Cancel'}
                        tile={'Cancel and close the modal'}
                        className='w-1/3'
                        onClick={() => setAddNewMode(false)}
                    />
                    <button
                        className={`flex items-center justify-center rounded-full py-1.5 px-2.5 rounded-lg order bg-backgrounds-white shadow-inner border font-semibold w-1/3 ${
                            error || !selectedFolder || !reponame
                                ? 'text-texts-secondary300 cursor-not-allowed'
                                : 'hover:bg-backgrounds-primary100 hover:border-backgrounds-primary200 text-backgrounds-primary400'
                        }`}
                        type='submit'
                        title='Upload files and add new repository'
                        disabled={error || !selectedFolder || !reponame}
                    >
                        <FontAwesomeIcon icon={faFolderPlus} className='mr-2' />
                        Add
                    </button>
                </div>
            </div>
        </form>
    );
};

export default AddCloudRepo;
