import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { toSentenceCase } from '../../reportLayouts/utils';
import { Button, Dropdown } from '../../reusableComponents';
import {
    faCompress,
    faExpand,
    faPencil,
    faChartSimple,
} from '@fortawesome/free-solid-svg-icons';
import { ChartView } from './chartView';
import {
    chartDropDownData,
    ChartTypes,
    demoChartData,
    iconColor,
} from './constants';

export const getChartIcon = (selectedChart) => {
    const foundData = chartDropDownData.find(
        (data) => data.label === selectedChart
    );
    const icon = foundData ? foundData.icon : faChartSimple;
    return icon;
};

export const ChartWithControls = ({
    selectedChart,
    setSelectedChart,
    chartData,
    chartName,
    chartOptions,
    closeDropdownSignal,
    showChartInFullScreen,
    toggleChartViewInFullScreen,
    enableEditing,
    setEnableEditing,
}) => {
    const hasChartData = chartData && chartData.length;
    const cData = hasChartData ? chartData : demoChartData;
    const [chartDropDownList, setChartDropDownList] = useState([]);

    const handleEditing = () => {
        toggleChartViewInFullScreen(true);
        setEnableEditing(true);
    };

    const handleSizeChange = () => {
        toggleChartViewInFullScreen();
        setEnableEditing(false);
    };

    useEffect(() => {
        const dropDownData = chartDropDownData.map((data) => {
            return {
                label: toSentenceCase(data.label),
                icon: data.icon,
                iconColor: data.iconColor,
                action: () => {
                    setSelectedChart(data.label);
                },
                selected: selectedChart === data.label,
            };
        });
        setChartDropDownList(dropDownData);
    }, [selectedChart]);

    return (
        <div className={`w-full h-full`}>
            <div
                className={`w-full flex items-center justify-between px-2 border-b h-[55px]`}
            >
                <div className='font-semibold text-texts-secondary300'>
                    <FontAwesomeIcon
                        icon={faChartSimple}
                        color={iconColor}
                        className='mr-2'
                    />
                    <span>Result View</span>
                </div>
                <div className='w-fit flex items-center'>
                    <Dropdown
                        dropDownList={chartDropDownList}
                        showButtonText={true}
                        buttonText={
                            <>
                                <FontAwesomeIcon
                                    icon={getChartIcon(selectedChart)}
                                    className='mr-2'
                                />
                                {toSentenceCase(selectedChart)}
                            </>
                        }
                        buttonHoverText={'Select chart to represent data'}
                        buttonTitle={'Selected View'}
                        buttonClassName={'w-60'}
                        backgroundColor={'white'}
                        textColor={''}
                        placement={'rightTop'}
                        closeSignal={closeDropdownSignal}
                    />
                </div>

                <div className='flex justify-between items-center'>
                    {!enableEditing && (
                        <Button
                            text={''}
                            icon={faPencil}
                            className={'mx-2'}
                            onClick={handleEditing}
                            disabled={false}
                            hoverText={'Edit Chart'}
                            type={'success'}
                        />
                    )}
                    <Button
                        text={''}
                        icon={showChartInFullScreen ? faCompress : faExpand}
                        className={''}
                        onClick={handleSizeChange}
                        hoverText={
                            showChartInFullScreen
                                ? 'Minimize the chart view'
                                : 'Expand chart view in full screen'
                        }
                        type={'success'}
                    />
                </div>
            </div>

            <div
                className='w-full'
                style={{
                    height:
                        selectedChart === ChartTypes.TABLE ? 'h-fit' : '90%',
                    backgroundColor: chartOptions.backgroundColor,
                    color: chartOptions.color,
                }}
            >
                <ChartView
                    chartData={{
                        chart: selectedChart,
                        data: cData,
                        options: chartOptions,
                    }}
                    chartName={chartName}
                    showNoDataWarning={false}
                    usingDemoData={!hasChartData}
                    isDemoPreview={true}
                    enableEditing={enableEditing}
                />
            </div>
        </div>
    );
};
