import React, { useState, forwardRef, useEffect } from 'react';
import { Card, Badge, Switch, message } from 'antd';
import { CheckOutlined } from '@ant-design/icons';
import { usePlanData } from '../../hooks/usePlanData';
import {
    SUBSCRIPTION_PLANS,
    YEARLY_DISCOUNT_PERCENTAGE,
} from '../../utils/planUtils';
import { Currency } from '../../constants/currency';
import { LoadingView } from '../../components/reusableComponents';

const PricingPage = forwardRef((props, ref) => {
    const { plans, error } = usePlanData();
    const [isYearly, setIsYearly] = useState(true);

    useEffect(() => {
        if (error) {
            message.error(error);
        }
    }, [error]);

    if (!plans || plans.length === 0) {
        return (
            <div className='pt-6'>
                <LoadingView loadingText='Loading pricing information' />
            </div>
        );
    }

    const handleSwitchChange = (checked) => {
        try {
            setIsYearly(checked);
        } catch (error) {
            console.error('Error switching billing period:', error);
            message.error('Failed to switch billing period');
        }
    };

    return (
        <div
            ref={ref}
            className='flex flex-col items-center max-w-[1440px] mx-auto px-2'
        >
            <div className='flex flex-col items-center gap-4 mt-6 mx-0 md:mx-8 lg:mx-20 rounded-lg shadow-md p-8 py-16 bg-backgrounds-secondaryChat'>
                <div className='flex items-center justify-center gap-4 mb-4'>
                    <span
                        className={`${
                            !isYearly ? 'font-bold' : 'text-gray-500'
                        } text-sm`}
                    >
                        Monthly
                    </span>
                    <Switch
                        checked={isYearly}
                        onChange={handleSwitchChange}
                        className='bg-backgrounds-primary300'
                    />
                    <span
                        className={`${
                            isYearly ? 'font-bold' : 'text-gray-500'
                        } text-sm`}
                    >
                        Yearly{' '}
                        <span className='text-green-500'>
                            (Save {YEARLY_DISCOUNT_PERCENTAGE}%)
                        </span>
                    </span>
                </div>

                <div className='flex flex-nowrap justify-center items-stretch gap-4'>
                    {plans
                        .filter((plan) => {
                            if (plan.name === SUBSCRIPTION_PLANS.FREE)
                                return true;

                            if (isYearly) {
                                return plan.name
                                    .toLowerCase()
                                    .includes('yearly');
                            } else {
                                return !plan.name
                                    .toLowerCase()
                                    .includes('yearly');
                            }
                        })
                        .sort((a, b) => {
                            const planOrder = [
                                'FREE',
                                'BASIC',
                                'PRO',
                                'ENTERPRISE',
                            ];
                            const planA = a.name.split('_')[0].toUpperCase();
                            const planB = b.name.split('_')[0].toUpperCase();
                            return (
                                planOrder.indexOf(planA) -
                                planOrder.indexOf(planB)
                            );
                        })
                        .map((plan, index) => {
                            const displayName = plan.name.split('_')[0];
                            const isMostPopular =
                                displayName.toLowerCase() === 'pro';

                            return (
                                <Badge.Ribbon
                                    key={index}
                                    text={isMostPopular ? 'Most Popular' : ''}
                                    color={isMostPopular ? 'blue' : 'white'}
                                >
                                    <Card
                                        className='w-64 h-[480px] rounded-xl shadow-md hover:shadow-lg transition duration-200 flex flex-col flex-shrink-0'
                                        title={displayName}
                                        headStyle={{
                                            backgroundColor: isMostPopular
                                                ? '#e0f7ff'
                                                : 'white',
                                            textAlign: 'center',
                                            fontWeight: 'bold',
                                        }}
                                        bodyStyle={{
                                            height: '100%',
                                            display: 'flex',
                                            flexDirection: 'column',
                                        }}
                                    >
                                        <div className='text-center mb-4'>
                                            <div className='flex flex-col gap-1'>
                                                <div className='flex items-baseline justify-center'>
                                                    {plan.name ===
                                                    SUBSCRIPTION_PLANS.FREE ? (
                                                        <span className='text-3xl font-bold'>
                                                            Free
                                                        </span>
                                                    ) : (
                                                        <>
                                                            <span className='text-3xl font-bold'>
                                                                {
                                                                    Currency.USD
                                                                        .symbol
                                                                }
                                                                {(
                                                                    plan.price ||
                                                                    0
                                                                ).toFixed(2)}
                                                            </span>
                                                            <span className='text-lg text-gray-500 mx-1'>
                                                                /
                                                            </span>
                                                            <span className='text-lg text-gray-500'>
                                                                {
                                                                    Currency.INR
                                                                        .symbol
                                                                }
                                                                {(
                                                                    (plan.price ||
                                                                        0) * 100
                                                                ).toFixed(2)}
                                                            </span>
                                                        </>
                                                    )}
                                                </div>

                                                <div className='text-sm text-gray-500 mt-1'>
                                                    {plan.name ===
                                                    SUBSCRIPTION_PLANS.FREE
                                                        ? 'forever'
                                                        : isYearly
                                                        ? 'per year'
                                                        : 'per month'}
                                                </div>
                                                {isYearly &&
                                                    plan.name !==
                                                        SUBSCRIPTION_PLANS.FREE && (
                                                        <div className='text-xs mt-1 text-gray-500'>
                                                            (
                                                            {
                                                                Currency.USD
                                                                    .symbol
                                                            }
                                                            {(
                                                                (plan.price ||
                                                                    0) / 12
                                                            ).toFixed(2)}{' '}
                                                            /
                                                            {
                                                                Currency.INR
                                                                    .symbol
                                                            }
                                                            {(
                                                                ((plan.price ||
                                                                    0) *
                                                                    100) /
                                                                12
                                                            ).toFixed(2)}{' '}
                                                            per month)
                                                        </div>
                                                    )}
                                            </div>
                                        </div>
                                        <div className='text-center text-sm text-gray-500 mb-4'>
                                            {plan.description ||
                                                'No description available'}
                                        </div>
                                        <ul className='mb-6 text-sm flex-grow space-y-2'>
                                            {(plan.features || []).map(
                                                (feature, i) => (
                                                    <li
                                                        key={i}
                                                        className={`flex items-start ${
                                                            feature === ''
                                                                ? 'invisible '
                                                                : 'gap-2'
                                                        }`}
                                                    >
                                                        <span className='text-green-500'>
                                                            <CheckOutlined />
                                                        </span>
                                                        <span className='text-gray-800'>
                                                            {feature}
                                                        </span>
                                                    </li>
                                                )
                                            )}
                                        </ul>
                                    </Card>
                                </Badge.Ribbon>
                            );
                        })}
                </div>
            </div>
        </div>
    );
});

export default PricingPage;
