import React, { useState, useCallback, useEffect } from 'react';
import { Table, Modal as AntdModal, message } from 'antd';
import cloneDeep from 'lodash/cloneDeep';
import { Button, Dropdown, Input } from '../reusableComponents';
import { faAdd, faFolder, faTrash } from '@fortawesome/free-solid-svg-icons';
import SubfieldsInfo from './subFieldsInfo';
import { Modal } from '../reusableComponents';
import Toggle from '../reusableComponents/Toggle';
const user = JSON.parse(localStorage.getItem('current'));

// Regular expressions for validation
const fieldNameRegex = /^[a-zA-Z0-9 _/\-]{1,32}$/;
const templateNameRegex = /^[a-zA-Z0-9_]{0,32}$/; // No special characters except underscore, max length 32
const subfieldNameRegex = /^[a-zA-Z0-9 _/\-]{1,32}$/;

// Editable cell handler for "Field Name" and "Field Description"
const handleFieldChange = (
    index,
    key,
    value,
    selectedTemplate,
    templateFields,
    setTemplateFields
) => {
    const updatedFieldsValue = cloneDeep(templateFields[selectedTemplate]);
    if (key === 'fieldName') {
        if (value === '') {
            // Allow empty field names without checking the regex

            updatedFieldsValue[index][key] = value;
            setTemplateFields((prevFields) => ({
                ...prevFields,
                [selectedTemplate]: updatedFieldsValue,
            }));
            return;
        }
        // Apply regex check for non-empty values
        if (!fieldNameRegex.test(value)) {
            message.error(
                'Field name should not contain special characters and be less than 32 characters.'
            );
            return;
        }
    }
    const updatedFields = cloneDeep(templateFields[selectedTemplate]);
    updatedFields[index][key] = value;
    setTemplateFields((prevFields) => ({
        ...prevFields,
        [selectedTemplate]: updatedFields,
    }));
};

// Handle changes for "Multiple" and "Verbosity" switches
const handleSwitchChange = (
    index,
    key,
    value,
    selectedTemplate,
    templateFields,
    setTemplateFields
) => {
    const updatedFields = cloneDeep(templateFields[selectedTemplate]);
    if (
        key === 'multiple' &&
        value === true &&
        updatedFields[index].subfields.length === 0
    ) {
        window.alert(
            'Adding subfields values is necessary for the multiple function to work.'
        );
        // Reset `multiple` to false
        updatedFields[index][key] = false;
    } else {
        updatedFields[index][key] = value;
    }
    setTemplateFields((prevFields) => ({
        ...prevFields,
        [selectedTemplate]: updatedFields,
    }));
};

// Handler to add a row (initially with default fields) to the selected template
const addRow = (selectedTemplate, setTemplateFields, templateFields) => {
    if (selectedTemplate) {
        setTemplateFields((prevFields) => ({
            ...prevFields,
            [selectedTemplate]: [
                ...prevFields[selectedTemplate],
                {
                    fieldName: '',
                    multiple: false,
                    verbosity: 0,
                    fieldDescription: '',
                    subfields: [],
                },
            ],
        }));
    }
};

// Handler to delete selected rows
const deleteRows = (
    selectedTemplate,
    selectedRows,
    setTemplateFields,
    templateFields,
    setSelectedRows
) => {
    const updatedFields = templateFields[selectedTemplate].filter(
        (_, index) => !selectedRows.includes(index)
    );
    setTemplateFields((prevFields) => ({
        ...prevFields,
        [selectedTemplate]: updatedFields,
    }));
    setSelectedRows([]); // Clear selection after deletion
};

// Handler to update template name
const handleTemplateNameChange = (
    e,
    selectedTemplate,
    setSelectedTemplate,
    setTemplateFields,
    templateFields
) => {
    const newTemplateName = e.target.value.trim(); // Trim whitespace

    // Check if the template name starts with 'default'
    if (newTemplateName.toLowerCase().startsWith('default')) {
        message.error('Template name cannot start with "default".');
        return;
    }

    // Check if the template name matches the regex
    if (!templateNameRegex.test(newTemplateName)) {
        message.error(
            'Template name should not contain special characters (except underscore) and be less than 32 characters.'
        );
        return;
    }

    if (newTemplateName === selectedTemplate) {
        // If the name is unchanged, do nothing
        return;
    }

    if (templateFields[newTemplateName]) {
        message.error('Template name already exists. Choose a unique name.');
        return;
    }

    // Update templateFields to move data from the old template to the new template
    setTemplateFields((prevFields) => {
        const updatedFields = { ...prevFields };

        // Move the data from the old template to the new template
        updatedFields[newTemplateName] = updatedFields[selectedTemplate] || [];
        delete updatedFields[selectedTemplate]; // Remove the old template key

        return updatedFields;
    });

    // Update the selected template to the new name
    setSelectedTemplate(newTemplateName);
};

const handleSheetNameChange = (e, templateSheetName, setTemplateSheetName) => {
    const newSheetName = e.target.value.trim(); // Trim whitespace

    // Check if the sheet name matches the regex
    const sheetNameRegex = /^[a-zA-Z0-9_]{0,32}$/; // Alphanumeric and underscores, max 32 chars
    if (!sheetNameRegex.test(newSheetName)) {
        message.error(
            'Sheet name can only contain letters, numbers, and underscores, and must be less than 32 characters.'
        );
        return;
    }

    if (newSheetName === templateSheetName) {
        // If the name is unchanged, do nothing
        return;
    }

    // Update the sheet name
    setTemplateSheetName(newSheetName);
};

// Function to format and pass template data to parent component
const prepareTemplateData = (
    templateFields,
    templateDescription,
    templateSheetName,
    email,
    onTemplateDataChange
) => {
    const templateData = {};

    // Iterate over each template
    for (const templateName in templateFields) {
        const fields = templateFields[templateName];
        // Calculate maximum subfields count
        const maximumSubfieldsCount = Math.max(
            0,
            ...fields.map((field) => field.subfields.length)
        );
        // Format data
        templateData[templateName] = {
            template_description: templateDescription,
            maximum_subfields_count: maximumSubfieldsCount,
            template_sheet_name: templateSheetName,
            email: email, // Get email from global state
            template_data: fields,
        };
    }
    const formattedData = JSON.stringify(templateData, null, 2);

    // Pass the formatted data to the parent component
    if (onTemplateDataChange) {
        onTemplateDataChange(formattedData);
    }
};

const TemplateManager = ({
    retrievedTemplateData,
    onTemplateDataChange,
    selectedTemplate,
    setSelectedTemplate,
    templateDescription,
    setTemplateDescription,
    templateSheetName,
    setTemplateSheetName,
}) => {
    const [selectedRows, setSelectedRows] = useState([]);
    const [isSubfieldModalVisible, setIsSubfieldModalVisible] = useState(false);
    const email = user.email;

    const [templateFields, setTemplateFields] = useState({
        'New Template': [
            {
                fieldName: '',
                multiple: false,
                verbosity: 0,
                fieldDescription: '',
                subfields: [],
            },
        ],
    });
    const [editingRowIndex, setEditingRowIndex] = useState(null); // To track the row being edited
    const openSubfieldModal = (subfields, index) => {
        setEditingRowIndex(index);
        setIsSubfieldModalVisible(true);
    };
    const closeSubfieldModal = () => {
        setIsSubfieldModalVisible(false);
    };

    // Define the save function with useCallback at the top level of the component
    const handleSaveSubfields = useCallback(
        (updatedSubfields) => {
            const updatedFields = [...templateFields[selectedTemplate]];

            // Ensure subfields are strings and valid based on regex
            const sanitizedSubfields = updatedSubfields
                .map((subfield) => String(subfield))
                .filter((subfield) => {
                    if (!subfieldNameRegex.test(subfield)) {
                        message.error('Subfield name should be alphanumeric.');
                        return false;
                    }
                    return true;
                });

            // Update the subfields without resetting the field's other data
            updatedFields[editingRowIndex] = {
                ...updatedFields[editingRowIndex], // Spread existing field data (fieldName, multiple, etc.)
                subfields: sanitizedSubfields, // Only update the subfields
            };

            setTemplateFields((prevFields) => ({
                ...prevFields,
                [selectedTemplate]: updatedFields,
            }));

            closeSubfieldModal();
        },
        [editingRowIndex, selectedTemplate, templateFields]
    );

    useEffect(() => {
        if (retrievedTemplateData) {
            // Get the key list from the retrieved data
            const keyList = Object.keys(retrievedTemplateData);
            // Use the key before setting it in state
            const firstKey = keyList[0];
            const description =
                retrievedTemplateData[firstKey].template_description;
            const tableData = retrievedTemplateData[firstKey].template_data;
            const sheetName =
                retrievedTemplateData[firstKey].template_sheet_name;
            // Now set the selected template to the first key
            setSelectedTemplate(firstKey);
            setTemplateDescription(description);
            setTemplateSheetName(sheetName);
            // Directly use the firstKey instead of selectedTemplate here
            setTemplateFields({
                [firstKey]: tableData,
            });
        } else {
            // Now set the selected template to the first key
            setSelectedTemplate('');
            setTemplateDescription('');
            setTemplateSheetName('');
            // Directly use the firstKey instead of selectedTemplate here
            setTemplateFields({});
        }
    }, [retrievedTemplateData]);

    // Update useEffect to prepare data whenever template fields or description change
    useEffect(() => {
        prepareTemplateData(
            templateFields,
            templateDescription,
            templateSheetName,
            email,
            onTemplateDataChange
        );
    }, [templateFields, templateDescription, email, templateSheetName]);

    // Array for dynamic columns definition with delete option
    const columns = [
        {
            title: 'Field Name',
            dataIndex: 'fieldName',
            key: 'fieldName',
            align: 'center',
            width: '20%',
            render: (text, record, index) => (
                <Input
                    value={text}
                    placeholder={`Field ${index + 1}`}
                    onFocus={(e) => e.target.select()}
                    onChange={(e) =>
                        handleFieldChange(
                            index,
                            'fieldName',
                            e.target.value,
                            selectedTemplate,
                            templateFields,
                            setTemplateFields
                        )
                    }
                    hoverText='Provide the label that will represent the answer to the query.'
                    disabled={
                        selectedTemplate &&
                        selectedTemplate.startsWith('default')
                    }
                />
            ),
        },
        {
            title: 'Field Description',
            dataIndex: 'fieldDescription',
            key: 'fieldDescription',
            align: 'center',
            width: '50%',
            render: (text, record, index) => {
                const isEditing = editingRowIndex === index;

                // Determine the maximum length based on the number of subfields
                const subfieldsLength = Array.isArray(record.subfields)
                    ? record.subfields.length
                    : 0;
                const calculatedMaxLength = subfieldsLength * 50;
                const maxLength = Math.max(calculatedMaxLength, 50);
                // Helper function to truncate text based on calculated maxLength
                const truncatedText =
                    text && text.length > maxLength
                        ? text.slice(0, maxLength) + '...'
                        : text || 'New Field Description';

                return isEditing ? (
                    // Render a textarea when editing
                    <textarea
                        value={text}
                        placeholder='New Field Description'
                        onChange={(e) =>
                            handleFieldChange(
                                index,
                                'fieldDescription',
                                e.target.value,
                                selectedTemplate,
                                templateFields,
                                setTemplateFields
                            )
                        }
                        className='w-full rounded-xl h-52 p-4 hover:outline-none focus:outline-backgrounds-primary200 border hover:border-backgrounds-primary300'
                        autoFocus
                        disabled={
                            selectedTemplate &&
                            selectedTemplate.startsWith('default')
                        }
                    />
                ) : (
                    // Render the preview text and enable editing on click
                    <div
                        onClick={() => setEditingRowIndex(index)}
                        className='cursor-pointer h-full flex items-center overflow-hidden whitespace-normal px-2 py-1'
                        title='Specify the main query for the field, along with any additional queries related to subfields that should be addressed within this description.'
                    >
                        {truncatedText}
                    </div>
                );
            },
        },
        {
            title: 'Multiple',
            dataIndex: 'multiple',
            key: 'multiple',
            align: 'center',
            width: '10%',
            render: (multiple, record, index) => (
                <Toggle
                    checked={multiple}
                    onChange={(checked) =>
                        handleSwitchChange(
                            index,
                            'multiple',
                            checked,
                            selectedTemplate,
                            templateFields,
                            setTemplateFields
                        )
                    }
                    title='Specify whether this field can accept multiple answers or is limited to a single response.'
                    disabled={
                        selectedTemplate &&
                        selectedTemplate.startsWith('default')
                    }
                />
            ),
        },
        {
            title: 'Verbosity',
            dataIndex: 'verbosity',
            key: 'verbosity',
            align: 'center',
            width: '13%',
            render: (verbosity, record, index) => (
                // Verbosity selection dropdown replacement
                <Dropdown
                    dropDownList={[
                        {
                            label: 'High',
                            action: () => {
                                handleSwitchChange(
                                    index,
                                    'verbosity',
                                    2,
                                    selectedTemplate,
                                    templateFields,
                                    setTemplateFields
                                );
                            },
                            selected: verbosity === 2,
                        },
                        {
                            label: 'Medium',
                            action: () => {
                                handleSwitchChange(
                                    index,
                                    'verbosity',
                                    1,
                                    selectedTemplate,
                                    templateFields,
                                    setTemplateFields
                                );
                            },
                            selected: verbosity === 1,
                        },
                        {
                            label: 'Low',
                            action: () => {
                                handleSwitchChange(
                                    index,
                                    'verbosity',
                                    0,
                                    selectedTemplate,
                                    templateFields,
                                    setTemplateFields
                                );
                            },
                            selected: verbosity === 0,
                        },
                    ]}
                    showButtonText={true}
                    buttonText={
                        verbosity === 2
                            ? 'High'
                            : verbosity === 1
                            ? 'Medium'
                            : 'Low'
                    }
                    buttonHoverText='Indicate the level of detail required in the response. Choose a higher verbosity for more detailed answers, or a lower setting for concise responses.'
                    disabled={
                        selectedTemplate &&
                        selectedTemplate.startsWith('default')
                    }
                />
            ),
        },
        {
            title: 'Subfields',
            dataIndex: 'subfields',
            key: 'subfields',
            align: 'center',
            width: '20%',
            render: (subfields, record, index) => (
                <div className='flex items-center space-x-2'>
                    {Array.isArray(subfields) && subfields.length > 0 ? (
                        <>
                            <div className='text-texts-secondary'>
                                {subfields.join(', ')}
                                {subfields.length > 0 && ','}{' '}
                                <button
                                    className='cursor-pointer text-texts-tertiary'
                                    onClick={() =>
                                        openSubfieldModal(subfields, index)
                                    }
                                    title='Add more subfields'
                                    style={{
                                        display: 'inline',
                                        padding: 0,
                                        margin: 0,
                                        border: 'none',
                                        background: 'none',
                                    }}
                                    disabled={
                                        selectedTemplate &&
                                        selectedTemplate.startsWith('default')
                                    }
                                >
                                    Add more
                                </button>
                            </div>
                        </>
                    ) : (
                        <div
                            className='cursor-pointer text-texts-tertiary'
                            onClick={() => {
                                if (
                                    !(
                                        selectedTemplate &&
                                        selectedTemplate.startsWith('default')
                                    )
                                ) {
                                    openSubfieldModal(subfields, index);
                                }
                            }}
                            title='Add any further subfields related to the field name whose answer you want to include.'
                        >
                            Add Subfield
                        </div>
                    )}
                </div>
            ),
        },
    ];

    return (
        <div className='px-6 py-4'>
            <div className='flex justify-between items-center mb-4'>
                {/* Template name input */}
                <div className='flex w-1/3'>
                    <Input
                        value={selectedTemplate}
                        onChange={(e) =>
                            handleTemplateNameChange(
                                e,
                                selectedTemplate,
                                setSelectedTemplate,
                                setTemplateFields,
                                templateFields
                            )
                        }
                        placeholder='Enter Template Name'
                        className='text font-semibold text-primary px-4 py-2 w-1/2' // Adding padding
                        hoverText={
                            retrievedTemplateData
                                ? 'Cannot edit existing template name'
                                : 'Select an appropriate name for the template.'
                        }
                        disabled={retrievedTemplateData}
                    />
                </div>
                <div className='w-2/3 pl-2'>
                    <Input
                        value={templateDescription}
                        onChange={(e) => setTemplateDescription(e.target.value)}
                        placeholder='Enter Template Description'
                        className='text font-semibold text-primary px-4 py-2 w-full '
                        hoverText={
                            'Provide a general description that defines the type of document this template is designed for.'
                        }
                        disabled={
                            selectedTemplate &&
                            selectedTemplate.startsWith('default')
                        }
                    />
                </div>
            </div>

            {/* Template description input */}
            <div className='border-t pt-4 border-gray-300'>
                <div className='flex justify-between mb-4'>
                    {/* First div (aligned to the start) */}
                    <div className='w-1/3'>
                        <Input
                            value={templateSheetName}
                            onChange={(e) =>
                                handleSheetNameChange(
                                    e,
                                    templateSheetName,
                                    setTemplateSheetName
                                )
                            }
                            placeholder='Enter Template Collection Name'
                            className='text font-semibold text-primary px-4 py-2 w-full'
                            hoverText={
                                'Provide a Collection Name which will be used for extracted data'
                            }
                            disabled={
                                selectedTemplate &&
                                selectedTemplate.startsWith('default')
                            }
                        />
                    </div>
                    {/* Second div (aligned to the end) */}
                    <div className='flex items-center'>
                        <Button
                            icon={faAdd}
                            onClick={() =>
                                addRow(
                                    selectedTemplate,
                                    setTemplateFields,
                                    templateFields
                                )
                            }
                            text={'Add Field'}
                            type={'success'}
                            disabled={
                                !selectedTemplate ||
                                !templateSheetName ||
                                !templateDescription ||
                                (selectedTemplate &&
                                    selectedTemplate.startsWith('default'))
                            } // Disable if either field is empty
                            hoverText={
                                !selectedTemplate ||
                                !templateDescription ||
                                !templateSheetName
                                    ? 'Please fill the template name, description, and Sheet Name to continue'
                                    : 'Add Field(s)'
                            }
                        />
                        <Button
                            onClick={() =>
                                deleteRows(
                                    selectedTemplate,
                                    selectedRows,
                                    setTemplateFields,
                                    templateFields,
                                    setSelectedRows
                                )
                            }
                            text={'Delete Field(s)'}
                            type={'danger'}
                            icon={faTrash}
                            disabled={
                                selectedRows.length === 0 ||
                                (selectedTemplate &&
                                    selectedTemplate.startsWith('default'))
                            }
                            className='ml-2'
                            hoverText={'Delete 1 or more fields.'}
                        />
                    </div>
                </div>

                {/* Template Table */}
                <div className='overflow-x-auto'>
                    <Table
                        rowSelection={{
                            type: 'checkbox',
                            selectedRowKeys: selectedRows,
                            onChange: (selectedRowKeys) => {
                                if (
                                    !selectedTemplate ||
                                    !selectedTemplate.startsWith('default')
                                ) {
                                    setSelectedRows(selectedRowKeys);
                                }
                            },
                            getCheckboxProps: (record) => ({
                                disabled:
                                    selectedTemplate &&
                                    selectedTemplate.startsWith('default'), // Disable selection if the template is "default"
                            }),
                        }}
                        columns={columns}
                        dataSource={templateFields[selectedTemplate] || []}
                        rowKey={(record, index) => index}
                        pagination={false}
                        bordered
                        style={{ backgroundColor: 'white' }}
                        scroll={{ y: 350 }}
                    />
                </div>
                {isSubfieldModalVisible && (
                    <Modal
                        title={'Manage SubFields'}
                        titleIcon={faFolder}
                        onClose={closeSubfieldModal}
                        className={
                            'fixed inset-0 flex items-center justify-center rounded-lg shadow-lg w-3/5 h-4/5 min-h-[400px] p-6'
                        }
                    >
                        <SubfieldsInfo
                            fieldName={
                                templateFields[selectedTemplate][
                                    editingRowIndex
                                ]?.fieldName
                            }
                            initialSubfields={
                                Array.isArray(
                                    templateFields[selectedTemplate][
                                        editingRowIndex
                                    ]?.subfields
                                )
                                    ? templateFields[selectedTemplate][
                                          editingRowIndex
                                      ]?.subfields
                                    : []
                            }
                            onSave={handleSaveSubfields} // Using the memoized callback
                        />
                    </Modal>
                )}
            </div>
        </div>
    );
};

export default TemplateManager;
