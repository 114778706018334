export const FILE_EXTENSIONS = Object.freeze({
    PDF: 'pdf',
    DOC: 'doc',
    DOCX: 'docx',
    TXT: 'txt',
    PY: 'py',
    JSON: 'json',
    CSV: 'csv',
    DB: 'db',
    JPG: 'jpg',
    JPEG: 'jpeg',
    PNG: 'png',
    BMP: 'bmp',
    SVG: 'svg',
});
