import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    UserAccessOptions,
    OrganizationAccessOptions,
} from '../../constants/userAccessOptions';
import {
    faBoxOpen,
    faUserAltSlash,
    faUserCog,
} from '@fortawesome/free-solid-svg-icons';
import { Button, Dropdown } from '../reusableComponents';

const updateAccessRights = ({
    userList,
    email,
    accessValue,
    enableSharing,
    setEnableSharing,
    stateAction,
}) => {
    const index = userList.findIndex((data) => data.email === email);
    const newDataList = [...userList];
    newDataList[index].accessRights = accessValue;
    stateAction(newDataList);
    if (!enableSharing) {
        setEnableSharing(true);
    }
};

const removeUser = ({
    userList,
    email,
    enableSharing,
    setEnableSharing,
    stateAction,
}) => {
    const index = userList.findIndex((data) => data.email === email);
    const updatedDataList = [...userList];
    updatedDataList.splice(index, 1);
    stateAction(updatedDataList);
    if (!enableSharing) {
        setEnableSharing(true);
    }
};

const getDropDownList = ({
    accessRights,
    userList,
    email,
    enableSharing,
    setEnableSharing,
    stateAction,
}) => {
    return Object.keys(UserAccessOptions).map((data, index) => {
        const accessValue = UserAccessOptions[data];
        return {
            key: index,
            label: data,
            disabled: false,
            selected: accessRights === accessValue,
            action: () => {
                if (data && accessRights !== accessValue) {
                    updateAccessRights({
                        userList,
                        email,
                        accessValue,
                        enableSharing,
                        setEnableSharing,
                        stateAction,
                    });
                }
            },
        };
    });
};

export const UserListComponent = ({
    userList,
    stateAction,
    enableSharing,
    setEnableSharing,
}) => {
    if (!userList || userList.length === 0) {
        return (
            <div className='flex flex-col py-8 justify-center items-center'>
                <FontAwesomeIcon
                    icon={faBoxOpen}
                    size='3x'
                    className='text-texts-secondary300'
                />
                <div className='font-semibold text-texts-secondary300 mt-3'>
                    Repository not shared with any user
                </div>
            </div>
        );
    }
    return userList.map((userData, index) => {
        const email = userData.email;
        const accessRights = userData.accessRights;
        const color =
            userData.accessRights === UserAccessOptions.ADMIN
                ? 'backgrounds-settings100'
                : 'lightblue';

        const dropDownListParams = {
            userList,
            email,
            accessRights,
            enableSharing,
            setEnableSharing,
            stateAction,
        };

        const removeActionParams = {
            userList,
            email,
            enableSharing,
            setEnableSharing,
            stateAction,
        };

        return (
            <div className='flex justify-between items-center mt-3 mb-4 px-2'>
                <div className='text-texts-secondary300'>{email}</div>
                <div className='flex items-center justify-between'>
                    <Dropdown
                        dropDownList={getDropDownList(dropDownListParams)}
                        showButtonText={true}
                        buttonText={accessRights}
                        buttonHoverText={'Change access rights of user'}
                        textColor={color}
                        backgroundColor={color}
                        buttonClassName={'w-24 h-8 mr-3'}
                        listClassName={''}
                        disabled={false}
                    />
                    <Button
                        className=''
                        // text={'Remove'}
                        onClick={(event) => {
                            removeUser(removeActionParams);
                            event.stopPropagation();
                        }}
                        hoverText={'Remove user access from this repository'}
                        icon={faUserAltSlash}
                        type={'danger'}
                    />
                </div>
            </div>
        );
    });
};

export const OrganizationMemberListComponent = ({
    memberList,
    stateAction,
    enableSaving,
    setEnableSaving,
    setUsersToRemove,
    currentUserEmail,
}) => {
    if (!memberList || memberList.length === 0) {
        return (
            <div className='flex flex-col py-8 justify-center items-center'>
                <FontAwesomeIcon
                    icon={faUserCog}
                    size='3x'
                    className='text-texts-secondary300'
                />
                <div className='font-semibold text-texts-secondary300 mt-3'>
                    No organization members found
                </div>
            </div>
        );
    }

    return memberList.map((memberData, index) => {
        const email = memberData.email;
        const accessRights = memberData.access_rights;

        const color =
            accessRights === OrganizationAccessOptions.ORGANISATION_ADMIN
                ? 'backgrounds-settings100'
                : 'icons-primary';

        const handleAccessRightsChange = (newAccessRights) => {
            const updatedMemberList = memberList.map((member) =>
                member.email === email
                    ? { ...member, access_rights: newAccessRights }
                    : member
            );
            stateAction(updatedMemberList);
            setEnableSaving(true);
        };

        const handleRemoveUser = () => {
            const updatedMemberList = memberList.filter(
                (member) => member.email !== email
            );
            stateAction(updatedMemberList);
            setEnableSaving(true);
            setUsersToRemove([{ email: email }]);
        };

        const dropDownList = Object.keys(OrganizationAccessOptions).map(
            (key) => {
                const accessOption = OrganizationAccessOptions[key];
                return {
                    key,
                    label: accessOption,
                    selected: accessRights === accessOption,
                    action: () => handleAccessRightsChange(accessOption),
                };
            }
        );

        return (
            <div
                key={index}
                className='flex justify-between items-center mt-3 mb-4 px-2'
            >
                <div className='text-texts-secondary300'>{email}</div>
                <div className='flex items-center justify-between'>
                    <Dropdown
                        dropDownList={dropDownList}
                        showButtonText={true}
                        buttonText={accessRights}
                        buttonHoverText={'Change access rights of user'}
                        textColor={color}
                        backgroundColor={color}
                        buttonClassName={'w-24 h-8 mr-3'}
                        listClassName={'w-24'}
                        disabled={false}
                    />
                    <Button
                        className=''
                        onClick={(event) => {
                            handleRemoveUser();
                            event.stopPropagation();
                        }}
                        hoverText={
                            email === currentUserEmail
                                ? `Admins can't remove themselves`
                                : `Remove user from organization`
                        }
                        icon={faUserAltSlash}
                        type={'danger'}
                        disabled={email === currentUserEmail}
                    />
                </div>
            </div>
        );
    });
};
