import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const Button = React.forwardRef(
    (
        { text, type, onClick, icon, hoverText, className, disabled, active },
        ref
    ) => {
        let activeStyle = '';
        let disabledStyle = disabled
            ? 'bg-backgrounds-white text-texts-secondary300 cursor-not-allowed'
            : '';
        const hoverStyle = disabled ? 'hover:scale-100' : 'hover:scale-110';

        if (
            type &&
            (typeof type === 'string' || type instanceof String) &&
            !disabled
        ) {
            if (type.toLowerCase() === 'success') {
                activeStyle = active
                    ? 'bg-backgrounds-primary100 border-backgrounds-primary200'
                    : 'bg-backgrounds-white';
                disabledStyle =
                    'text-texts-tertiary hover:bg-backgrounds-primary100 hover:border-backgrounds-primary200 focus:outline-backgrounds-primary200';
            } else if (type && type.toLowerCase() === 'info') {
                activeStyle = active
                    ? `bg-backgrounds-primary100 border-backgrounds-primary200`
                    : 'bg-backgrounds-white';
                disabledStyle = `text-texts-tertiary hover:bg-backgrounds-primary100 hover:border-backgrounds-primary200 focus:outline-backgrounds-primary200`;
            } else if (type && type.toLowerCase() === 'warning') {
                activeStyle = active
                    ? `bg-backgrounds-settings100 border-backgrounds-settings500`
                    : 'bg-backgrounds-white';
                disabledStyle = `text-backgrounds-primary200 hover:bg-backgrounds-primary100 hover:border-backgrounds-primary200 focus:outline-backgrounds-primary500`;
            } else if (type && type.toLowerCase() === 'danger') {
                activeStyle = active
                    ? `bg-buttons-alert border-buttons-alert100`
                    : 'bg-backgrounds-white';
                disabledStyle = `text-buttons-alert400 hover:bg-buttons-alert hover:border-buttons-alert100 focus:outline-buttons-alert100`;
            }
        }

        const buttonStyles = `flex py-1.5 px-2.5 items-center justify-center border rounded-lg shadow-inner ${activeStyle} ${disabledStyle} ${className}`;
        const iconOnlyButtonStyles = `flex w-8 h-8 items-center justify-center border rounded-full shadow-inner ${hoverStyle} ${activeStyle} ${disabledStyle} ${className}`;

        const styles = text ? buttonStyles : iconOnlyButtonStyles;

        return (
            <button
                ref={ref}
                className={styles}
                onClick={
                    !disabled
                        ? onClick
                        : (e) => {
                              e.stopPropagation();
                          }
                }
                title={hoverText}
            >
                {icon && (
                    <FontAwesomeIcon icon={icon} className='drop-shadow-sm' />
                )}
                {icon && text && <span className='pr-1.5'></span>}
                {text && (
                    <div className='font-semibold drop-shadow-sm select-none truncate text-base'>
                        {text}
                    </div>
                )}
            </button>
        );
    }
);
