import React from 'react';

export const TextArea = ({
    value,
    placeholder,
    rows,
    cols,
    onChange,
    className,
    resize,
    disabled,
}) => {
    return (
        <textarea
            className={` w-full h-52 p-2.5 rounded-xl hover:outline-none focus:outline-backgrounds-primary200 border hover:border-backgrounds-primary300 ${
                disabled ? 'bg-backgrounds-secondary' : 'bg-backgrounds-white'
            } ${className ? className : ''}`}
            placeholder={placeholder}
            rows={rows} // Increase the number of visible rows
            cols={cols}
            value={value}
            onChange={onChange}
            style={{ resize: resize ? resize : 'both' }}
            disabled={disabled}
        ></textarea>
    );
};
