import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faClipboardCheck,
    faComment,
    faComments,
    faEllipsisV,
    faFile,
    faFolder,
    faLayerGroup,
    faWrench,
} from '@fortawesome/free-solid-svg-icons';
import 'react-reflex/styles.css';
import { ReflexContainer, ReflexElement } from 'react-reflex';
import { ChatActions } from './chatActions';
import { ChatDisplay } from './chatDisplay';
import { Dropdown } from '../reusableComponents';
import { getFileIcon, getFileIconColor } from '../../utils';
import { Button } from '../reusableComponents';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';

export const ChatContainer = ({
    activeQuestion,
    questionsData,
    isGenerating,
    flagMode,
    query,
    currentQnA,
    fineTunedMode,
    repoName,
    user,
    setFlagMode,
    setFlagIndex,
    setQuery,
    handleChartFormat,
    handleTableFormat,
    handleListFormat,
    handleCustomFormat,
    handleFineTune,
    handleSubmit,
    handleSubmitMultiple,
    handleRegenerate,
    setBatchMode,
    setBatchWithEval,
    setFineTuningVerify,
    fileToChat,
    isAdmin,
    isDatabase,
    togglePdfVisibility,
}) => {
    const BREAKPOINT_WIDTH = 1024;
    const dropDownListDataForAdmin = [
        {
            label: 'Batch',
            icon: faLayerGroup,
            action: () => setBatchMode(true),
        },
        {
            label: 'Batch & Eval',
            icon: faClipboardCheck,
            action: () => {
                setBatchMode(true);
                setBatchWithEval(true);
            },
        },
        // {
        //     label: 'Fine Tune',
        //     icon: faWrench,
        //     action: () => setFineTuningVerify(true),
        // },
        // {
        //     label: 'Fine Tuned Model Chat',
        //     icon: faComments,
        //     action: () => {
        //         // setBatchMode(true);
        //         // setBatchWithEval(true);
        //         alert('Feature is under development. Get back soon!!');
        //     },
        // },
    ];

    const dropDownListDataForUser = [
        {
            label: 'Batch',
            icon: faLayerGroup,
            action: () => setBatchMode(true),
        },
        // {
        //     label: 'Batch & Eval',
        //     icon: faClipboardCheck,
        //     action: () => {
        //         setBatchMode(true);
        //         setBatchWithEval(true);
        //     },
        // },
        // {
        //     label: 'Fine Tune',
        //     icon: faWrench,
        //     action: () => setFineTuningVerify(true),
        // },
        // {
        //     label: 'Fine Tuned Model Chat',
        //     icon: faComments,
        //     action: () => {
        //         // setBatchMode(true);
        //         // setBatchWithEval(true);
        //         alert('Feature is under development. Get back soon!!');
        //     },
        // },
    ];

    return (
        <ReflexContainer orientation='horizontal'>
            {window.innerWidth <= BREAKPOINT_WIDTH &&
                !isDatabase &&
                activeQuestion && (
                    <div className='fixed bottom-24 right-8 z-20 scale-110'>
                        <Button
                            className={'mx-2 w-30 h-30'}
                            //text={'View Source'}
                            hoverText={'View Source'}
                            icon={faFile}
                            onClick={(e) => {
                                togglePdfVisibility();
                            }}
                            type={'success'}
                        />
                    </div>
                )}
            <ReflexElement
                className='header border-b shadow-sm'
                style={{
                    minHeight: '3.5rem',
                    height: '3.5rem',
                    maxHeight: '3.5rem',
                }}
            >
                <div className='pane-content w-full h-full p-2 select-none'>
                    <div className='flex h-full items-center my-0 mx-2'>
                        <div className='w-1/2 my-1 text-texts-secondary300 truncate'>
                            <div className='flex items-center'>
                                {fileToChat === undefined ||
                                fileToChat === '' ? (
                                    <FontAwesomeIcon
                                        icon={faFolder}
                                        //color='gold'
                                        className='mr-2 drop-shadow-sm text-backgrounds-primary200'
                                    />
                                ) : (
                                    <FontAwesomeIcon
                                        icon={getFileIcon(fileToChat)}
                                        color={getFileIconColor(fileToChat)}
                                        className='mr-2 drop-shadow-sm'
                                    />
                                )}{' '}
                                {fileToChat === undefined ||
                                fileToChat === '' ? (
                                    <div className='truncate font-semibold text-base'>
                                        {repoName}
                                    </div>
                                ) : (
                                    <div className='flex items-center truncate font-semibold w-full'>
                                        <div className='truncate text-base mr-2'>
                                            {fileToChat}
                                        </div>
                                        <div className='truncate text-sm'>
                                            ({repoName})
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        {
                            //if needed add button here
                        }
                        <div className='w-1/2 flex justify-end'>
                            {fileToChat === undefined || fileToChat === '' ? (
                                isAdmin ? (
                                    <Dropdown
                                        dropDownList={
                                            isAdmin
                                                ? dropDownListDataForAdmin
                                                : dropDownListDataForUser
                                        }
                                        icon={
                                            <FontAwesomeIcon
                                                icon={faEllipsisV}
                                            />
                                        }
                                        showButtonText={false}
                                        buttonText={null}
                                        buttonHoverText={'More options'}
                                        buttonClassName={`px-3.5 py-2`}
                                        listClassName={''}
                                        backgroundColor={'white'}
                                        textColor={'green'}
                                        disabled={false}
                                    />
                                ) : (
                                    <div></div>
                                )
                            ) : (
                                <div></div>
                            )}
                        </div>
                    </div>
                </div>
            </ReflexElement>

            <ReflexElement flex={1}>
                <div className='pane-content'>
                    <ChatDisplay
                        activeQuestion={activeQuestion}
                        questionsData={questionsData}
                        flagMode={flagMode}
                        repoName={repoName}
                        setQuery={setQuery}
                        currentQnA={currentQnA}
                        user={user}
                        setFlagMode={setFlagMode}
                        setFlagIndex={setFlagIndex}
                        handleChartFormat={handleChartFormat}
                        handleTableFormat={handleTableFormat}
                        handleListFormat={handleListFormat}
                        handleCustomFormat={handleCustomFormat}
                        isGenerating={isGenerating}
                        fileToChat={fileToChat}
                        isDatabase={isDatabase}
                    />
                </div>
            </ReflexElement>

            <ReflexElement
                className='footer border-t shadow'
                style={{
                    minHeight: '4rem',
                    height: '4rem',
                    maxHeight: '4rem',
                }}
            >
                <div className='pane-content w-full h-full'>
                    <div className='flex items-center justify-end w-full h-full'>
                        <ChatActions
                            query={query}
                            fineTunedMode={fineTunedMode}
                            repoName={repoName}
                            currentQnA={currentQnA}
                            setQuery={setQuery}
                            handleFineTune={handleFineTune}
                            handleSubmit={handleSubmit}
                            handleSubmitMultiple={handleSubmitMultiple}
                            handleRegenerate={handleRegenerate}
                            isGenerating={isGenerating}
                            fileToChat={fileToChat}
                            isDatabase={isDatabase}
                        />
                    </div>
                </div>
            </ReflexElement>
        </ReflexContainer>
    );
};
