import React, { forwardRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { LandingPageButton } from '../../components/reusableComponents';
import { getUrl } from '../../components/pdfDisplay/utils';

const Functionalities = forwardRef((props, ref) => {
    const useSecuredConnection = process.env.REACT_APP_USE_SECURED_CONNECTION;
    const handleClick = (url) => {
        if (url.charAt(0) === '/') {
            // Ensure environment variables are set correctly
            const host = process.env.REACT_APP_HOST;
            const port = process.env.REACT_APP_PORT;

            console.log(`${host}:${port}`);
            if (useSecuredConnection) {
                window.open(getUrl(`http://${host}${url}`), '_self');
            } else {
                // Construct the full URL with the path
                window.open(getUrl(`http://${host}:${port}${url}`), '_self');
            }
        } else {
            window.location.href = url;
        }
    };

    return (
        //flex flex-col m-0 md:flex-row md:mx-8 lg:mx-18 rounded-xl bg-backgrounds-backgrounds-primary100 bg-gradient-primary
        <div
            ref={ref}
            className='mx-auto px-4 md:px-8 lg:px-10 my-20 flex flex-col m-0 md:flex-row md:mx-8 lg:mx-20 rounded-xl shadow-md bg-backgrounds-secondaryChat'
        >
            <div className='px-20'>
                <h1 className='text-3xl text-texts-secondary font-bold text-center mb-10 pt-20'>
                    Understand the Functionalities
                </h1>
                {/* Functionality 1 */}
                <div className='functionalitiesContent flex flex-col md:flex-row mb-10 items-center justify-center'>
                    <div
                        className='image flex justify-center items-center p-5 order-2 md:order-1'
                        style={{ flex: 1 }}
                    >
                        <img
                            src={process.env.PUBLIC_URL + '/one.png'}
                            alt='LP'
                            className='w-full h-auto max-w-xs md:max-w-md lg:max-w-lg xl:max-w-xl rounded-xl'
                            draggable='false'
                        />
                    </div>
                    <div
                        className='textContainer flex flex-col justify-center order-1 md:order-2 text-center'
                        style={{ flex: 1 }}
                    >
                        <h2 className='text-2xl text-texts-secondary font-bold mb-5'>
                            Easily Reformat Answers
                        </h2>
                        <p className='mb-5 text-texts-secondary'>
                            Transform responses with ease, ensuring they meet
                            the desired format and style requirements. With
                            intuitive tools at their disposal, users can refine
                            answers swiftly, maintaining consistency and clarity
                            throughout their communication.
                        </p>
                        <LandingPageButton
                            className='bg-backgrounds-primary300 border-backgrounds-white border-2 hover:bg-gradient-to-r hover:from-backgrounds-primary300 hover:via-backgrounds-primary300 hover:to-backgrounds-primary200 hover:border-backgrounds-white hover:text-backgrounds-white p-2 rounded-lg mb-8 w-40 h-12 mx-auto text-texts-primary font-bold '
                            onClick={() => handleClick('/signup')}
                            text='Start Today'
                            icon={faArrowRight}
                        />
                    </div>
                </div>
                {/* Repeat the above structure for other functionalities */}
                {/* Functionality 2 */}
                <div className='functionalitiesContentReverse flex flex-col md:flex-row mb-10 items-center justify-center'>
                    <div
                        className='image flex justify-center items-center p-10 order-2 md:order-2'
                        style={{ flex: 1 }}
                    >
                        <img
                            src={process.env.PUBLIC_URL + '/version.png'}
                            alt='LP'
                            className='w-full h-auto max-w-xs md:max-w-md lg:max-w-lg xl:max-w-xl rounded-xl'
                            draggable='false'
                        />
                    </div>
                    <div
                        className='textContainer flex flex-col justify-center order-1 md:order-1 text-center'
                        style={{ flex: 1 }}
                    >
                        <h2 className='text-2xl text-texts-secondary font-bold mb-5'>
                            Check Sources of Answers
                        </h2>
                        <p className='mb-5 text-texts-secondary'>
                            Validate the accuracy and reliability of information
                            by accessing the sources linked to each answer. This
                            feature empowers users to conduct thorough
                            fact-checking, fostering trust and credibility in
                            the exchanged content.
                        </p>
                        <LandingPageButton
                            className='bg-backgrounds-primary300 border-backgrounds-white border-2 hover:bg-gradient-to-r hover:from-backgrounds-primary300 hover:via-backgrounds-primary300 hover:to-backgrounds-primary200 hover:border-backgrounds-white hover:text-backgrounds-white p-2 rounded-lg mb-8 w-40 h-12 mx-auto text-texts-primary font-bold '
                            onClick={() => handleClick('/signup')}
                            text='Start Today'
                            icon={faArrowRight}
                        />
                    </div>
                </div>
                {/* Repeat the above structure for other functionalities */}
                {/* Functionality 3 */}
                <div className='functionalitiesContent flex flex-col md:flex-row mb-10 items-center justify-center'>
                    <div
                        className='image flex justify-center items-center p-10 order-2 md:order-1'
                        style={{ flex: 1 }}
                    >
                        <img
                            src={process.env.PUBLIC_URL + '/document.png'}
                            alt='LP'
                            className='w-full h-auto max-w-xs md:max-w-md lg:max-w-lg xl:max-w-xl rounded-xl'
                            draggable='false'
                        />
                    </div>
                    <div
                        className='textContainer flex flex-col justify-center order-1 md:order-2 text-center'
                        style={{ flex: 1 }}
                    >
                        <h2 className='text-2xl text-texts-secondary font-bold mb-5'>
                            Chat with an Individual File
                        </h2>
                        <p className='mb-5 text-texts-secondary '>
                            Foster focused discussions by engaging directly with
                            specific files. This functionality allows users to
                            delve into the details of a particular document,
                            facilitating targeted collaboration and
                            problem-solving.
                        </p>
                        <LandingPageButton
                            className='bg-backgrounds-primary300 border-backgrounds-white border-2 hover:bg-gradient-to-r hover:from-backgrounds-primary300 hover:via-backgrounds-primary300 hover:to-backgrounds-primary200 hover:border-backgrounds-white hover:text-backgrounds-white p-2 rounded-lg mb-8 w-40 h-12 mx-auto text-texts-primary font-bold '
                            onClick={() => handleClick('/signup')}
                            text='Start Today'
                            icon={faArrowRight}
                        />
                    </div>
                </div>
                {/* Repeat the above structure for other functionalities */}
                {/* Functionality 4 */}
                <div className='functionalitiesContentReverse flex flex-col md:flex-row mb-10 items-center justify-center'>
                    <div
                        className='image flex justify-center items-center p-10 order-2 md:order-2'
                        style={{ flex: 1 }}
                    >
                        <img
                            src={process.env.PUBLIC_URL + '/approve.png'}
                            alt='LP'
                            className='w-full h-auto max-w-xs md:max-w-md lg:max-w-lg xl:max-w-xl rounded-xl'
                            draggable='false'
                        />
                    </div>
                    <div
                        className='textContainer flex flex-col justify-center order-1 md:order-1 text-center'
                        style={{ flex: 1 }}
                    >
                        <h2 className='text-2xl text-texts-secondary font-bold mb-5'>
                            Easily extract structured information from multiple
                            files saving 100s hours of manual effort
                        </h2>
                        <p className='mb-5 text-texts-secondary'>
                            Streamline data extraction from numerous files,
                            significantly reducing the time and effort
                            traditionally spent on manual processes. By
                            automatically organizing and extracting structured
                            information, users can reclaim valuable time and
                            allocate resources more efficiently.
                        </p>
                        <LandingPageButton
                            className='bg-backgrounds-primary300 border-backgrounds-white border-2 hover:bg-gradient-to-r hover:from-backgrounds-primary300 hover:via-backgrounds-primary300 hover:to-backgrounds-primary200 hover:border-backgrounds-white hover:text-backgrounds-white p-2 rounded-lg mb-8 w-40 h-12 mx-auto text-texts-primary font-bold '
                            onClick={() => handleClick('/signup')}
                            text='Start Today'
                            icon={faArrowRight}
                        />
                    </div>
                </div>
                {/* Repeat the above structure for other functionalities */}
                {/* Functionality 5 */}
                <div className='functionalitiesContent flex flex-col md:flex-row mb-10 items-center justify-center'>
                    <div
                        className='image flex justify-center items-center p-10 order-2 md:order-1'
                        style={{ flex: 1 }}
                    >
                        <img
                            src={process.env.PUBLIC_URL + '/chart.png'}
                            alt='LP'
                            className='w-full h-auto max-w-xs md:max-w-md lg:max-w-lg xl:max-w-xl rounded-xl'
                            draggable='false'
                        />
                    </div>
                    <div
                        className='textContainer flex flex-col justify-center order-1 md:order-2 text-center'
                        style={{ flex: 1 }}
                    >
                        <h2 className='text-2xl text-texts-secondary font-bold mb-5'>
                            Generate beautiful reports with our report designer
                        </h2>
                        <p className='mb-5 text-texts-secondary'>
                            Create visually stunning and professional reports
                            effortlessly using our intuitive report designer
                            tool. With customizable templates and advanced
                            formatting options, users can produce polished
                            reports that effectively convey their message and
                            insights.
                        </p>
                        <LandingPageButton
                            className='bg-backgrounds-primary300 border-backgrounds-white border-2 hover:bg-gradient-to-r hover:from-backgrounds-primary300 hover:via-backgrounds-primary300 hover:to-backgrounds-primary200 hover:border-backgrounds-white hover:text-backgrounds-white p-2 rounded-lg mb-8 w-40 h-12 mx-auto text-texts-primary font-bold '
                            onClick={() => handleClick('/signup')}
                            text='Start Today'
                            icon={faArrowRight}
                        />
                    </div>
                </div>
                {/* Repeat the above structure for other functionalities */}
                {/* Functionality 6 */}
                <div className='functionalitiesContentReverse flex flex-col md:flex-row mb-10 items-center justify-center'>
                    <div
                        className='image flex justify-center items-center p-10 order-2 md:order-2'
                        style={{ flex: 1 }}
                    >
                        <img
                            src={process.env.PUBLIC_URL + '/groupChat.png'}
                            alt='LP'
                            className='w-full h-auto max-w-xs md:max-w-md lg:max-w-lg xl:max-w-xl rounded-xl'
                            draggable='false'
                        />
                    </div>
                    <div
                        className='textContainer flex flex-col justify-center order-1 md:order-1 text-center'
                        style={{ flex: 1 }}
                    >
                        <h2 className='text-2xl text-texts-secondary font-bold mb-5'>
                            Chat with Big documents containing 1000s of Pages
                        </h2>
                        <p className='mb-5 text-texts-secondary'>
                            Seamlessly navigate through extensive documents
                            containing thousands of pages without any
                            performance issues or delays. This feature ensures
                            smooth communication and collaboration, regardless
                            of the size or complexity of the documents involved.
                        </p>
                        <LandingPageButton
                            className='bg-backgrounds-primary300 border-backgrounds-white border-2 hover:bg-gradient-to-r hover:from-backgrounds-primary300 hover:via-backgrounds-primary300 hover:to-backgrounds-primary200 hover:border-backgrounds-white hover:text-backgrounds-white p-2 rounded-lg mb-8 w-40 h-12 mx-auto text-texts-primary font-bold '
                            onClick={() => handleClick('/signup')}
                            text='Start Today'
                            icon={faArrowRight}
                        />
                    </div>
                </div>
                {/* Repeat the above structure for other functionalities */}
                {/* Functionality 7 */}
                <div className='functionalitiesContent flex flex-col md:flex-row mb-10 items-center justify-center'>
                    <div
                        className='image flex justify-center items-center p-10 order-2 md:order-1'
                        style={{ flex: 1 }}
                    >
                        <img
                            src={process.env.PUBLIC_URL + '/folder.png'}
                            alt='LP'
                            className='w-full h-auto max-w-xs md:max-w-md lg:max-w-lg xl:max-w-xl rounded-xl'
                            draggable='false'
                        />
                    </div>
                    <div
                        className='textContainer flex flex-col justify-center order-1 md:order-2 text-center'
                        style={{ flex: 1 }}
                    >
                        <h2 className='text-2xl text-texts-secondary font-bold mb-5'>
                            Chat with Document containing text, images, graphics
                        </h2>
                        <p className='mb-5 text-texts-secondary'>
                            Experience fluid communication even with complex
                            documents comprising text, images, and graphics. Our
                            platform seamlessly integrates diverse content
                            formats, allowing users to discuss, analyze, and
                            collaborate on rich and multifaceted documents
                            without any constraints.
                        </p>
                        <LandingPageButton
                            className='bg-backgrounds-primary300 border-backgrounds-white border-2 hover:bg-gradient-to-r hover:from-backgrounds-primary300 hover:via-backgrounds-primary300 hover:to-backgrounds-primary200 hover:border-backgrounds-white hover:text-backgrounds-white p-2 rounded-lg mb-8 w-40 h-12 mx-auto text-texts-primary font-bold '
                            onClick={() => handleClick('/signup')}
                            text='Start Today'
                            icon={faArrowRight}
                        />
                    </div>
                </div>
                {/* Repeat the above structure for other functionalities */}
                {/* Functionality 8 */}
                <div className='functionalitiesContentReverse flex flex-col md:flex-row mb-10 items-center justify-center'>
                    <div
                        className='image flex justify-center items-center p-10 order-2 md:order-2'
                        style={{ flex: 1 }}
                    >
                        <img
                            src={process.env.PUBLIC_URL + '/chatbot.png'}
                            alt='LP'
                            className='w-full h-auto max-w-xs md:max-w-md lg:max-w-lg xl:max-w-xl rounded-xl'
                            draggable='false'
                        />
                    </div>
                    <div
                        className='textContainer flex flex-col justify-center order-1 md:order-1 text-center'
                        style={{ flex: 1 }}
                    >
                        <h2 className='text-2xl text-texts-secondary font-bold mb-5'>
                            Chat with Databases
                        </h2>
                        <p className='mb-5 text-texts-secondary'>
                            Engage in interactive discussions directly within
                            databases, enabling users to query, analyze, and
                            derive insights from structured data. Our platform
                            facilitates real-time communication and
                            collaboration within database environments,
                            empowering users to make informed decisions and
                            drive meaningful outcomes.
                        </p>
                        <LandingPageButton
                            className='bg-backgrounds-primary300 border-backgrounds-white border-2 hover:bg-gradient-to-r hover:from-backgrounds-primary300 hover:via-backgrounds-primary300 hover:to-backgrounds-primary200 hover:border-backgrounds-white hover:text-backgrounds-white p-2 rounded-lg mb-8 w-40 h-12 mx-auto text-texts-primary font-bold '
                            onClick={() => handleClick('/signup')}
                            text='Start Today'
                            icon={faArrowRight}
                        />
                    </div>
                </div>
                {/* Repeat the above structure for other functionalities */}
                {/* Functionality 9 */}
                <div className='functionalitiesContent flex flex-col md:flex-row mb-10 items-center justify-center'>
                    <div
                        className='image flex justify-center items-center p-10 order-2 md:order-1'
                        style={{ flex: 1 }}
                    >
                        <img
                            src={process.env.PUBLIC_URL + '/sheet.png'}
                            alt='LP'
                            className='w-full h-auto max-w-xs md:max-w-md lg:max-w-lg xl:max-w-xl rounded-xl'
                            draggable='false'
                        />
                    </div>
                    <div
                        className='textContainer flex flex-col justify-center order-1 md:order-2 text-center'
                        style={{ flex: 1 }}
                    >
                        <h2 className='text-2xl text-texts-secondary font-bold mb-5'>
                            Publish extracted information as excel file or
                            formatted pdf report
                        </h2>
                        <p className='mb-5 text-texts-secondary '>
                            Effortlessly transform extracted data into
                            actionable insights by publishing it as an Excel
                            file or a professionally formatted PDF report. With
                            just a few clicks, users can convert raw information
                            into easily digestible formats, facilitating
                            decision-making and knowledge dissemination.
                        </p>
                        <LandingPageButton
                            className='bg-backgrounds-primary300 border-backgrounds-white border-2 hover:bg-gradient-to-r hover:from-backgrounds-primary300 hover:via-backgrounds-primary300 hover:to-backgrounds-primary200 hover:border-backgrounds-white hover:text-backgrounds-white p-2 rounded-lg mb-8 w-40 h-12 mx-auto text-texts-primary font-bold '
                            onClick={() => handleClick('/signup')}
                            text='Start Today'
                            icon={faArrowRight}
                        />
                    </div>
                </div>
                {/* Repeat the above structure for other functionalities */}
                {/* Functionality 10 */}
                <div className='functionalitiesContentReverse flex flex-col md:flex-row mb-10 items-center justify-center'>
                    <div
                        className='image flex justify-center items-center p-10 order-2 md:order-2'
                        style={{ flex: 1 }}
                    >
                        <img
                            src={process.env.PUBLIC_URL + '/share.png'}
                            alt='LP'
                            className='w-full h-auto max-w-xs md:max-w-md lg:max-w-lg xl:max-w-xl rounded-xl'
                            draggable='false'
                        />
                    </div>
                    <div
                        className='textContainer flex flex-col justify-center order-1 md:order-1 text-center'
                        style={{ flex: 1 }}
                    >
                        <h2 className='text-2xl text-texts-secondary font-bold mb-5'>
                            Share Knowledge Base with Other Users
                        </h2>
                        <p className='mb-5 text-texts-secondary'>
                            Foster collaboration and knowledge-sharing by
                            seamlessly sharing your knowledge base with other
                            users. Our platform enables users to disseminate
                            insights, best practices, and valuable information
                            across teams and departments, promoting synergy and
                            driving collective intelligence.
                        </p>
                        <LandingPageButton
                            className='bg-backgrounds-primary300 border-backgrounds-white border-2 hover:bg-gradient-to-r hover:from-backgrounds-primary300 hover:via-backgrounds-primary300 hover:to-backgrounds-primary200 hover:border-backgrounds-white hover:text-backgrounds-white p-2 rounded-lg mb-8 w-40 h-12 mx-auto text-texts-primary font-bold '
                            onClick={() => handleClick('/signup')}
                            text='Start Today'
                            icon={faArrowRight}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
});

export default Functionalities;
