import {
    faChartArea,
    faChartBar,
    faChartColumn,
    faChartGantt,
    faChartLine,
    faChartPie,
    faEarth,
    faSquare,
    faTable,
} from '@fortawesome/free-solid-svg-icons';

export const ChartTypes = Object.freeze({
    AREA_CHART: 'AreaChart',
    BAR_CHART: 'BarChart',
    CARD: 'Card',
    COLUMN_CHART: 'ColumnChart',
    GEO_CHART: 'GeoChart',
    LINE_CHART: 'LineChart',
    PIE_CHART: 'PieChart',
    SCATTER_CHART: 'ScatterChart',
    TABLE: 'Table',
});

export const DataTypes = Object.freeze({
    STRING: 'string',
    NUMBER: 'number',
});

export const defaultChartOptions = Object.freeze({
    hAxis: { title: 'X axis' },
    vAxis: { title: 'Y axis' },
    width: '100%',
    height: '100%',
    backgroundColor: 'white',
    color: '#64748B',
    legend: { position: 'top', alignment: 'start' },
});

export const iconColor = 'lightblue';

export const demoChartData = [
    ['Country', 'Popularity'],
    ['Germany', 200],
    ['United States', 300],
    ['Brazil', 400],
    ['Canada', 500],
    ['France', 600],
    ['RU', 700],
];

export const backgroundColors = [
    '#FFEDEB', // Softer Peach
    '#FFFACD', // Softer Light Lemon
    '#ECF8EC', // Softer Mint Green
    '#EAF7FE', // Softer Light Sky Blue
    '#F5F7F8', // Neutral Light Gray
    '#F7EDF9', // Softer Lavender
    '#FFF7E6', // Softer Warm Sand
    '#FFFFFF', // Pure White (unchanged)
];

export const fontColors = [
    '#D84315', // Dark Burnt Orange for Peach
    '#B88900', // Dark Gold for Lemon
    '#388E3C', // Forest Green for Mint
    '#0288D1', // Deep Blue for Sky Blue
    '#607D8B', // Slate Gray for Cool Gray
    '#7B1FA2', // Royal Purple for Lavender
    '#FF6F00', // Sunset Orange for Warm Sand
    '#000000', // Black for White
];

export const chartDropDownData = [
    {
        label: ChartTypes.CARD,
        icon: faSquare,
        iconColor: iconColor,
    },
    {
        label: ChartTypes.AREA_CHART,
        icon: faChartArea,
        iconColor: iconColor,
    },
    {
        label: ChartTypes.BAR_CHART,
        icon: faChartBar,
        iconColor: iconColor,
    },
    {
        label: ChartTypes.COLUMN_CHART,
        icon: faChartColumn,
        iconColor: iconColor,
    },
    {
        label: ChartTypes.GEO_CHART,
        icon: faEarth,
        iconColor: iconColor,
    },
    {
        label: ChartTypes.LINE_CHART,
        icon: faChartLine,
        iconColor: iconColor,
    },
    {
        label: ChartTypes.PIE_CHART,
        icon: faChartPie,
        iconColor: iconColor,
    },
    {
        label: ChartTypes.SCATTER_CHART,
        icon: faChartGantt,
        iconColor: iconColor,
    },
    {
        label: ChartTypes.TABLE,
        icon: faTable,
        iconColor: iconColor,
    },
];
