import React, { useState, useEffect, useRef } from 'react';
import Maindbrepo from './maindbrepo';
import AddCloudRepo from './addCloudRepo';
import AddEmailRepo from './addEmailRepo';
import axios from 'axios';
import { Switch } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCircleXmark,
    faExclamationTriangle,
    faFolderPlus,
} from '@fortawesome/free-solid-svg-icons';
import {
    Button,
    DragDropFile,
    FileListViewer,
    Input,
} from './reusableComponents';
import { vectorStores } from '../constants/vectorStore';
import { hasDuplicateNames, isValidName } from '../utils';
import { ProcessType } from '../constants/processTypes';
import { TaskProgressType } from '../constants/taskProgressTypes';
import { isMobile } from 'react-device-detect';
import { getUrl } from './pdfDisplay/utils';
import { visionModels } from '../Constants';

const AddNewRepo = (props) => {
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [error, setError] = useState('');
    const [repoDetails, setRepoDetails] = useState([]);
    const [activeVectorStore, setActiveVectorStore] = useState(
        vectorStores.CHROMA
    );
    const [isDatabaseRepo, setIsDatabaseRepo] = useState(false);
    const [isCloudRepo, setIsCloudRepo] = useState(false);
    const [isEmailRepo, setIsEmailRepo] = useState(false);
    const [visionModel, setVisionModel] = useState('Standard Model');
    const [visionModelDropdown, setVisionMoldelDropdown] =
        useState(visionModels);

    // 'gpt-4o-mini',
    //     'gpt-4o',
    //     'gemini-1.5-pro',
    //     'gemini-1.5-flash',
    const fileInputRef = useRef(null);

    const vectorStoresList = [
        {
            key: 0,
            label: vectorStores.CHROMA,
            selected: activeVectorStore === vectorStores.CHROMA,
            action: () => {
                setActiveVectorStore(vectorStores.CHROMA);
            },
        },
        {
            key: 1,
            label: vectorStores.FAISS,
            selected: activeVectorStore === vectorStores.FAISS,
            action: () => {
                setActiveVectorStore(vectorStores.FAISS);
            },
        },
    ];

    const user = JSON.parse(localStorage.getItem('current'));

    const [reponame, setreponame] = useState('');
    const [summary, setSummary] = useState(false);
    const [visionRepo, setVisionRepo] = useState(true);

    const call = async () => {
        try {
            const dynamicUrl = getUrl(
                `http://${process.env.REACT_APP_NODE_SERVER_HOST}:${process.env.REACT_APP_NODE_SERVER_PORT}/repocount/${user.email}`
            );

            const response = await fetch(dynamicUrl);
            const data = await response.json();

            return data.count;
        } catch (error) {
            console.log(error);
        }
    };

    function isNameFound(searchString) {
        for (let i = 0; i < repoDetails.length; i++) {
            if (repoDetails[i].name === searchString) {
                return true;
            }
        }
        return false;
    }

    const repos = async () => {
        try {
            const dynamicUrl = getUrl(
                `http://${process.env.REACT_APP_NODE_SERVER_HOST}:${process.env.REACT_APP_NODE_SERVER_PORT}/getAllChatsRepos/${user.email}`
            );
            const authToken = encodeURIComponent(user.token);

            const headers = {
                Authorization: authToken,
                'Content-Type': 'application/json',
            };

            const response = await fetch(dynamicUrl, {
                method: 'GET',
                headers: headers,
            });

            const data = await response.json();
            setRepoDetails(data.Normal);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        repos();
    }, []);

    const handleFileSelect = (event) => {
        const files = Array.from(event.target.files);
        if (files.length > 0) {
            setSelectedFiles(files);
            setError('');
            validateFiles(files);
        }
    };

    const generateUniqueNumber = () => {
        const min = 10000;
        const max = 99999;
        const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
        return randomNumber.toString();
    };

    const handleFileDrop = (event) => {
        event.preventDefault();
        const files = Array.from(event.dataTransfer.files);
        setSelectedFiles(files);
        setError('');
        validateFiles(files);

        // Programmatically set the files of the file input
        if (fileInputRef.current) {
            const dataTransfer = new DataTransfer();
            files.forEach((file) => dataTransfer.items.add(file));
            fileInputRef.current.files = dataTransfer.files;
        }
    };

    const handleDragOver = (event) => {
        event.preventDefault();
    };

    const validateFiles = (files) => {
        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            const fileType = file.type;
            if (
                !(
                    fileType === 'application/pdf' ||
                    fileType === 'text/plain' ||
                    fileType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
                    fileType === 'image/jpeg' ||
                    fileType === 'image/png'
                )
            ) {
                setError(
                    'Only PDF, DOCX, text, JPEG, and PNG files are allowed.'
                );
                return;
            }

            if (hasDuplicateNames(files)) {
                setError('Duplicate file name found.');
                return;
            }
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setError('');

        if (selectedFiles.length === 0) {
            setError('Please select a file to upload!');
            return;
        }

        const isValidNameData = isValidName(reponame, false);
        if (!isValidNameData.isValid) {
            setError(isValidNameData.error);
            return;
        }

        const isFound = isNameFound(reponame);
        if (isFound) {
            setError('Repository with this name already exists!');
            return;
        }

        props.setViewNotifications(true);
        props.setAddNewMode(false);
        if (window.innerWidth < 1024) {
            props.closeSidebar();
        }

        const formData = new FormData();
        selectedFiles.forEach((file, index) => {
            formData.append(`file`, file);
        });

        try {
            const status = {
                message: `Started repository creation with name ${reponame}`,
                percentageCompletion: 0,
                process: ProcessType.REPO_CREATION,
                progress: TaskProgressType.STARTED,
                repoId: null,
                repoName: reponame,
                statusCode: 200,
                timeStamp: new Date(),
                userMail: user.email,
            };

            props.socket.emit('statusUpdate', status);

            const dynamicUrl = getUrl(
                `http://${process.env.REACT_APP_NODE_SERVER_HOST}:${process.env.REACT_APP_NODE_SERVER_PORT}`
            );
            const val = (await call()) + 1;
            const un = generateUniqueNumber();

            const data = {
                id: user.email,
                val: val,
                name: reponame,
                un: un,
                summary_flag: summary,
                vector_store_type: activeVectorStore,
                visionRepo: visionRepo,
                visionModel:
                    visionModel === 'Percision Model'
                        ? 'gemini-1.5-pro'
                        : 'gemini-1.5-flash',
            };
            const data2 = JSON.stringify(data);
            const encodedToken = encodeURIComponent(user.token);

            await axios
                .post(`${dynamicUrl}/new_upload/${data2}`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        Authorization: encodedToken,
                    },
                    onUploadProgress: (progressEvent) => {
                        const fileUploadPercentage =
                            (progressEvent.loaded * 100) / progressEvent.total;
                        const percentCompleted = Math.round(
                            fileUploadPercentage / 10
                        );

                        const status = {
                            message: 'Uploading files for repository creation',
                            percentageCompletion: percentCompleted,
                            process: ProcessType.REPO_CREATION,
                            progress: TaskProgressType.PROCESSING,
                            repoId: null,
                            repoName: reponame,
                            statusCode: 200,
                            timeStamp: new Date(),
                            userMail: user.email,
                        };

                        props.socket.emit('statusUpdate', status);
                    },
                })
                .then(async (response) => {
                    if (response.data.message_alert) {
                        console.log(response.data.message_alert);
                    } else {
                        console.log(response.data.message);
                    }

                    await props.getAllReposData();
                });
        } catch (error) {
            if (error.response && error.response.status === 400) {
                const errorMessage = error.response.data.error;
                console.log(`${errorMessage}`);
            } else {
                console.log(error.message);
            }
        }
    };

    const onChange = (checked) => {
        setSummary(checked);
    };

    const onChangeVision = (checked) => {
        setVisionRepo(checked);
    };

    const handleChange = (value) => {
        if (
            (!isDatabaseRepo && value === 'database') ||
            (!isCloudRepo && value === 'cloud') ||
            (!isEmailRepo && value === 'email')
        ) {
            setSelectedFiles([]); // Clear selected files when switching to database repo type
        }
        setIsDatabaseRepo(value === 'database');
        setIsCloudRepo(value === 'cloud');
        setIsEmailRepo(value === 'email');
    };

    const [isSmallScreen, setIsSmallScreen] = useState(
        window.innerWidth <= 1024
    );

    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth <= 568);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div className='w-[90vw] min-w-[90px] md:w-[60vw] md:min-w-[500px] flex-col px-6 py-6 rounded-xl bg-backgrounds-slate'>
            <div className='relative flex flex-col w-full text-texts-secondary300'>
                <div className='mb-3'>
                    <Input
                        className=''
                        type='text'
                        placeholder='New repository name'
                        value={reponame}
                        maxLength={60}
                        onChange={(e) => {
                            const value = e.target.value;
                            // Regular expression to allow only alphanumeric characters and underscores
                            const validNameRegex = /^[a-zA-Z0-9_]*$/;
                            const isValid = validNameRegex.test(value);

                            if (isValid) {
                                setError('');
                                setreponame(value);
                            } else {
                                setError(
                                    'Repository name can only contain letters, numbers, and underscores.'
                                );
                            }
                        }}
                        autoFocus
                    />
                </div>

                <div className='flex flex-row mb-3 mt-1 items-center'>
                    <div className='text-texts-secondary300 mr-4 font-semibold'>
                        Repository Type
                    </div>
                    <div className='flex flex-row items-center'>
                        <label className='flex items-center mr-3'>
                            <input
                                type='radio'
                                name='repoType'
                                value='file'
                                defaultChecked
                                className='mr-2'
                                onChange={(e) => handleChange(e.target.value)}
                            />
                            File
                        </label>

                        <label className='flex items-center mr-3'>
                            <input
                                type='radio'
                                name='repoType'
                                value='database'
                                className='mr-2'
                                onChange={(e) => handleChange(e.target.value)}
                            />
                            Database
                        </label>

                        <label className='flex items-center mr-3'>
                            <input
                                type='radio'
                                name='repoType'
                                value='cloud'
                                className='mr-2'
                                onChange={(e) => handleChange(e.target.value)}
                            />
                            Cloud
                        </label>

                        <label className='flex items-center'>
                            <input
                                type='radio'
                                name='repoType'
                                value='email'
                                className='mr-2'
                                onChange={(e) => handleChange(e.target.value)}
                            />
                            Email Interface
                        </label>
                    </div>
                </div>
                {isEmailRepo ? (
                    <AddEmailRepo
                        reponame={reponame}
                        setAddNewMode={props.setAddNewMode}
                        getAllReposData={props.getAllReposData}
                        socket={props.socket}
                        isAdmin={props.isAdmin}
                        visionRepo={visionRepo}
                        onChangeVision={onChangeVision}
                        visionModel={visionModel}
                        setVisionModel={setVisionModel}
                        visionModelDropdown={visionModelDropdown}
                        error={error}
                        handleSubmit={handleSubmit}
                        user={user}
                    />
                ) : isCloudRepo ? (
                    <AddCloudRepo
                        reponame={reponame}
                        setAddNewMode={props.setAddNewMode}
                        getAllReposData={props.getAllReposData}
                        socket={props.socket}
                        isAdmin={props.isAdmin}
                        visionRepo={visionRepo}
                        onChangeVision={onChangeVision}
                        visionModel={visionModel}
                        setVisionModel={setVisionModel}
                        visionModelDropdown={visionModelDropdown}
                        error={error}
                        handleSubmit={handleSubmit}
                        user={user}
                    />
                ) : isDatabaseRepo ? (
                    <div>
                        <Maindbrepo
                            reponame={reponame}
                            setAddNewMode={props.setAddNewMode}
                            getAllReposData={props.getAllReposData}
                            socket={props.socket}
                        ></Maindbrepo>
                    </div>
                ) : (
                    <>
                        <form onSubmit={handleSubmit}>
                            <input
                                type='file'
                                className='bg-backgrounds-white w-full p-2 rounded-xl border cursor-pointer'
                                multiple
                                onClick={(e) => (e.target.value = null)}
                                onChange={handleFileSelect}
                                ref={fileInputRef}
                            />
                            {!isMobile && (
                                <DragDropFile
                                    text={
                                        "Drag and drop a file here or click on 'Choose Files' button to upload files"
                                    }
                                    onDrop={handleFileDrop}
                                    onDragOver={handleDragOver}
                                />
                            )}

                            <div className='pt-4'>
                                <FileListViewer
                                    className='mt-5'
                                    selectedFiles={selectedFiles}
                                />

                                {/* {false&& (
                                    <div className='flex justify-between items-center mt-3'>
                                        <div className='flex items-center justify-center mt-4'>
                                            <div className='mr-2 font-semibold text-texts-secondary300'>
                                                Vision Processing
                                            </div>
                                            <Switch
                                                checked={visionRepo}
                                                onChange={onChangeVision}
                                            />
                                        </div>
                                    </div>
                                )} */}

                                <div className='flex items-center my-4 '>
                                    <div className='w-1/8 font-semibold text-texts-secondary300'>
                                        <label htmlFor='Vision Model'>
                                            Vision Model
                                        </label>
                                    </div>
                                    <div className=' ml-2 w-full md:w-1/4'>
                                        <div className='relative'>
                                            <select
                                                id='Vision Model'
                                                className='block w-auto md:w-30 p-2 mt-1 rounded-xl shadow-sm focus:ring-backgrounds-primary500 focus:border-backgrounds-primary400 hover:outline-none focus:outline-backgrounds-primary200 border hover:border-backgrounds-primary300'
                                                value={visionModel}
                                                onChange={(e) =>
                                                    setVisionModel(
                                                        e.target.value
                                                    )
                                                }
                                            >
                                                <option
                                                    disabled={true}
                                                    value={null}
                                                >
                                                    Select a Vision Model
                                                </option>
                                                {visionModelDropdown.map(
                                                    (method) => (
                                                        <option
                                                            key={method}
                                                            value={method}
                                                        >
                                                            {method}
                                                        </option>
                                                    )
                                                )}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className='flex justify-between items-center '>
                                    <div className='flex items-center justify-center mt-4'>
                                        <div className='mr-2 font-semibold text-texts-secondary300'>
                                            Summary
                                        </div>
                                        <Switch
                                            checked={summary}
                                            onChange={onChange}
                                        />
                                    </div>
                                </div> */}
                            </div>
                            {error && (
                                <div className='flex justify-center items-center text-buttons-alert400 mt-3'>
                                    <FontAwesomeIcon
                                        icon={faExclamationTriangle}
                                        className='mr-2'
                                    />
                                    {error}
                                </div>
                            )}
                            <div className='flex items-center justify-center mt-4'>
                                <div className='flex items-center justify-between w-full'>
                                    <Button
                                        type='danger'
                                        icon={faCircleXmark}
                                        text={'Cancel'}
                                        tile={'Cancel and close the modal'}
                                        className='w-1/3'
                                        onClick={() => {
                                            props.setAddNewMode(false);
                                        }}
                                    />

                                    <button
                                        className={`flex items-center justify-center rounded-full py-1.5 px-2.5 rounded-lg order bg-backgrounds-white shadow-inner border font-semibold w-1/3 ${
                                            !reponame ||
                                            error ||
                                            selectedFiles.length === 0
                                                ? 'text-texts-secondary300 cursor-not-allowed'
                                                : 'hover:bg-backgrounds-primary100 hover:border-backgrounds-primary200 text-backgrounds-primary400'
                                        }`}
                                        type='submit'
                                        title='Upload files and add new repository'
                                        disabled={
                                            !reponame ||
                                            error ||
                                            selectedFiles.length === 0
                                        }
                                    >
                                        <FontAwesomeIcon
                                            icon={faFolderPlus}
                                            className='mr-2'
                                        />
                                        Add
                                    </button>
                                </div>
                            </div>
                        </form>
                    </>
                )}
            </div>
        </div>
    );
};

export default AddNewRepo;
