import React from 'react';
import { Modal } from '../../reusableComponents';
import { ChartView } from './chartView';
import { getChartIcon } from './chartWithControls';

export const FullScreenResultView = ({
    chartData,
    chartName,
    setViewFullScreenResult,
}) => {
    return (
        <Modal
            title={chartName}
            titleIcon={getChartIcon(chartData.chart)}
            iconColor={'backgrounds-settings100'}
            onClose={() => {
                setViewFullScreenResult(false);
            }}
        >
            <div className='w-[70vw] h-[79vh]'>
                <ChartView
                    chartData={chartData}
                    chartName={chartName}
                    showNoDataWarning={true}
                    isFullScreen={true}
                    usingDemoData={false}
                    isDemoPreview={false}
                    enableEditing={false}
                />
            </div>
        </Modal>
    );
};
