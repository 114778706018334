import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCircleXmark,
    faExclamationTriangle,
    faFileCirclePlus,
} from '@fortawesome/free-solid-svg-icons';
import { Button, DragDropFile, FileListViewer } from './reusableComponents';
import { hasDuplicateNames } from '../utils';
import { TaskProgressType } from '../constants/taskProgressTypes';
import { ProcessType } from '../constants/processTypes';
import { isMobile } from 'react-device-detect';
import { getUrl } from './pdfDisplay/utils';

const AddFile = (props) => {
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [error, setError] = useState('');
    const [repoDetails, setRepoDetails] = useState([]);
    const fileInputRef = useRef(null);
    const user = JSON.parse(localStorage.getItem('current'));

    const getFilesList = async (val) => {
        try {
            const dynamicUrl = getUrl(`http://${process.env.REACT_APP_NODE_SERVER_HOST}:${process.env.REACT_APP_NODE_SERVER_PORT}/new_alldocs/${val}`);
            const authToken = encodeURIComponent(user.token); // Replace with your actual authentication token

            const headers = {
                Authorization: authToken,
                'Content-Type': 'application/json',
            };

            const response = await fetch(dynamicUrl, {
                method: 'GET',
                headers: headers,
            });

            const data = await response.json();
            setRepoDetails(data);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getFilesList(props.repoid);
    }, []);

    const call = async () => {
        try {
            const dynamicUrl = getUrl(`http://${process.env.REACT_APP_NODE_SERVER_HOST}:${process.env.REACT_APP_NODE_SERVER_PORT}/repocount/${user.email}`);
            const response = await fetch(dynamicUrl);
            const data = await response.json();
            return data.count;
        } catch (error) {
            console.log(error);
        }
    };

    // Handle file selection
    const handleFileSelect = (event) => {
        const files = Array.from(event.target.files);
        if (files.length > 0) {
            setSelectedFiles(files);
            setError('');
            validateFiles(repoDetails, files);
        }
    };

    // Handle file drop
    const handleFileDrop = (event) => {
        event.preventDefault();
        const files = Array.from(event.dataTransfer.files);
        setSelectedFiles(files);
        setError('');
        validateFiles(repoDetails, files);

        // Programmatically set the files of the file input
        if (fileInputRef.current) {
            const dataTransfer = new DataTransfer();
            files.forEach((file) => dataTransfer.items.add(file));
            fileInputRef.current.files = dataTransfer.files;
        }
    };

    // Prevent default behavior for file drop
    const handleDragOver = (event) => {
        event.preventDefault();
    };

    // Validate file types
    const validateFiles = (oldFiles, files) => {
        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            const fileType = file.type;
            if (
                !(
                    fileType === 'application/pdf' ||
                    fileType === 'text/plain' ||
                    fileType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
                    fileType === 'image/jpeg' ||
                    fileType === 'image/png'
                )
            ) {
                setError(
                    'Only PDF, DOCX, text, JPEG, and PNG files are allowed.'
                );
                return;
            }

            if (hasDuplicateNames(files)) {
                setError('Duplicate file name found in selected file list.');
                return;
            }

            const allFilesData = [...oldFiles, ...files];
            if (hasDuplicateNames(allFilesData)) {
                setError('File with same name already exist in repository.');
                return;
            }
        }
    };

    // Handle form submission
    const handleSubmit = async (event) => {
        event.preventDefault();
        setError('');

        if (selectedFiles.length === 0) {
            setError('Please select a file.');
            return;
        }

        // Show notification as soon as new notification arrives
        props.setViewNotifications(true);

        // Hide modal
        props.setAddFileMode(false);
        if (window.innerWidth < 1024) {
            props.closeSidebar();
        }

        const formData = new FormData();

        selectedFiles.forEach((file, index) => {
            formData.append(`file`, file);
        });

        try {
            const status = {
                message: `Files upload starting for ${props.foldername}`,
                percentageCompletion: 0,
                process: ProcessType.FILE_UPLOAD,
                progress: TaskProgressType.STARTED,
                repoId: props.repoid,
                repoName: props.foldername,
                statusCode: 200,
                timeStamp: new Date(),
                userMail: user.email,
            };
            props.socket.emit('statusUpdate', status);

            const dynamicUrl = getUrl(`http://${process.env.REACT_APP_NODE_SERVER_HOST}:${process.env.REACT_APP_NODE_SERVER_PORT}`);
            const val = (await call()) + 1;
            console.log(val);
            const data = {
                id: props.mail,
                foldername: props.foldername,
                repoid: props.repoid,
                userMail: user.email,
            };
            const data2 = JSON.stringify(data);

            const encodedToken = encodeURIComponent(user.token);

            await axios.post(`${dynamicUrl}/new_upload2/${data2}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: encodedToken,
                },
                onUploadProgress: (progressEvent) => {
                    const fileUploadPercentage =
                        (progressEvent.loaded * 100) / progressEvent.total;
                    const percentCompleted = Math.round(
                        fileUploadPercentage / 10
                    );

                    const status = {
                        message: `Uploading files to ${props.foldername}`,
                        percentageCompletion: percentCompleted,
                        process: ProcessType.FILE_UPLOAD,
                        progress: TaskProgressType.PROCESSING,
                        repoId: props.repoid,
                        repoName: props.foldername,
                        statusCode: 200,
                        timeStamp: new Date(),
                        userMail: user.email,
                    };

                    props.socket.emit('statusUpdate', status);
                },
            });
            // update file list in side bar
            await props.getRepoFilesData(props.repoid);
        } catch (error) {
            // alert(error);
            console.error('Error uploading files:', error);
        }
    };

    return (
        <div className='w-[90vw] min-w-[90px] md:w-[60vw] md:min-w-[500px] flex-col px-6 py-6 rounded-xl bg-backgrounds-slate '>
            <form onSubmit={handleSubmit}>
                <input
                    type='file'
                    className='bg-backgrounds-white w-full p-2 rounded-xl border cursor-pointer'
                    multiple
                    onChange={handleFileSelect}
                    ref={fileInputRef}
                />
                {!isMobile && (
                    <DragDropFile
                        text={
                            " Drag and drop a file here or click on 'Choose File' button to upload files"
                        }
                        onDrop={handleFileDrop}
                        onDragOver={handleDragOver}
                    />
                )}

                <div>
                    <FileListViewer selectedFiles={selectedFiles} />
                    {error && (
                        <div className='flex justify-center items-center text-texts-red400 mt-3'>
                            <FontAwesomeIcon
                                icon={faExclamationTriangle}
                                className='mr-2'
                            />
                            {error}
                        </div>
                    )}
                </div>

                <div className='flex items-center justify-center mt-4'>
                    <div className='flex items-center justify-between w-full'>
                        <Button
                            type='danger'
                            icon={faCircleXmark}
                            className='w-1/3'
                            text={'Cancel'}
                            tile={'Cancel and close the modal'}
                            onClick={() => {
                                props.setAddFileMode(false);
                            }}
                        />
                        <button
                            className={`flex items-center justify-center rounded-full py-1.5 px-2.5 rounded-lg order bg-backgrounds-white shadow-inner border font-semibold w-1/3 ${
                                error || selectedFiles.length === 0
                                    ? 'text-texts-secondary300 cursor-not-allowed'
                                    : `hover:bg-backgrounds-primary100 hover:border-backgrounds-primary200 text-texts-tertiary`
                            }`}
                            type='submit'
                            title='Upload files and add them to repository'
                            disabled={error || selectedFiles.length === 0}
                        >
                            <FontAwesomeIcon
                                icon={faFileCirclePlus}
                                className='mr-2'
                            />
                            Add
                        </button>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default AddFile;
