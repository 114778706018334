import { useState, useEffect } from 'react';
import axios from 'axios';
import { getUrl } from '../components/pdfDisplay/utils';

export const usePlanData = () => {
    const [plans, setPlans] = useState([]);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchAllPlanDetails = async () => {
            try {
                const response = await axios.get(
                    getUrl(
                        `http://${process.env.REACT_APP_FAST_API_HOST}:${process.env.REACT_APP_FAST_API_PORT}/subscription_routes/subscription-plans`
                    )
                );
                setPlans(response.data.plans);
                setError(null);
            } catch (error) {
                console.error('Error fetching plan details:', error);
                setError(
                    'Failed to load subscription plans. Please try again later.'
                );
            }
        };

        fetchAllPlanDetails();
    }, []);

    return { plans, error };
};
