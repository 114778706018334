import React, { useEffect, useState } from 'react';
import { ImageViewer } from './imageViewer';
import { ReflexContainer, ReflexElement } from 'react-reflex';
import 'react-reflex/styles.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getFileIcon, getFileIconColor } from '../../utils';
import { getUrl } from '../pdfDisplay/utils';

export const SingleImageContainer = ({
    repoId,
    email,
    folderName,
    fileName,
}) => {
    const [fileUrl, setFileUrl] = useState([]);

    useEffect(() => {
        const path = `${email}/${folderName}/${fileName}`;
        const encodedPath = encodeURIComponent(path);

        const fileUrl = getUrl(
            `http://${process.env.REACT_APP_FAST_API_HOST}:${process.env.REACT_APP_FAST_API_PORT}/get_image/?file_path=${encodedPath}`
        );
        setFileUrl(fileUrl);
    }, [repoId, email, folderName, fileName]);

    return (
        <ReflexContainer
            orientation='horizontal'
            style={{ minWidth: '20rem', width: '50vw', height: '79vh' }}
        >
            <ReflexElement
                className='header border-b'
                style={{
                    minHeight: '3.5rem',
                    height: '3.5rem',
                    maxHeight: '3.5rem',
                }}
            >
                <div className='pane-content w-full h-full p-2 select-none'>
                    <div className='flex h-full items-center my-0 mx-2'>
                        <div className='flex h-full items-center w-full my-1 text-texts-secondary300 truncate'>
                            {fileName ? (
                                <div className='flex items-center truncate h-full'>
                                    <FontAwesomeIcon
                                        icon={getFileIcon(fileName)}
                                        color={getFileIconColor(fileName)}
                                        className='mr-2 drop-shadow'
                                    />
                                    <div className='truncate font-semibold text-base'>
                                        {fileName}
                                    </div>
                                </div>
                            ) : (
                                <></>
                            )}
                        </div>
                    </div>
                </div>
            </ReflexElement>

            <ReflexElement flex={1}>
                <div className='pane-content w-full h-full'>
                    <ImageViewer fileName={fileName} fileUrl={fileUrl} />
                </div>
            </ReflexElement>
        </ReflexContainer>
    );
};
