import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHighlighter, faSquare } from '@fortawesome/free-solid-svg-icons';
import { DataTypes } from '../constant';

export const DebugLegendsSection = ({ lengthData, isAdmin }) => {
    const displayLegends = Object.keys(DataTypes).map((key) => {
        const count = lengthData ? lengthData[DataTypes[key]] : 0;

        let color = 'white';
        if (DataTypes[key] === DataTypes.CHAPTERS) color = 'text-blue-400';
        else if (DataTypes[key] === DataTypes.TABLES) color = 'text-red-400';
        else if (DataTypes[key] === DataTypes.CHUNKS) color = 'text-yellow-400';

        return (
            <div
                key={key}
                className='bg-backgrounds-white flex justify-center items-center mx-1 py-1 px-2 rounded-xl border shadow-sm'
            >
                <div className='capitalize font-semibold'>
                    <FontAwesomeIcon
                        icon={faSquare}
                        className={`mr-1.5 ${color}`}
                    />
                    <span>{DataTypes[key]}</span>
                    <span
                        title={`${DataTypes[key]} highlight count(s)`}
                        className='ml-1 border-l pl-2 cursor-pointer'
                    >
                        {count ? count : 0}
                    </span>
                </div>
            </div>
        );
    });

    return (
        <div className='w-full h-full flex justify-start items-center rounded-xl'>
            <div className='flex justify-center items-center mr-2 text-slate-500 truncate h-full flex items-center'>
                <FontAwesomeIcon icon={faHighlighter} className='mr-2' />
                <div className='overflow-ellipsis overflow-hidden font-semibold truncate h-full flex items-center'>
                    Highlight Legends
                </div>
            </div>
            <div className='flex items-center rounded-xl'>{displayLegends}</div>
        </div>
    );
};
