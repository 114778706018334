import React, { useState } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle, faCircleXmark, faFolderPlus } from '@fortawesome/free-solid-svg-icons';
import { Button } from './reusableComponents';
import { isValidName } from '../utils';
import { ProcessType } from '../constants/processTypes';
import { TaskProgressType } from '../constants/taskProgressTypes';
import { getUrl } from './pdfDisplay/utils';
import { Form, Input } from 'antd';

const Addnewrepo_mssql = (props) => {
    const [error, setError] = useState('');
    const [serverName, setServerName] = useState('');
    const [databaseName, setDatabaseName] = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [port, setPort] = useState('1433');

    const user = JSON.parse(localStorage.getItem('current'));

    const handleSubmit = async (values) => {
        setError('');

        if (!props.reponame) {
            setError('Please enter a valid repository name!');
            return;
        }

        if (!serverName || !databaseName || !username || !password) {
            setError('Please fill in all required fields!');
            return;
        }

        const isValidNameData = isValidName(props.reponame, false);
        if (!isValidNameData.isValid) {
            setError(isValidNameData.error);
            return;
        }

        try {
            const dynamicUrl = getUrl(`http://${process.env.REACT_APP_FAST_API_HOST}:${process.env.REACT_APP_FAST_API_PORT}`);

            const formData = new FormData();
            formData.append('reponame', props.reponame);
            formData.append('email', user.email);
            formData.append('repotype', 'mssql');
            formData.append('server_name', serverName);
            formData.append('database_name', databaseName);
            formData.append('username', username);
            formData.append('password', password);
            formData.append('port', port);

            props.setAddNewMode(false);

            const status = {
                message: 'Starting MSSQL database repository creation',
                percentageCompletion: 0,
                process: ProcessType.REPO_CREATION,
                progress: TaskProgressType.STARTED,
                repoId: null,
                repoName: props.reponame,
                statusCode: 200,
                timeStamp: new Date(),
                userMail: user.email,
            };

            props.socket.emit('statusUpdate', status);

            await axios.post(`${dynamicUrl}/add_mssql_repo`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: encodeURIComponent(user.token),
                },
            });

            await props.getAllReposData();
        } catch (error) {
            if (error.response?.status === 400) {
                alert(error.response.data.error);
            } else {
                alert(error.message);
            }
            props.setAddNewMode(false);
        }
    };

    const isFormComplete = () => {
        return serverName && databaseName && username && password;
    };

    return (
        <div>
            <Form
                name='basic'
                initialValues={{ remember: true }}
                onFinish={handleSubmit}
                layout='vertical'
            >
                <div className='grid grid-cols-3 gap-4'>
                    <div className='col-span-3 sm:col-span-3 md:col-span-2 lg:col-span-2'>
                        <Form.Item
                            label='Server Name'
                            name='serverName'
                            rules={[{ required: true, message: 'Please input the server name!' }]}
                        >
                            <Input
                                value={serverName}
                                onChange={(e) => setServerName(e.target.value)}
                                placeholder="e.g., localhost or server address"
                            />
                        </Form.Item>
                    </div>
                    <div className='col-span-3 sm:col-span-3 md:col-span-1 lg:col-span-1'>
                        <Form.Item
                            label='Port'
                            name='port'
                        >
                            <Input
                                value={port}
                                onChange={(e) => setPort(e.target.value)}
                                placeholder="Default: 1433"
                            />
                        </Form.Item>
                    </div>
                </div>

                <Form.Item
                    label='Database Name'
                    name='databaseName'
                    rules={[{ required: true, message: 'Please input the database name!' }]}
                >
                    <Input
                        value={databaseName}
                        onChange={(e) => setDatabaseName(e.target.value)}
                    />
                </Form.Item>

                <Form.Item
                    label='Username'
                    name='username'
                    rules={[{ required: true, message: 'Please input the username!' }]}
                >
                    <Input
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                    />
                </Form.Item>

                <Form.Item
                    label='Password'
                    name='password'
                    rules={[{ required: true, message: 'Please input the password!' }]}
                >
                    <Input.Password
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </Form.Item>

                {error && (
                    <div className='flex justify-center items-center text-buttons-alert400 mt-3'>
                        <FontAwesomeIcon icon={faExclamationTriangle} className='mr-2' />
                        {error}
                    </div>
                )}

                <Form.Item>
                    <div className='flex items-center justify-center mt-4'>
                        <div className='flex items-center justify-between w-full'>
                            <Button
                                type='danger'
                                icon={faCircleXmark}
                                text={'Cancel'}
                                title={'Cancel and close the modal'}
                                className='w-1/3'
                                onClick={() => props.setAddNewMode(false)}
                            />

                            <button
                                className={`flex items-center justify-center rounded-full py-1.5 px-2.5 rounded-lg order bg-backgrounds-white shadow-inner border font-semibold w-1/3 ${
                                    !isFormComplete()
                                        ? 'text-texts-secondary300 cursor-not-allowed'
                                        : 'hover:bg-backgrounds-primary100 hover:border-backgrounds-primary200 text-backgrounds-primary400'
                                }`}
                                type='submit'
                                title='Upload files and add new repository'
                                disabled={!isFormComplete()}
                            >
                                <FontAwesomeIcon icon={faFolderPlus} className='mr-2' />
                                Add
                            </button>
                        </div>
                    </div>
                </Form.Item>
            </Form>
        </div>
    );
};

export default Addnewrepo_mssql; 