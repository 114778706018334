import React, { useEffect } from 'react';
import 'react-reflex/styles.css';
import { ReflexContainer, ReflexElement } from 'react-reflex';

import { UserDetailAndAction } from '../userDetailAndAction';
import { Brand } from '../brand';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '../reusableComponents';
import { faCaretLeft, faHeadset } from '@fortawesome/free-solid-svg-icons';
import { useGlobalCredits } from '../GlobalStateContext';

export const SupportTopics = ({
    topics,
    activeSection,
    setActiveSection,
    closeSidebar,
}) => {
    const { transactionMode, setTransactionMode } = useGlobalCredits();
    return topics
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((item, index) => (
            <div className='w-full text-texts-secondary300' key={index}>
                <button
                    className={`w-full flex items-center justify-between ${
                        activeSection === item.name
                            ? 'bg-backgrounds-primary100 shadow-inner text-texts-tertiary'
                            : ''
                    } rounded-xl my-2 px-3 py-1 border border-transparent hover:bg-backgrounds-primary100 hover:border-backgrounds-primary200 text-texts-secondary300`}
                    onClick={(event) => {
                        if (transactionMode) {
                            setTransactionMode(!transactionMode);
                        }
                        setActiveSection(item.name);
                        event.stopPropagation();
                        if (window.innerWidth < 1024) {
                            closeSidebar(); // Call the closeSidebar function passed from navbar.jsx
                        }
                    }}
                >
                    <div className='flex items-center justify-center truncate max-w-prose'>
                        <FontAwesomeIcon
                            icon={item.icon}
                            className='mr-2 drop-shadow-sm text-icons-primary'
                        />
                        <div className='truncate text-base'>{item.name}</div>
                    </div>
                </button>
            </div>
        ));
};

export const SupportSideBar = ({
    topics,
    activeSection,
    setActiveSection,
    setIsLeftViewExpanded,
    isSidebarOpen,
    closeSidebar,
    setIsSidebarOpen,
    displayFloatingButton,
    setDisplayFloatingButton,
}) => {
    useEffect(() => {
        const handleFloatingButtonVisibility = () => {
            if (isSidebarOpen) {
                setDisplayFloatingButton(false);
            } else {
                setDisplayFloatingButton(true);
            }
        };

        handleFloatingButtonVisibility();

        return () => {
            // Optional cleanup if needed when unmounting
            setDisplayFloatingButton(true);
        };
    }, [isSidebarOpen]);
    return (
        <div
            className={`z-0 h-full w-full transition-all duration-700 ease-in-out bg-transparent p-1 m-0 rounded-xl`}
        >
            <ReflexContainer orientation='horizontal'>
                <ReflexElement
                    className='header border-b-2 border-b-backgrounds-white'
                    style={{
                        minHeight: '8rem',
                        height: '8rem',
                        maxHeight: '8rem',
                    }}
                >
                    <div className='pane-content w-full h-full pb-2 px-1'>
                        <div className='w-full h-full flex flex-col justify-between items-center text-texts-secondary300'>
                            <Brand />

                            <div className='w-full flex items-center justify-between'>
                                <div className='w-full flex items-center truncate'>
                                    <FontAwesomeIcon
                                        icon={faHeadset}
                                        className='mr-2 drop-shadow-sm text-icons-primary'
                                    />
                                    <div className='font-semibold truncate pb-0.5'>
                                        Support Topics
                                    </div>
                                </div>
                                <div className='flex items-center justify-center'>
                                    <Button
                                        icon={faCaretLeft}
                                        className={'w-9 h-9 text-icons-primary'}
                                        onClick={() => {
                                            if (window.innerWidth > 1024) {
                                                // Adjust the breakpoint as per your design
                                                setIsLeftViewExpanded(false);
                                            } else {
                                                closeSidebar(); // Call the closeSidebar function passed from navbar.jsx
                                            }
                                        }}
                                        hoverText={
                                            'Collapse and hide repositories view'
                                        }
                                        type={'warning'}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </ReflexElement>

                <ReflexElement flex={1}>
                    <div className='pane-content p-1'>
                        <div className='h-full w-full overflow-auto'>
                            <SupportTopics
                                topics={topics}
                                activeSection={activeSection}
                                setActiveSection={setActiveSection}
                                closeSidebar={closeSidebar}
                            />
                        </div>
                    </div>
                </ReflexElement>

                <ReflexElement
                    className='footer border-t-2 border-t-backgrounds-white'
                    style={{
                        minHeight: '9.5rem',
                        height: '9.5rem',
                        maxHeight: '9.5rem',
                    }}
                >
                    <div className='pane-content w-full h-full'>
                        <UserDetailAndAction />
                    </div>
                </ReflexElement>
            </ReflexContainer>
        </div>
    );
};

export default SupportSideBar;
