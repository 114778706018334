import React, { useEffect, useRef, useState } from 'react';
import { useAdmin } from './../../context/AdminContext';
import axios from 'axios';
import { Chart } from 'react-google-charts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faUserAlt,
    faFlag,
    faRobot,
    faPencil,
    faTable,
    faList,
    faComments,
    faDatabase,
    faClipboardList,
    faEllipsisV,
    faChartPie,
    faChartColumn,
    faChartBar,
    faChartLine,
} from '@fortawesome/free-solid-svg-icons';
import {
    Button,
    Dropdown,
    ExpandableTable,
    LoadingView,
} from '../reusableComponents';
import { Modal } from '../reusableComponents';
import { List } from 'antd';
import { ChatFormatType } from '../../constants/chatFormatType';
import { isValidJsonString } from '../../utils';
import { RepoType } from '../../constants/repoType';
import { getUrl } from '../pdfDisplay/utils';
import { ChartTypes } from '../reportLayouts/constants';
import { DataType } from '../../enums';

const NoChatView = ({ repoName, isGenerating, fileToChat }) => {
    if (isGenerating) {
        return (
            <div className='flex w-full h-full items-end justify-center drop-shadow-sm mt-2'>
                <LoadingView inLine={true} loadingText={'Generating Answer'} />
            </div>
        );
    }

    return (
        <div className='flex flex-col h-[60vh] w-full items-center justify-center my-4 relative'>
            <FontAwesomeIcon
                icon={faComments}
                className={`text-icons-primary100
            w-16 h-16
            md:w-24 md:h-24
            lg:w-32 lg:h-32
            `}
            />

            <div className='flex items-center justify-center text-texts-secondary300 text-xl mt-6'>
                <div className='break-all p-2'>
                    Start chatting with{' '}
                    {fileToChat === '' || fileToChat === undefined
                        ? repoName
                        : fileToChat}
                    's model
                </div>
            </div>
        </div>
    );
};

const getTime = (timestamp) => {
    const timeText = timestamp.split(',')[1];
    const timeData = timeText.split(':');
    const hours = timeData[0];
    const mins = timeData[1];
    const amPm = timeText.split(' ')[2];
    return `${hours}:${mins} ${amPm ? amPm : ''}`;
};

const splitDate = (date) => {
    const parts = date.split('/');
    const day = parseInt(parts[0]);
    const month = parseInt(parts[1]);
    const year = parseInt(parts[2]);
    return {
        day,
        month,
        year,
    };
};

const getDate = (timestamp) => {
    const date = timestamp.split(',')[0];
    const parts = date.split('/');
    const dateFormatted = parts[1] + '/' + parts[0] + '/' + parts[2];
    const dateObj = splitDate(dateFormatted);

    const dateNow = new Date().toLocaleDateString('en-GB').toString();
    const dateNowObj = splitDate(dateNow);

    if (
        dateNowObj.year === dateObj.year &&
        dateNowObj.month === dateObj.month &&
        dateNowObj.day === dateObj.day
    )
        return 'Today';
    if (
        dateNowObj.year === dateObj.year &&
        dateNowObj.month === dateObj.month &&
        dateNowObj.day - dateObj.day === 1
    )
        return 'Yesterday';

    return date;
};

const ChartDisplay = ({
    chatObject,
    activeChart,
    setActiveChart,
    isSmallScreen,
    setEnableScrollToBottom,
}) => {
    if (typeof chatObject.answer.data === DataType.STRING) {
        return chatObject.answer.data.toString();
    }

    const columnData = chatObject.answer.columns;
    const data = chatObject.answer.data;

    if (Array.isArray(data) && Array.isArray(data[0]) && data[0].length > 0) {
        let columnDataIsString = 0;
        data[0].forEach((element) => {
            if (typeof element === DataType.STRING) columnDataIsString++;
        });

        if (columnDataIsString > 1) {
            return <div>No chart possible for this data representation.</div>;
        }

        const chartData = [columnData, ...data];
        const chartType = activeChart
            ? activeChart
            : chatObject.answer.chartType;
        let options = chatObject.answer.options;
        if (chartType === ChartTypes.BAR_CHART) {
            options = {
                ...options,
                hAxis: options.vAxis,
                vAxis: options.hAxis,
            };
        }

        return (
            <div className='w-full flex flex-col'>
                <ChartButtonGroup
                    activeChart={activeChart}
                    setActiveChart={setActiveChart}
                    isSmallScreen={isSmallScreen}
                    setEnableScrollToBottom={setEnableScrollToBottom}
                />
                <Chart
                    chartType={chartType}
                    data={chartData}
                    options={{
                        ...options,
                        legend: {
                            position: 'top',
                            alignment: 'end',
                        },
                    }}
                    width={'100%'}
                    height={'fit-content'}
                    chartPackages={['corechart', 'controls']}
                />
            </div>
        );
    }
};

const QuestionActionView = ({ user, chatObject, setQuery }) => {
    return (
        <div className='w-full ml-auto flex justify-between items-center border-b border-white pb-2 mb-2'>
            <div className='flex items-center justify-between text-texts-secondary300 truncate'>
                <div className='flex items-center justify-center w-6 h-6 rounded-full bg-backgrounds-white mr-2'>
                    <FontAwesomeIcon
                        icon={faUserAlt}
                        className='text-sky-300'
                    />
                </div>
                <div className='font-semibold truncate text-base'>
                    {user.username}
                </div>
            </div>
            {/* Action Section */}
            <div className='text-texts-secondary300'>
                <button
                    onClick={() => {
                        setQuery(chatObject.question);
                    }}
                    title='Edit the question in chat box'
                    className='ml-4 hover:scale-110'
                >
                    <FontAwesomeIcon icon={faPencil} />
                </button>
            </div>
        </div>
    );
};

const QuestionView = ({ chatObject }) => {
    return (
        <>
            <div className='text-slate-700'>{chatObject.question}</div>
            <div className='flex justify-between items-between text-xs mt-2 text-texts-secondary300'>
                <div>{getDate(chatObject.timestamp)}</div>
                <div>{getTime(chatObject.timestamp)}</div>
            </div>
        </>
    );
};

const truncateText = (text, maxLength) => {
    return text.length > maxLength ? text.slice(0, maxLength) + '...' : text;
};

const DisplayText = ({ text, maxLength }) => (
    <div className='font-semibold text-base'>
        {truncateText(text, maxLength)}
    </div>
);

const AnswerActionView = ({
    index,
    repoName,
    chatObject,
    user,
    size,
    handleCustomFormat,
    handleListFormat,
    handleTableFormat,
    handleChartFormat,
    setViewDatabaseQuery,
    setActiveDbQuery,
    flagged,
    setFlagIndex,
    setFlagMode,
    isLastAnswer,
    setEnableScrollToBottom,
    isDatabase,
    activeChart,
    setActiveChart,
}) => {
    //   const [previousFileToChat, setPreviousFileToChat] = useState('');
    //   useEffect(() => {
    //       // Update previousFileToChat only when fileToChat changes
    //       if (fileToChat !== undefined && fileToChat !== '') {
    //           setPreviousFileToChat(fileToChat);
    //       }
    //   }, [fileToChat]);
    const [sqlQuery, setSqlQuery] = useState('');
    const [queries, setQueries] = useState([]);
    const [showQueries, setShowQueries] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);
    const adminList = useAdmin();
    const [isSmallScreen, setIsSmallScreen] = useState(
        window.innerWidth <= 1024
    );
    const dropdownRef = useRef(null);

    const userSpecificActionObj = {
        chart: {
            label: 'Show in chart',
            icon: faChartPie,
            //iconColor: 'sky',
            action: () =>
                handleChartFormat(chatObject, isDatabase, activeChart),
        },
        table: {
            label: 'Show in tabular format',
            icon: faTable,
            //iconColor: 'sky',
            action: () => handleTableFormat(),
        },
        list: {
            label: 'Show in List format',
            icon: faList,
            //iconColor: 'sky',
            action: () => handleListFormat(),
        },
    };

    const dropDownList = Object.values(userSpecificActionObj);

    useEffect(() => {
        // Handler to check if screen width is less than or equal to 1024px
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth <= 1024);
        };

        // Add event listener to window resize
        window.addEventListener('resize', handleResize);

        // Check the screen size initially
        handleResize();

        // Cleanup the event listener on unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        let isMounted = true; // Track whether the component is still mounted

        const loadResources = async () => {
            if (!chatObject.qid) return;

            // Load SQL query
            const query = chatObject.sqlQuery;
            if (isMounted && query) {
                setSqlQuery(query);
            }

            // Check if the user is an admin
            if (isMounted) {
                setIsAdmin(user && adminList.includes(user.email));
            }
        };

        loadResources();

        return () => {
            isMounted = false; // Cleanup on unmount
        };
    }, [chatObject.qid, adminList, user]);

    const convertToQueriesArray = (queriesString) => {
        let queriesArray = [];
        try {
            let parsedQueries = queriesString;

            // Manual conversion of the string to array
            if (typeof parsedQueries === 'string') {
                // Remove the outer square brackets and split by comma
                parsedQueries = parsedQueries
                    .replace(/^\[|\]$/g, '') // Remove outer square brackets
                    .split(/,\s*(?=['"])/) // Split by commas between strings
                    .map(
                        (query) => query.replace(/^['"]|['"]$/g, '') // Remove quotes around each query
                    );
            }

            // If it's an array, assign it to queriesArray
            if (Array.isArray(parsedQueries)) {
                queriesArray = parsedQueries;
            }
        } catch (error) {
            console.error('Failed to parse queries:', error);
        }

        return queriesArray;
    };

    const [queriesArray, setQueriesArray] = useState([]);

    useEffect(() => {
        if (chatObject.queries) {
            const array = convertToQueriesArray(chatObject.queries);
            setQueriesArray(array);
        }
    }, [chatObject.queries]);

    return (
        <div className='w-full ml-auto flex justify-between items-center border-b border-white pb-2 mb-2'>
            <div className='flex items-center justify-between text-texts-secondary300 truncate'>
                <div className='flex items-center justify-center w-6 h-6 rounded-full bg-backgrounds-white mr-2'>
                    <FontAwesomeIcon
                        icon={faRobot}
                        className='text-backgrounds-primary400'
                    />
                </div>
                <div className='font-semibold'>
                    <DisplayText
                        text={
                            !chatObject.fileToChat ||
                            chatObject.fileToChat === '' ||
                            chatObject.fileToChat === undefined
                                ? repoName
                                : chatObject.fileToChat
                        }
                        maxLength={28}
                    />
                </div>
            </div>

            {/* Action Section */}
            <div className='flex items-center w-fit h-full text-texts-secondary300'>
                {chatObject.queries && isAdmin && (
                    <>
                        <button
                            className='ml-4 hover:scale-110'
                            onClick={() => {
                                setEnableScrollToBottom(false);
                                setShowQueries(!showQueries);
                            }}
                            title='View related queries'
                        >
                            <FontAwesomeIcon
                                icon={faClipboardList}
                                style={{ fontSize: '18px' }}
                            />
                        </button>
                        {showQueries && (
                            <Modal
                                title={'Related Multi Queries'}
                                titleIcon={faClipboardList}
                                iconColor={'icons-primary'}
                                onClose={() => setShowQueries(false)}
                            >
                                <div className='w-96 text-texts-secondary300 p-3'>
                                    <div className='font-bold mb-2'>
                                        These are some multi queries that were
                                        asked from LLM:
                                    </div>
                                    {queriesArray && queriesArray.length > 0 ? (
                                        <ul className='list-disc pl-5'>
                                            {queriesArray.map(
                                                (query, index) => (
                                                    <li
                                                        key={index}
                                                        className='mb-1'
                                                    >
                                                        {query}
                                                    </li>
                                                )
                                            )}
                                        </ul>
                                    ) : (
                                        <p>
                                            No multi-queries fired for this
                                            answer.
                                        </p>
                                    )}
                                </div>
                            </Modal>
                        )}
                    </>
                )}

                {(chatObject.chunksData.length || sqlQuery) &&
                    typeof chatObject.answer === 'string' && (
                        <div className='text-texts-secondary300'>
                            {sqlQuery && (
                                <button
                                    className='ml-4 hover:scale-110'
                                    onClick={() => {
                                        setEnableScrollToBottom(false);
                                        setViewDatabaseQuery(true);
                                        setActiveDbQuery(sqlQuery);
                                    }}
                                    title='View query'
                                >
                                    <FontAwesomeIcon icon={faDatabase} />
                                </button>
                            )}

                            {isLastAnswer && (
                                <button
                                    className='ml-4 hover:scale-110'
                                    onClick={userSpecificActionObj.chart.action}
                                    disabled={!chatObject.answer}
                                    title='Convert answer into chart format'
                                >
                                    <FontAwesomeIcon icon={faChartPie} />
                                </button>
                            )}

                            {!sqlQuery && isLastAnswer && !isSmallScreen && (
                                <>
                                    <button
                                        className='ml-4 hover:scale-110'
                                        onClick={
                                            userSpecificActionObj.table.action
                                        }
                                        disabled={!chatObject.answer}
                                        title='Convert answer into tabular format'
                                    >
                                        <FontAwesomeIcon icon={faTable} />
                                    </button>
                                    <button
                                        className='ml-4 hover:scale-110'
                                        onClick={
                                            userSpecificActionObj.list.action
                                        }
                                        disabled={!chatObject.answer}
                                        title='Convert answer into list format'
                                    >
                                        <FontAwesomeIcon icon={faList} />
                                    </button>
                                </>
                            )}
                        </div>
                    )}

                {/* flag */}
                <button
                    title='Flag this response'
                    onClick={() => {
                        setEnableScrollToBottom(false);
                        setFlagMode(true);
                        setFlagIndex(chatObject.qid);
                    }}
                    className='ml-4 hover:scale-110'
                >
                    <FontAwesomeIcon
                        icon={faFlag}
                        className={
                            flagged
                                ? 'text-buttons-alert400'
                                : 'text-texts-secondary300'
                        }
                    />
                </button>

                {/* Dropdown for small screens */}
                {!sqlQuery && isLastAnswer && isSmallScreen && (
                    <div
                        className='relative inline-block text-left'
                        ref={dropdownRef}
                    >
                        <Dropdown
                            dropDownList={dropDownList}
                            icon={<FontAwesomeIcon icon={faEllipsisV} />}
                            showButtonText={false}
                            buttonText={null}
                            buttonHoverText={'More options'}
                            buttonClassName={
                                'ml-4 hover:scale-110 focus:outline-none focus:outline-none'
                            }
                            listClassName={''}
                            backgroundColor={''}
                            textColor={'green'}
                            disabled={false}
                            customClass={true}
                            placement={'left'}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

const ChartButtonGroup = ({
    activeChart,
    setActiveChart,
    isSmallScreen,
    setEnableScrollToBottom,
}) => {
    const buttonData = [
        {
            icon: faChartBar,
            type: ChartTypes.BAR_CHART,
            buttonText: 'Bar',
            hoverText: `Convert to Bar chart format`,
            action: () => {
                setEnableScrollToBottom(false);
                setActiveChart(ChartTypes.BAR_CHART);
            },
        },
        {
            icon: faChartColumn,
            type: ChartTypes.COLUMN_CHART,
            buttonText: 'Column',
            hoverText: `Convert to Column chart format`,
            action: () => {
                setEnableScrollToBottom(false);
                setActiveChart(ChartTypes.COLUMN_CHART);
            },
        },
        {
            icon: faChartLine,
            type: ChartTypes.LINE_CHART,
            buttonText: 'Line',
            hoverText: `Convert to Line chart format`,
            action: () => {
                setEnableScrollToBottom(false);
                setActiveChart(ChartTypes.LINE_CHART);
            },
        },
        // {
        //     icon: faChartArea,
        //     type: ChartTypes.AREA_CHART,
        //     buttonText: 'Area',
        //     hoverText: `Convert to Area chart format`,
        //     action: () => {
        //         setEnableScrollToBottom(false);
        //         setActiveChart(ChartTypes.AREA_CHART);
        //     },
        // },
        {
            icon: faChartPie,
            type: ChartTypes.PIE_CHART,
            buttonText: 'Pie',
            hoverText: `Convert to Pie Chart format`,
            action: () => {
                setEnableScrollToBottom(false);
                setActiveChart(ChartTypes.PIE_CHART);
            },
        },
    ];

    const renderButtonGroup = buttonData.map((data) => (
        <Button
            text={data.buttonText}
            type={'success'}
            onClick={data.action}
            icon={data.icon}
            hoverText={data.hoverText}
            disabled={false}
            active={activeChart === data.type}
            className={`${isSmallScreen ? 'mr-1' : 'ml-1'} mb-1`}
        />
    ));
    return (
        <div
            className={`w-full flex ${
                isSmallScreen ? 'flex-col' : ''
            } items-center justify-between mb-1`}
        >
            <div className='mr-2 font-semibold text-texts-secondary300'>
                Charts
            </div>
            <div className=' flex items-center justify-start mb-1 flex-wrap'>
                {renderButtonGroup}
            </div>
        </div>
    );
};

const AnswerView = ({
    chatObject,
    isDatabase,
    isSmallScreen,
    activeChart,
    setActiveChart,
    setEnableScrollToBottom,
}) => {
    const pageSize = isSmallScreen ? 3 : 5;
    let pagination = false;
    let answerDisplayComponent = null;

    if (chatObject) {
        if (
            isDatabase === RepoType.SQLLITE ||
            isDatabase === RepoType.POSTGRESS ||
            isDatabase === RepoType.MSSQL
        ) {
            // Database repositories

            let answer = chatObject.answer;

            if (isValidJsonString(answer)) {
                let level = 0;
                const validJsonLevels = 5;
                while (isValidJsonString(answer) && level < validJsonLevels) {
                    answer = JSON.parse(answer);
                    level++;
                }
            } else {
                answer = chatObject.answer;
            }

            if (answer.type === ChatFormatType.HTML) {
                answerDisplayComponent = (
                    <div
                        dangerouslySetInnerHTML={{
                            __html: answer.payload ?? answer,
                        }}
                        className='px-1'
                    />
                );
            } else if (answer.type === ChatFormatType.STRING) {
                answerDisplayComponent = chatObject.answer.payload
                    ? chatObject.answer.payload
                    : chatObject.answer;
            } else if (answer.type === ChatFormatType.TABLE) {
                const columnData = answer.payload.columns;
                const tableData = answer.payload.data;

                answerDisplayComponent = (
                    <ExpandableTable
                        columnData={columnData}
                        tableData={tableData}
                        pageSize={pageSize}
                        pagination={pagination}
                        isSmallScreen={isSmallScreen}
                        completeData={answer.payload.completeData}
                    />
                );
            } else if (chatObject.type === ChatFormatType.CHART) {
                answerDisplayComponent = (
                    <ChartDisplay
                        chatObject={chatObject}
                        activeChart={activeChart}
                        setActiveChart={setActiveChart}
                        isSmallScreen={isSmallScreen}
                        setEnableScrollToBottom={setEnableScrollToBottom}
                    />
                );
            } else {
                answerDisplayComponent = `${chatObject.answer}`;
            }
        } else {
            // Non database repositories
            if (chatObject.type === ChatFormatType.TABLE) {
                const columnData = chatObject.answer.tableHead;

                const tableData = chatObject.answer.tableData;
                const tableFormattedData = tableData.map((rowData) =>
                    Object.values(rowData)
                );

                answerDisplayComponent = (
                    <ExpandableTable
                        columnData={columnData}
                        tableData={tableFormattedData}
                        pageSize={pageSize}
                        pagination={pagination}
                        isSmallScreen={isSmallScreen}
                    />
                );
            } // Non database repositories
            else if (chatObject.type === ChatFormatType.CHART) {
                answerDisplayComponent = (
                    <ChartDisplay
                        chatObject={chatObject}
                        activeChart={activeChart}
                        setActiveChart={setActiveChart}
                        isSmallScreen={isSmallScreen}
                        setEnableScrollToBottom={setEnableScrollToBottom}
                    />
                );
            } else if (chatObject.type === ChatFormatType.LIST) {
                let data = [];

                if (chatObject.answer.listData) {
                    if (Array.isArray(chatObject.answer.listData)) {
                        data = chatObject.answer.listData;
                    } else {
                        data = [chatObject.answer.listData.toString()];
                    }
                } else {
                    data = [chatObject.answer.toString()];
                }

                answerDisplayComponent = (
                    <List
                        size='small'
                        dataSource={data}
                        renderItem={(item) => <List.Item>{item}</List.Item>}
                    />
                );
            } else if (chatObject.type === ChatFormatType.MULTIPLE) {
                let title = '';
                let data = [];

                if (!Array.isArray(chatObject.answer)) {
                    title = 'Multiple Answers';
                    data = [chatObject.answer.toString()];
                } else {
                    title = `${chatObject.answer.length} Answer${
                        chatObject.answer.length > 1 ? 's' : ''
                    }`;
                    data = chatObject.answer;

                    if (chatObject.answer.length > 1) {
                        pagination = { pageSize: pageSize };
                    }
                }

                answerDisplayComponent = (
                    <List
                        header={
                            <div className='text-texts-secondary300 font-semibold text-center w-full'>
                                {title}
                            </div>
                        }
                        size='small'
                        dataSource={data}
                        renderItem={(item) => <List.Item>{item}</List.Item>}
                        pagination={pagination}
                    />
                );
            } else if (chatObject.type === ChatFormatType.CUSTOM) {
                if (typeof chatObject.answer === ChatFormatType.STRING) {
                    answerDisplayComponent = chatObject.answer;
                } else {
                    answerDisplayComponent = chatObject.answer.toString();
                }
            } else {
                if (typeof chatObject.answer === ChatFormatType.STRING) {
                    answerDisplayComponent = chatObject.answer;
                } else {
                    answerDisplayComponent = chatObject.answer.toString();
                }
            }
        }

        return (
            <div className='w-full'>
                <div className='w-full text-slate-700'>
                    {answerDisplayComponent}
                </div>
                <div className='w-full flex justify-between items-center text-xs mt-2 text-texts-secondary300'>
                    <div>{getDate(chatObject.timestamp)}</div>
                    <div>{getTime(chatObject.timestamp)}</div>
                </div>
            </div>
        );
    }
};

const ActionContainer = ({
    index,
    repoName,
    item,
    user,
    activeQuestion,
    sortedQnAData,
    handleChartFormat,
    handleCustomFormat,
    handleListFormat,
    handleTableFormat,
    setViewDatabaseQuery,
    setActiveDbQuery,
    setFlagIndex,
    setFlagMode,
    isLastAnswer,
    enableScrollToBottom,
    setEnableScrollToBottom,
    isDatabase,
    isSmallScreen,
    chatEndRef,
    isGenerating,
}) => {
    const [activeChart, setActiveChart] = useState(item.answer.chartType);
    return (
        <div className='flex w-full items-center justify-end'>
            <div className='w-4/5 whitespace-normal break-words m-3 px-5 py-4 bg-backgrounds-secondaryChat max-w-[600px] rounded-xl shadow-lg'>
                <AnswerActionView
                    index={index}
                    repoName={repoName}
                    chatObject={item}
                    user={user}
                    size={sortedQnAData.length}
                    handleChartFormat={handleChartFormat}
                    handleCustomFormat={handleCustomFormat}
                    handleListFormat={handleListFormat}
                    handleTableFormat={handleTableFormat}
                    setViewDatabaseQuery={setViewDatabaseQuery}
                    setActiveDbQuery={setActiveDbQuery}
                    flagged={item.flagged}
                    setFlagIndex={setFlagIndex}
                    setFlagMode={setFlagMode}
                    isLastAnswer={isLastAnswer}
                    setEnableScrollToBottom={setEnableScrollToBottom}
                    isDatabase={isDatabase}
                    activeChart={activeChart}
                    setActiveChart={setActiveChart}
                />
                <AnswerView
                    chatObject={item}
                    index={index}
                    activeQuestion={activeQuestion}
                    isSmallScreen={isSmallScreen}
                    isDatabase={isDatabase}
                    activeChart={activeChart}
                    setActiveChart={setActiveChart}
                    setEnableScrollToBottom={setEnableScrollToBottom}
                />

                {/* Dummy reference div, for auto scroll to this div (bottom) when display updates */}
                {enableScrollToBottom && index === sortedQnAData.length - 1 && (
                    <div
                        ref={chatEndRef}
                        className={!isGenerating ? 'mt-2' : ''}
                    />
                )}
            </div>
        </div>
    );
};

export const ChatDisplay = ({
    activeQuestion,
    questionsData,
    flagMode,
    repoName,
    setQuery,
    user,
    setFlagMode,
    setFlagIndex,
    handleChartFormat,
    handleTableFormat,
    handleListFormat,
    handleCustomFormat,
    isGenerating,
    fileToChat,
    isDatabase,
}) => {
    const chatEndRef = React.createRef();

    const scrollToBottom = (chatEndRef) => {
        chatEndRef.current?.scrollIntoView({
            behavior: isGenerating ? 'smooth' : 'instant',
        });
    };
    const [viewDatabaseQuery, setViewDatabaseQuery] = useState(false);
    const [activeDbQuery, setActiveDbQuery] = useState('');
    const [enableScrollToBottom, setEnableScrollToBottom] = useState(true);

    //responsiveness related
    const [isSmallScreen, setIsSmallScreen] = useState(
        window.innerWidth <= 1024
    );

    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth <= 1024);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [window.innerWidth]);

    useEffect(() => {
        scrollToBottom(chatEndRef);
    }, [chatEndRef]);

    useEffect(() => {
        setEnableScrollToBottom(true);
    }, [isGenerating, repoName, fileToChat]);

    let chatList = [];
    if (questionsData) {
        // Sorting the chat according to time and date
        const sortedQnAData = [...questionsData.values()].sort((a, b) => {
            return new Date(a.timestamp) - new Date(b.timestamp);
        });

        chatList = sortedQnAData.map((item, index) => {
            const isLastAnswer = index === sortedQnAData.length - 1;
            return (
                <div key={index}>
                    {/* Question Container */}
                    <div className='flex w-full items-center justify-start'>
                        <div className='w-4/5 whitespace-normal break-words m-3 px-5 py-4 bg-backgrounds-primary100 max-w-[600px] rounded-xl shadow-lg'>
                            <QuestionActionView
                                user={user}
                                chatObject={item}
                                setQuery={setQuery}
                            />
                            <QuestionView chatObject={item} />
                        </div>
                    </div>

                    {/* Answer Container */}
                    <ActionContainer
                        user={user}
                        index={index}
                        repoName={repoName}
                        item={item}
                        activeQuestion={activeQuestion}
                        sortedQnAData={sortedQnAData}
                        handleChartFormat={handleChartFormat}
                        handleCustomFormat={handleCustomFormat}
                        handleListFormat={handleListFormat}
                        handleTableFormat={handleTableFormat}
                        setViewDatabaseQuery={setViewDatabaseQuery}
                        setActiveDbQuery={setActiveDbQuery}
                        setFlagIndex={setFlagIndex}
                        setFlagMode={setFlagMode}
                        isLastAnswer={isLastAnswer}
                        enableScrollToBottom={enableScrollToBottom}
                        setEnableScrollToBottom={setEnableScrollToBottom}
                        isDatabase={isDatabase}
                        isSmallScreen={isSmallScreen}
                        chatEndRef={chatEndRef}
                        isGenerating={isGenerating}
                    />
                </div>
            );
        });
    }

    return chatList && chatList.length ? (
        <div className='w-full h-full p-4 flex flex-col justify-between'>
            {/* Database modal */}
            {viewDatabaseQuery && (
                <Modal
                    title={'Database Query'}
                    titleIcon={faDatabase}
                    iconColor={'icons-primary'}
                    onClose={() => setViewDatabaseQuery(false)}
                >
                    <div className='w-96 text-texts-secondary300 p-3'>
                        {activeDbQuery}
                    </div>
                </Modal>
            )}

            <div className='w-full h-full'>{chatList}</div>
            {isGenerating && (
                <div className='drop-shadow-sm mt-2'>
                    <LoadingView
                        inLine={true}
                        loadingText={'Generating Answer'}
                    />
                </div>
            )}
        </div>
    ) : (
        <NoChatView
            isGenerating={isGenerating}
            repoName={repoName}
            fileToChat={fileToChat}
        />
    );
};
