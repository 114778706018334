import React, { useState, useEffect } from 'react';
import { Form, Input, Select, Switch } from 'antd';
import { Button } from './reusableComponents';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCircleXmark,
    faExclamationTriangle,
    faFolderPlus,
} from '@fortawesome/free-solid-svg-icons';
import { getUrl } from './pdfDisplay/utils';

const { Option } = Select;

const AddEmailRepo = ({
    reponame,
    setAddNewMode,
    getAllReposData,
    socket,
    isAdmin,
    visionRepo,
    onChangeVision,
    visionModel,
    setVisionModel,
    visionModelDropdown,
    user,
}) => {
    const [formData, setFormData] = useState({
        email: '',
        password: '',
        serviceType: '',
        mailServerName: '',
        mailPort: '',
        smtpServerName: '',
        smtpPort: '',
        template: '',
    });
    const [templateList, setTemplateList] = useState([]);
    const [error, setError] = useState('');

    const {
        email,
        password,
        serviceType,
        mailServerName,
        mailPort,
        smtpServerName,
        smtpPort,
        template,
    } = formData;

    useEffect(() => {
        // Fetch template list when the component mounts or user changes
        const fetchTemplateList = async () => {
            try {
                console.log('FETCHING TEMPLATES.....');

                // Directly build the URL without using fetch
                const tempUrl = getUrl(
                    `http://${process.env.REACT_APP_NODE_SERVER_HOST}:${process.env.REACT_APP_NODE_SERVER_PORT}/gettemplate`
                );

                // Fetch template list using axios
                const response = await axios.get(tempUrl, {
                    params: {
                        id: user.email,
                    },
                });

                const templatesData = response.data;

                // Update the state with the fetched templates
                setTemplateList(templatesData);
            } catch (err) {
                console.error('Error fetching template list:', err);
                setError('Failed to load templates.');
            }
        };

        if (user && user.email) {
            fetchTemplateList();
        } else {
            console.error('User email is not available');
            setError('User not found.');
        }
    }, []); // Add user to the dependency array

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSelectChange = (value, field) => {
        setFormData({
            ...formData,
            [field]: value,
        });
    };

    const isFormComplete = () => {
        return Object.values(formData).every((value) => value.trim() !== '');
    };

    const onFinish = async () => {
        setError('');
        try {
            // Validation logic for the fields
            if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
                setError('Please enter a valid email address.');
                return;
            }
            if (isNaN(Number(mailPort)) || mailPort.toString().length > 4) {
                setError('Mail port must be a numeric value up to 4 digits.');
                return;
            }
            if (isNaN(Number(smtpPort)) || smtpPort.toString().length > 4) {
                setError('SMTP port must be a numeric value up to 4 digits.');
                return;
            }

            const dataToSend = {
                user: user.email,
                repoName: reponame,
                email,
                password,
                serviceType,
                mailServerName,
                mailPort,
                smtpServerName,
                smtpPort,
                template,
            };
            const encodedToken = encodeURIComponent(user.token);

            // First API call: Store email extraction info
            try {
                const emailResponse = await fetch(
                    getUrl(
                        `http://${process.env.REACT_APP_FAST_API_HOST}:${process.env.REACT_APP_FAST_API_PORT}/email_extraction_routes/store-email-ext-info`
                    ),
                    {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(dataToSend),
                    }
                );

                if (!emailResponse.ok) {
                    const errorData = await emailResponse.json();
                    console.error(
                        'Error during email configuration:',
                        errorData
                    );
                    alert(
                        'Failed to store email extraction info. Please try again.'
                    );
                    return;
                }

                console.log('Email extraction info stored successfully.');
            } catch (err) {
                console.error(
                    'Error during email extraction info API call:',
                    err
                );
                alert(
                    'An error occurred while storing email extraction info. Please try again.'
                );
                return;
            }

            const folderData = {
                id: user.email,
                name: reponame,
                visionRepo: visionRepo,
                visionModel:
                    visionModel === 'Percision Model'
                        ? 'gemini-1.5-pro'
                        : 'gemini-1.5-flash',
                vector_store_type: 'CHROMA',
                summary_flag: false,
                storage_type: 'email',
            };
            const repoDataString = encodeURIComponent(
                JSON.stringify(folderData)
            );

            // Second API call: Create repository
            try {
                const repoResponse = await fetch(
                    getUrl(
                        `http://${process.env.REACT_APP_NODE_SERVER_HOST}:${process.env.REACT_APP_NODE_SERVER_PORT}/new_empty_repo/${repoDataString}`
                    ),
                    {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: encodedToken,
                        },
                    }
                );

                if (!repoResponse.ok) {
                    const errorData = await repoResponse.json();
                    console.error(
                        'Error during repository creation:',
                        errorData
                    );
                    alert('Failed to create repository. Please try again.');
                    return;
                }

                console.log('Repository created successfully.');
            } catch (err) {
                console.error(
                    'Error during repository creation API call:',
                    err
                );
                alert(
                    'An error occurred while creating the repository. Please try again.'
                );
                return;
            }

            // Refresh repository data
            getAllReposData();

            // Close the modal on success
            setAddNewMode(false);
        } catch (err) {
            console.error('Unexpected error during API requests:', err);
            alert('An unexpected error occurred. Please try again.');
        }
    };

    return (
        <div>
            <Form
                name='basic'
                initialValues={{ remember: true }}
                onFinish={onFinish}
                layout='vertical'
            >
                <Form.Item
                    label='Email'
                    name='email'
                    rules={[
                        { required: true, message: 'Please input your email!' },
                    ]}
                >
                    <Input name='email' value={email} onChange={handleChange} />
                </Form.Item>

                <Form.Item
                    label='Password'
                    name='password'
                    rules={[
                        {
                            required: true,
                            message: 'Please input your password!',
                        },
                    ]}
                >
                    <Input.Password
                        name='password'
                        value={password}
                        onChange={handleChange}
                    />
                </Form.Item>

                <Form.Item
                    label='Service Type'
                    name='serviceType'
                    rules={[
                        {
                            required: true,
                            message: 'Please select the service type!',
                        },
                    ]}
                >
                    <Select
                        value={serviceType}
                        onChange={(value) =>
                            handleSelectChange(value, 'serviceType')
                        }
                        placeholder='Select service type'
                    >
                        <Option value='IMAP'>IMAP</Option>
                        <Option value='POP'>POP</Option>
                    </Select>
                </Form.Item>

                <Form.Item
                    label='Mail Server Name'
                    name='mailServerName'
                    rules={[
                        {
                            required: true,
                            message: 'Please input the mail server name!',
                        },
                    ]}
                >
                    <Input
                        name='mailServerName'
                        value={mailServerName}
                        onChange={handleChange}
                    />
                </Form.Item>

                <Form.Item
                    label='Mail Port'
                    name='mailPort'
                    rules={[
                        {
                            required: true,
                            message: 'Please input the mail port!',
                        },
                    ]}
                >
                    <Input
                        name='mailPort'
                        value={mailPort}
                        onChange={handleChange}
                    />
                </Form.Item>

                <Form.Item
                    label='SMTP Server Name'
                    name='smtpServerName'
                    rules={[
                        {
                            required: true,
                            message: 'Please input the SMTP server name!',
                        },
                    ]}
                >
                    <Input
                        name='smtpServerName'
                        value={smtpServerName}
                        onChange={handleChange}
                    />
                </Form.Item>

                <Form.Item
                    label='SMTP Port'
                    name='smtpPort'
                    rules={[
                        {
                            required: true,
                            message: 'Please input the SMTP port!',
                        },
                    ]}
                >
                    <Input
                        name='smtpPort'
                        value={smtpPort}
                        onChange={handleChange}
                    />
                </Form.Item>

                <Form.Item
                    label='Template'
                    name='template'
                    rules={[
                        {
                            required: true,
                            message: 'Please select a template!',
                        },
                    ]}
                >
                    <Select
                        placeholder='Select a template'
                        value={template}
                        onChange={(value) =>
                            handleSelectChange(value, 'template')
                        }
                    >
                        {templateList.map((tpl) => (
                            <Select.Option
                                key={tpl.template_id || tpl.filename} // Use template_id if available, fallback to filename
                                value={tpl.filename} // Use filename as value
                            >
                                {tpl.filename} {/* Display filename */}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>

                {error && (
                    <div className='flex justify-center items-center text-buttons-alert400 mt-3'>
                        <FontAwesomeIcon
                            icon={faExclamationTriangle}
                            className='mr-2'
                        />
                        {error}
                    </div>
                )}

                <div>
                    {isAdmin && (
                        <div className='flex justify-between items-center mt-3'>
                            <div className='flex items-center justify-center mt-4'>
                                <div className='mr-2 font-semibold text-texts-secondary300'>
                                    Vision Processing
                                </div>
                                <Switch
                                    checked={visionRepo}
                                    onChange={onChangeVision}
                                />
                            </div>
                        </div>
                    )}

                    <div className='flex items-center mt-4 '>
                        <div className='w-1/8 font-semibold text-texts-secondary300'>
                            <label htmlFor='Vision Model'>Vision Model</label>
                        </div>
                        <div className='ml-2 w-full md:w-1/4'>
                            <div className='relative'>
                                <select
                                    id='Vision Model'
                                    className='block w-auto md:w-30 p-2 mt-1 rounded-xl shadow-sm focus:ring-backgrounds-primary500 focus:border-backgrounds-primary400 hover:outline-none focus:outline-backgrounds-primary200 border hover:border-backgrounds-primary300'
                                    value={visionModel}
                                    onChange={(e) =>
                                        setVisionModel(e.target.value)
                                    }
                                >
                                    <option disabled={true} value={null}>
                                        Select a Vision Model
                                    </option>
                                    {visionModelDropdown.map((method) => (
                                        <option key={method} value={method}>
                                            {method}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    </div>
                </div>

                <Form.Item>
                    <div className='flex items-center justify-center mt-4'>
                        <div className='flex items-center justify-between w-full'>
                            <Button
                                type='danger'
                                icon={faCircleXmark}
                                text={'Cancel'}
                                tile={'Cancel and close the modal'}
                                className='w-1/3'
                                onClick={() => setAddNewMode(false)}
                            />

                            <button
                                className={`flex items-center justify-center rounded-full py-1.5 px-2.5 rounded-lg order bg-backgrounds-white shadow-inner border font-semibold w-1/3 ${
                                    !isFormComplete()
                                        ? 'text-texts-secondary300 cursor-not-allowed'
                                        : 'hover:bg-backgrounds-primary100 hover:border-backgrounds-primary200 text-backgrounds-primary400'
                                }`}
                                type='submit'
                                title='Add new email service repository'
                                disabled={!isFormComplete()}
                            >
                                <FontAwesomeIcon
                                    icon={faFolderPlus}
                                    className='mr-2'
                                />
                                Add
                            </button>
                        </div>
                    </div>
                </Form.Item>
            </Form>
        </div>
    );
};

export default AddEmailRepo;
