import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faHandPointLeft,
    faHandPointUp,
} from '@fortawesome/free-solid-svg-icons';
import supportImage from '../../images/technicalSupport.png';

export const SupportBeforeSelect = () => {
    const isSmallScreen = window.innerWidth < 1024;

    return (
        <div className='bg-backgrounds-slate w-full h-full rounded-xl'>
            {!isSmallScreen ? (
                <>
                    <div className='flex h-4/6 w-full items-center justify-center text-texts-secondary300'>
                        <div className='flex flex-col items-center justify-center m-[3vw]'>
                            <img
                                className='w-[8vw] h-[8vw] mb-2 drop-shadow'
                                src={supportImage}
                                alt=''
                                draggable='false'
                            />

                            <div className='mr-8'>Support</div>
                        </div>
                    </div>

                    <div className='flex items-center justify-center text-texts-secondary300 text-xl pt-10'>
                        <FontAwesomeIcon
                            icon={faHandPointLeft}
                            className='mr-3 text-icons-primary'
                        />
                        <div>
                            Select a category from left to view related support
                            articles
                        </div>
                    </div>
                </>
            ) : (
                <div className='flex flex-col items-center'>
                    <div className='flex items-center justify-center text-texts-secondary300 text-xl pt-20'>
                        <FontAwesomeIcon
                            icon={faHandPointUp}
                            className='mr-3 text-icons-primary'
                        />
                        <h1>
                            Select a category from left to view related support
                            articles
                        </h1>
                    </div>

                    <div className='flex h-4/6 w-full items-center justify-center text-texts-secondary300 pt-20'>
                        <div className='flex flex-col items-center justify-center m-[3vw]'>
                            <img
                                className='w-[25vw] h-[25vw] mb-2 drop-shadow'
                                src={supportImage}
                                alt=''
                                draggable='false'
                            />

                            <div className='mr-8'>Support</div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};
