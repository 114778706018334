import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
    faRightFromBracket,
    faUserAlt,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from './reusableComponents';
import { PoweredByInfo } from './powereByComponent';
import { useAuth } from '../context/AuthContext';

// export const handleLogout = async (navigate) => {

//     try {
//         const ipAddress = window.location.hostname;
//         const dynamicUrl = `http://${process.env.REACT_APP_NODE_SERVER_HOST}:${process.env.REACT_APP_NODE_SERVER_PORT}/logout`;

//         // localStorage.clear();
//         // navigate('/login');
//         const response = await fetch(dynamicUrl, {
//             method: 'POST',
//             credentials: 'include',
//         });
//     } catch (err) {
//         console.log(err);
//     }
// };

export const UserDetailAndAction = () => {
    const user = JSON.parse(localStorage.getItem('current'));
    const navigate = useNavigate();
    const { logout } = useAuth();

    return (
        <div className='flex flex-col items-center justify-between text-texts-secondary300 w-full h-full pt-1 truncate'>
            <div className='w-full flex items-center border border-transparent p-2 rounded-xl mt-2 truncate'>
                <FontAwesomeIcon
                    icon={faUserAlt}
                    size='2x'
                    className='rounded-full bg-backgrounds-white border p-2 text-sky-300'
                />
                <div className='ml-3 truncate'>
                    <div className='font-semibold truncate text-base'>
                        {user.username}
                    </div>
                    <div className='text-sm truncate text-base'>
                        {user.email}
                    </div>
                </div>
            </div>

            <Button
                text={'Logout'}
                className='w-full mb-1'
                onClick={() => {
                    // handleLogout(navigate);
                    logout();
                }}
                hoverText='Log out from current user'
                icon={faRightFromBracket}
                type={'danger'}
                disabled={false}
            />

            <div className='flex w-full items-center justify-center truncate'>
                <PoweredByInfo />
            </div>
        </div>
    );
};
