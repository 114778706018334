import React, { useEffect, useState } from 'react';
import { Card, Badge, Switch, message, Modal } from 'antd';
import { CheckOutlined } from '@ant-design/icons';
import { useAuth } from '../context/AuthContext';
import axios from 'axios';
import { loadPaymentScript } from '../utils/loadPaymentScript';
import { getUrl } from './pdfDisplay/utils';
import { usePlanData } from '../hooks/usePlanData';
import {
    getPlanPriority,
    SUBSCRIPTION_PLANS,
    YEARLY_DISCOUNT_PERCENTAGE,
} from '../utils/planUtils';
import { Currency } from '../constants/currency';
import { LoadingView } from './reusableComponents';
import { Button } from './reusableComponents/button';

const PricingPageOnLogin = () => {
    const { plans, error } = usePlanData();
    const [planName, setPlanName] = useState(SUBSCRIPTION_PLANS.FREE);
    const [isYearly, setIsYearly] = useState(true);
    const { user } = useAuth();
    const [isLoading, setIsLoading] = useState(false);
    const [isCheckoutLoading, setIsCheckoutLoading] = useState(false);
    const [currentRazorpaySubId, setCurrentRazorpaySubId] = useState(null);
    const [showCancelModal, setShowCancelModal] = useState(false);
    const [cancellingSubscription, setCancellingSubscription] = useState(false);

    useEffect(() => {
        if (error) {
            message.error(error);
        }
    }, [error]);

    useEffect(() => {
        const fetchPlanName = async () => {
            setIsLoading(true);
            try {
                const response = await axios.get(
                    getUrl(
                        `http://${process.env.REACT_APP_FAST_API_HOST}:${
                            process.env.REACT_APP_FAST_API_PORT
                        }/subscription_routes/current-plan/${encodeURIComponent(
                            user.email
                        )}`
                    )
                );
                setPlanName(response.data.name);
                setCurrentRazorpaySubId(response.data.razorpay_subscription_id);
            } catch (error) {
                console.error('Error fetching plan:', error);
                message.error('Failed to fetch current plan details');
                setPlanName(SUBSCRIPTION_PLANS.FREE);
            } finally {
                setIsLoading(false);
            }
        };
        fetchPlanName();
    }, [user]);

    const handleCheckout = async (amount) => {
        try {
            if (!amount) {
                throw new Error('Please enter a valid amount.');
            }
            setIsCheckoutLoading(true);
            const dynamicUrl = getUrl(
                `http://${process.env.REACT_APP_NODE_SERVER_HOST}:${process.env.REACT_APP_NODE_SERVER_PORT}/create-checkout-session`
            );
            const authToken = encodeURIComponent(user.token);
            const response = await axios.post(
                dynamicUrl,
                {
                    amount: amount,
                    currency: user.currency,
                    isYearly: isYearly,
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: authToken,
                    },
                }
            );

            if (response) {
                let data = {
                    amount: response?.data?.amount,
                    order_id: response?.data?.id,
                    currency: response?.data?.currency,
                    subscription_id: response?.data?.subscription_id,
                };
                loadPaymentScript(user, data);
            }
        } catch (err) {
            console.error('Error processing payment:', err);
            message.error(err.message || 'Payment failed. Please try again.');
        } finally {
            setIsCheckoutLoading(false);
        }
    };

    const handlePlanSelection = (plan, amount) => {
        try {
            if (getPlanPriority(plan.name) <= getPlanPriority(planName)) {
                return;
            }
            handleCheckout(amount);
        } catch (error) {
            console.error('Error selecting plan:', error);
            message.error('Failed to process plan selection');
        }
    };

    const handleCancelSubscription = async () => {
        try {
            if (!currentRazorpaySubId) {
                throw new Error('No active subscription found');
            }

            setCancellingSubscription(true);
            const response = await fetch(
                getUrl(
                    `http://${process.env.REACT_APP_NODE_SERVER_HOST}:${process.env.REACT_APP_NODE_SERVER_PORT}/cancel-subscription/${currentRazorpaySubId}`
                ),
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: user.token,
                    },
                    body: JSON.stringify({ email: user.email }),
                }
            );

            if (!response.ok) {
                throw new Error('Failed to cancel subscription');
            }

            message.success('Subscription cancelled successfully');
            setShowCancelModal(false);
            window.location.reload();
            return true;
        } catch (error) {
            console.error('Error cancelling subscription:', error);
            message.error('Failed to cancel subscription');
            return false;
        } finally {
            setCancellingSubscription(false);
        }
    };

    const CancelConfirmationModal = () => (
        <Modal
            title='Cancel Subscription'
            open={showCancelModal}
            onCancel={() => setShowCancelModal(false)}
            footer={null}
        >
            {cancellingSubscription ? (
                <LoadingView loadingText='Cancelling subscription' />
            ) : (
                <div className='space-y-4'>
                    <h3 className='text-lg font-medium'>
                        Are you sure you want to cancel your subscription?
                    </h3>
                    <div className='text-sm'>
                        <p>This action will:</p>
                        <ul className='list-disc pl-5 mt-2 space-y-2'>
                            <li>
                                Cancel your current subscription immediately
                            </li>
                            <li>Remove access to premium features</li>
                            <li>Downgrade your account to the free plan</li>
                            <li>This action cannot be undone</li>
                        </ul>
                    </div>
                    <div className='flex justify-end space-x-4 mt-6'>
                        <Button
                            text='Keep Subscription'
                            type='success'
                            onClick={() => setShowCancelModal(false)}
                        />
                        <Button
                            text='Cancel Subscription'
                            type='danger'
                            onClick={handleCancelSubscription}
                        />
                    </div>
                </div>
            )}
        </Modal>
    );

    return (
        <div className='flex flex-col items-center max-w-[1440px] mx-auto px-2'>
            {isLoading ? (
                <div className='flex justify-center items-center w-full py-8'>
                    <LoadingView loadingText='Loading subscription plans...' />
                </div>
            ) : (
                <>
                    <div className='mt-8 pt-4 flex items-center justify-center gap-6'>
                        <span
                            className={`${
                                !isYearly ? 'font-bold' : 'text-gray-500'
                            } text-sm`}
                        >
                            Monthly
                        </span>
                        <Switch
                            checked={isYearly}
                            onChange={setIsYearly}
                            className='bg-backgrounds-primary300'
                        />
                        <span
                            className={`${
                                isYearly ? 'font-bold' : 'text-gray-500'
                            } text-sm`}
                        >
                            Yearly{' '}
                            <span className='text-green-500'>
                                (Save {YEARLY_DISCOUNT_PERCENTAGE}%)
                            </span>
                        </span>
                    </div>

                    <div className='flex flex-wrap justify-center items-stretch gap-4 p-4 w-full'>
                        {plans
                            .filter((plan) => {
                                if (plan.name === SUBSCRIPTION_PLANS.FREE)
                                    return true;

                                if (isYearly) {
                                    return plan.name
                                        .toLowerCase()
                                        .includes('yearly');
                                } else {
                                    return !plan.name
                                        .toLowerCase()
                                        .includes('yearly');
                                }
                            })
                            .sort((a, b) => {
                                // Define the order: FREE -> BASIC -> PRO -> ENTERPRISE
                                const planOrder = {
                                    FREE: 1,
                                    BASIC: 2,
                                    PRO: 3,
                                    ENTERPRISE: 4,
                                };

                                // Get the base plan name without _YEARLY suffix
                                const planA = a.name.split('_')[0];
                                const planB = b.name.split('_')[0];

                                return planOrder[planA] - planOrder[planB];
                            })
                            .map((plan, index) => {
                                const displayName = plan.name.split('_')[0];
                                const isMostPopular =
                                    displayName.toLowerCase() === 'pro';

                                return (
                                    <Badge.Ribbon
                                        key={index}
                                        text={
                                            plan.name === planName
                                                ? 'Current'
                                                : isMostPopular
                                                ? 'Most Popular'
                                                : ''
                                        }
                                        color={
                                            plan.name === planName
                                                ? 'yellow'
                                                : isMostPopular
                                                ? 'blue'
                                                : 'white'
                                        }
                                    >
                                        <Card
                                            className='w-64 h-[420px] rounded-xl shadow-md hover:shadow-lg transition duration-200 flex flex-col flex-shrink-0'
                                            title={displayName}
                                            headStyle={{
                                                backgroundColor: isMostPopular
                                                    ? '#e0f7ff'
                                                    : 'white',
                                                textAlign: 'center',
                                                fontWeight: 'bold',
                                                padding: '8px',
                                            }}
                                            bodyStyle={{
                                                height: '100%',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                padding: '12px',
                                            }}
                                        >
                                            <div className='text-center mb-1'>
                                                <div className='text-2xl font-semibold'>
                                                    {
                                                        Currency[user.currency]
                                                            .symbol
                                                    }
                                                    {plan.name ===
                                                    SUBSCRIPTION_PLANS.FREE
                                                        ? 0
                                                        : user.currency ===
                                                          Currency.USD.key
                                                        ? plan.price.toFixed(2)
                                                        : (
                                                              plan.price * 100
                                                          ).toFixed(2)}
                                                </div>
                                                <div className='text-xs mt-1 text-gray-500'>
                                                    {plan.name ===
                                                    SUBSCRIPTION_PLANS.FREE
                                                        ? 'forever'
                                                        : isYearly
                                                        ? 'per year'
                                                        : 'per month'}
                                                </div>
                                                {isYearly &&
                                                    plan.name !==
                                                        SUBSCRIPTION_PLANS.FREE && (
                                                        <div className='text-xs mt-1 text-gray-500'>
                                                            (
                                                            {
                                                                Currency[
                                                                    user
                                                                        .currency
                                                                ].symbol
                                                            }
                                                            {user.currency ===
                                                            Currency.USD.key
                                                                ? (
                                                                      plan.price /
                                                                      12
                                                                  ).toFixed(2)
                                                                : (
                                                                      (plan.price *
                                                                          100) /
                                                                      12
                                                                  ).toFixed(
                                                                      2
                                                                  )}{' '}
                                                            /month)
                                                        </div>
                                                    )}
                                            </div>
                                            <div className='text-center mt-1 text-xs text-gray-500 mb-3'>
                                                {plan.description}
                                            </div>
                                            <ul className='flex-grow mt-1 mb-4 text-sm space-y-2'>
                                                {plan.features.map(
                                                    (feature, i) => (
                                                        <li
                                                            key={i}
                                                            className={`flex items-start ${
                                                                feature === ''
                                                                    ? 'invisible '
                                                                    : 'gap-2'
                                                            }`}
                                                        >
                                                            <span className='text-green-500 mt-0.5'>
                                                                <CheckOutlined
                                                                    style={{
                                                                        fontSize:
                                                                            '12px',
                                                                    }}
                                                                />
                                                            </span>
                                                            <span className='text-gray-800 text-sm'>
                                                                {feature}
                                                            </span>
                                                        </li>
                                                    )
                                                )}
                                            </ul>

                                            {plan.name !==
                                                SUBSCRIPTION_PLANS.FREE && (
                                                <Button
                                                    text={
                                                        plan.name === planName
                                                            ? 'Cancel Subscription'
                                                            : `Upgrade to ${
                                                                  plan.name.split(
                                                                      '_'
                                                                  )[0]
                                                              }`
                                                    }
                                                    type={
                                                        plan.name === planName
                                                            ? 'danger'
                                                            : 'success'
                                                    }
                                                    onClick={() =>
                                                        plan.name === planName
                                                            ? setShowCancelModal(
                                                                  true
                                                              )
                                                            : handlePlanSelection(
                                                                  plan,
                                                                  user.currency ===
                                                                      Currency
                                                                          .USD
                                                                          .key
                                                                      ? plan.price
                                                                      : plan.price *
                                                                            100
                                                              )
                                                    }
                                                    disabled={
                                                        isCheckoutLoading ||
                                                        (plan.name !==
                                                            planName &&
                                                            getPlanPriority(
                                                                plan.name
                                                            ) <=
                                                                getPlanPriority(
                                                                    planName
                                                                ))
                                                    }
                                                    className={`w-full ${
                                                        plan.name !==
                                                            planName &&
                                                        getPlanPriority(
                                                            plan.name
                                                        ) <=
                                                            getPlanPriority(
                                                                planName
                                                            )
                                                            ? 'bg-gray-200'
                                                            : ''
                                                    }`}
                                                />
                                            )}
                                        </Card>
                                    </Badge.Ribbon>
                                );
                            })}
                    </div>
                </>
            )}
            <CancelConfirmationModal />
        </div>
    );
};

export default PricingPageOnLogin;
