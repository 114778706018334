import React from 'react';

import { Button } from '../../reusableComponents';
import { faClose, faExpand, faPencil } from '@fortawesome/free-solid-svg-icons';
import './styles';
import { ChartView } from './chartView';
import { ChartTypes } from './constants';

const isValidWidget = (widgetName, selectedRepo, queryText, chartData) => {
    if (
        !widgetName ||
        !selectedRepo ||
        !queryText ||
        !chartData ||
        !chartData.chart ||
        !chartData.data ||
        chartData.data.length === 0
    ) {
        return false;
    }

    return true;
};

export const Widget = ({
    layout,
    metaData,
    reposData,
    queriesData,
    chartsData,
    setActiveWidget,
    setViewWidgetOptions,
    setViewFullScreenResult,
    setShowConfirmationModal,
}) => {
    const widgetName = metaData.get(layout.i).name;
    const selectedRepo = reposData.get(layout.i);
    const queryText = queriesData.get(layout.i).queryText;
    const chartData = chartsData.get(layout.i);
    const isWidgetNameHidden = metaData.get(layout.i)
        ? metaData.get(layout.i).isWidgetNameHidden
        : false;

    const { backgroundColor, color } = chartData.options;
    return (
        <div
            className='w-full h-full rounded-xl overflow-hidden'
            style={{ backgroundColor: backgroundColor, color: color }}
        >
            {/* Card Header */}
            <div
                className={`w-full flex justify-between items-center p-2 rounded-t-xl hover:bg-backgrounds-primary100 hover:shadow-inner ${
                    chartData.chart === ChartTypes.CARD ? 'border-b' : ''
                } ${
                    isValidWidget(
                        widgetName,
                        selectedRepo,
                        queryText,
                        chartData
                    )
                        ? 'bg-inherit'
                        : 'bg-buttons-alert'
                }`}
            >
                {!isWidgetNameHidden ? (
                    <div className='font-semibold p-1 drop-shadow-sm truncate'>
                        <span className='w-full truncate'>{widgetName}</span>
                    </div>
                ) : (
                    <div></div>
                )}

                <div className='flex justify-center items-center'>
                    <Button
                        // text={'Edit Card'}
                        icon={faPencil}
                        className={'mr-2'}
                        onClick={(e) => {
                            e.stopPropagation();
                            setActiveWidget(layout);
                            setViewWidgetOptions(true);
                        }}
                        hoverText={'Edit Widget'}
                        type={'Warning'}
                    />
                    <Button
                        // text={'Edit Card'}
                        icon={faExpand}
                        className={'mr-2'}
                        onClick={(e) => {
                            e.stopPropagation();
                            setActiveWidget(layout);
                            setViewFullScreenResult(true);
                        }}
                        hoverText={'Expand Widget'}
                        type={'success'}
                    />

                    <Button
                        // text={'Delete Card'}
                        icon={faClose}
                        className={''}
                        onClick={(e) => {
                            e.stopPropagation();
                            setActiveWidget(layout);
                            setShowConfirmationModal(true);
                        }}
                        hoverText={'Remove Widget'}
                        type={'danger'}
                    />
                </div>
            </div>

            {/* Card Body */}
            <div
                className={`w-full ${
                    chartData.chart === ChartTypes.TABLE ? 'h-fit' : 'h-full'
                }`}
            >
                <ChartView chartData={chartData} chartName={widgetName} />
            </div>
        </div>
    );
};
