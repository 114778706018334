import React, { useState } from 'react';
import { ReflexContainer, ReflexElement, ReflexSplitter } from 'react-reflex';
import { TwitterPicker } from 'react-color';
import {
    backgroundColors,
    ChartTypes,
    fontColors,
    iconColor,
} from './constants';
import { Input } from '../../reusableComponents';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGear, faPalette, faT } from '@fortawesome/free-solid-svg-icons';
import { ChartWithControls } from './chartWithControls';

const maxLegendLength = 20;

const Title = ({ title, icon }) => {
    return (
        <div className='w-full flex items-center font-semibold text-texts-secondary300 bg-backgrounds-primary100 px-2 py-1'>
            <FontAwesomeIcon icon={icon} className='mr-2' />
            <span>{title}</span>
        </div>
    );
};

export const CardEditView = ({
    selectedChart,
    setSelectedChart,
    chartData,
    chartName,
    chartOptions,
    setChartOptions,
    closeDropdownSignal,
    showChartInFullScreen,
    toggleChartViewInFullScreen,
    enableEditing,
    setEnableEditing,
}) => {
    const [hAxis, setHAxis] = useState(chartOptions.hAxis.title ?? '');
    const [vAxis, setVAxis] = useState(chartOptions.vAxis.title ?? '');
    const isValidAxisChart =
        selectedChart === ChartTypes.AREA_CHART ||
        selectedChart === ChartTypes.BAR_CHART ||
        selectedChart === ChartTypes.COLUMN_CHART ||
        selectedChart === ChartTypes.LINE_CHART ||
        selectedChart === ChartTypes.SCATTER_CHART;

    const handleBackgroundColorChange = (color, event) => {
        setChartOptions((prevState) => ({
            ...prevState,
            backgroundColor: color.hex,
        }));
    };

    const handleFontColorChange = (color, event) => {
        const newChartOption = { ...chartOptions };
        newChartOption.color = color.hex;
        newChartOption.titleTextStyle = {
            color: color.hex, // Change the title font color
        };

        newChartOption.legend = {
            ...chartOptions.legend,
            textStyle: {
                color: color.hex, // Change the legend font color
            },
        };

        newChartOption.hAxis = {
            ...chartOptions.hAxis,
            titleTextStyle: {
                color: color.hex, // Change horizontal axis title color
            },
            textStyle: {
                color: color.hex, // Change horizontal axis labels color
            },
        };

        newChartOption.vAxis = {
            ...chartOptions.vAxis,
            titleTextStyle: {
                color: color.hex, // Change vertical axis title color
            },
            textStyle: {
                color: color.hex, // Change vertical axis labels color
            },
        };

        // newChartOption.pieSliceTextStyle = {
        //     color: color.hex, // Change the text color on the pie slices
        // };

        setChartOptions(newChartOption);
    };

    const handleXAxisLegendChange = (value) => {
        setHAxis(value);

        setChartOptions((prevState) => ({
            ...prevState,
            hAxis: { ...prevState.hAxis, title: value },
        }));
    };

    const handleYAxisLegendChange = (value) => {
        setVAxis(value);

        setChartOptions((prevState) => ({
            ...prevState,
            vAxis: { ...prevState.vAxis, title: value },
        }));
    };

    if (enableEditing) {
        return (
            <div className='w-full h-full'>
                <ReflexContainer orientation='vertical'>
                    <ReflexElement
                        className='left-pane mr-0.5'
                        minSize={350}
                        size={500}
                        maxSize={700}
                    >
                        <div className='pane-content w-full h-full bg-transparent'>
                            <div className='w-full h-full flex flex-col justify-start items-start'>
                                <div className='w-full flex items-center justify-between border-b px-2 h-[60px]'>
                                    <div className='font-semibold text-texts-secondary300 '>
                                        <FontAwesomeIcon
                                            icon={faGear}
                                            color={iconColor}
                                            className='mr-2'
                                        />
                                        <span>View Controls</span>
                                    </div>
                                </div>

                                <div className='w-full h-full'>
                                    <>
                                        <Title
                                            title={'Colors'}
                                            icon={faPalette}
                                        />
                                        <div className='w-full flex-col justify-around items-center p-4 mb-4'>
                                            <div className='w-full flex-col items-center mb-4'>
                                                <div className='mb-2'>
                                                    <label className='font-semibold text-texts-secondary300'>
                                                        Background Color
                                                    </label>
                                                </div>

                                                <TwitterPicker
                                                    onChangeComplete={
                                                        handleBackgroundColorChange
                                                    }
                                                    triangle='hide'
                                                    colors={backgroundColors}
                                                    width='100%'
                                                    color={
                                                        chartOptions.backgroundColor
                                                    }
                                                />
                                            </div>
                                            <div className='w-full flex-col items-center mb-4'>
                                                <div className='mb-2'>
                                                    <label>
                                                        <div className='font-semibold text-texts-secondary300'>
                                                            Font Color
                                                        </div>
                                                    </label>
                                                </div>

                                                <TwitterPicker
                                                    onChangeComplete={
                                                        handleFontColorChange
                                                    }
                                                    triangle='hide'
                                                    colors={fontColors}
                                                    width='100%'
                                                    color={chartOptions.color}
                                                />
                                            </div>
                                        </div>
                                    </>

                                    {isValidAxisChart && (
                                        <>
                                            <Title
                                                title={'Axis Labels'}
                                                icon={faT}
                                            />

                                            <div className='w-full flex-col justify-around items-center p-4 mb-4'>
                                                <div className='w-full flex-col items-center mb-4'>
                                                    <div className='mb-2'>
                                                        <label className='font-semibold text-texts-secondary300'>
                                                            X Axis
                                                        </label>
                                                    </div>

                                                    <Input
                                                        className={
                                                            'w-full text-texts-secondary300 truncate'
                                                        }
                                                        value={hAxis}
                                                        type={'string'}
                                                        onChange={(e) =>
                                                            handleXAxisLegendChange(
                                                                e.target.value
                                                            )
                                                        }
                                                        maxLength={
                                                            maxLegendLength
                                                        }
                                                    />
                                                </div>

                                                <div className='w-full flex-col items-center mb-4'>
                                                    <div className='mb-2'>
                                                        <label className='font-semibold text-texts-secondary300'>
                                                            Y Axis
                                                        </label>
                                                    </div>

                                                    <Input
                                                        className={
                                                            'w-full text-texts-secondary300 truncate'
                                                        }
                                                        value={vAxis}
                                                        type={'string'}
                                                        onChange={(e) =>
                                                            handleYAxisLegendChange(
                                                                e.target.value
                                                            )
                                                        }
                                                        maxLength={
                                                            maxLegendLength
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </ReflexElement>

                    <ReflexSplitter
                        style={{
                            border: 0,
                            background: 'white',
                            width: '4px',
                            zIndex: 10,
                            height: '100%',
                        }}
                    />

                    <ReflexElement className='right-pane ml-0.5'>
                        <div className='pane-content w-full h-full bg-transparent'>
                            {/* Data View Section */}
                            <ChartWithControls
                                selectedChart={selectedChart}
                                setSelectedChart={setSelectedChart}
                                chartData={chartData}
                                chartOptions={chartOptions}
                                chartName={chartName}
                                showChartInFullScreen={showChartInFullScreen}
                                closeDropdownSignal={closeDropdownSignal}
                                toggleChartViewInFullScreen={
                                    toggleChartViewInFullScreen
                                }
                                enableEditing={enableEditing}
                                setEnableEditing={setEnableEditing}
                            />
                        </div>
                    </ReflexElement>
                </ReflexContainer>
            </div>
        );
    } else return <></>;
};
