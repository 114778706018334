import {
    faAdd,
    faBriefcase,
    faClose,
    faCopy,
    faExternalLink,
    faPencil,
    faShoppingCart,
    faTrash,
    faUser,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useRef, useState } from 'react';
import { notification } from 'antd';
import { Button, ConfirmModal, Input } from '../reusableComponents';
import { getUrl } from '../pdfDisplay/utils';
import axios from 'axios';
import { isEmailValid, isValidContactNumber } from '../../utils';

const maxCompanyNameLength = 40;
const maxCopyRightTextLength = 40;

function isValidCompanyName(name) {
    const regex = /^[A-Za-z0-9 .,'&-]+$/;
    const minLength = 2;

    return (
        regex.test(name) &&
        name.length >= minLength &&
        name.length <= maxCompanyNameLength
    );
}
const isValidCopyRightText = (text) => {
    // const regex =
    //     /^(©|Copyright)\s*(\d{4})(-\d{4})?\s*([A-Za-z0-9 .,'"-]{0,40})?$/;
    // const minLength = 5;

    // return (
    //     regex.test(text) &&
    //     text.length >= minLength &&
    //     text.length <= maxCopyRightTextLength
    // );
    return true;
};

export const validateVendorsData = (vendorData) => {
    const result = { isValid: true, error: '' };
    if (!vendorData.company_name) {
        result.isValid = false;
        result.error = 'Company Name is required in SUPPORT SETTINGS';
        return result;
    }
    if (!isValidCompanyName(vendorData.company_name)) {
        result.isValid = false;
        result.error = 'Please provide valid Company Name in SUPPORT SETTINGS';
        return result;
    }

    if (!vendorData.contact_email) {
        result.isValid = false;
        result.error = 'Contact Email is required in SUPPORT SETTINGS';
        return result;
    }
    if (!isEmailValid(vendorData.contact_email)) {
        result.isValid = false;
        result.error = 'Please provide valid Contact Email in SUPPORT SETTINGS';
        return result;
    }

    if (!vendorData.contact_number) {
        result.isValid = false;
        result.error = 'Contact Number is required in SUPPORT SETTINGS';
        return result;
    }
    if (!isValidContactNumber(vendorData.contact_number)) {
        result.isValid = false;
        result.error =
            'Please provide valid Contact Number in SUPPORT SETTINGS';
        return result;
    }

    if (vendorData.copy_right_text) {
        if (!isValidCopyRightText(vendorData.copy_right_text)) {
            result.isValid = false;
            result.error =
                'Please provide valid Copy Rights Text in SUPPORT SETTINGS';
            return result;
        }
    }

    return result;
};

// Handle file selection
const handleFileChange = (event, accountDetails, setAccountDetails) => {
    const file = event.target.files[0];
    if (file) {
        handleVendorFieldUpdate(
            'logo',
            file,
            accountDetails,
            setAccountDetails
        );
    }
};

// Trigger the hidden file input when the custom button is clicked
const handleButtonClick = (fileInputRef) => {
    fileInputRef.current.click(); // Programmatically click the hidden file input
};

const LogoComponent = ({
    isSmallScreen,
    accountDetails,
    setAccountDetails,
}) => {
    const fileInputRef = useRef(null); // Reference to the hidden file input
    const logo = accountDetails.vendorDetails
        ? accountDetails.vendorDetails.logo
        : null;

    return (
        <div className='flex items-center'>
            {/* Show the existing logo*/}
            <div
                className='relative rounded-xl border flex items-center justify-center bg-backgrounds-white p-2'
                style={{ width: '100px', height: '100px' }}
            >
                {logo ? (
                    <img
                        src={URL.createObjectURL(new Blob([logo]))}
                        alt='Current Logo'
                        className='rounded-xl'
                    />
                ) : (
                    <FontAwesomeIcon
                        icon={faBriefcase}
                        size='3x'
                        className='text-texts-secondary300 rounded-xl p-6 bg-backgrounds-white'
                    />
                )}

                {/* Hidden file input */}
                <input
                    type='file'
                    accept='image/*'
                    ref={fileInputRef} // Use the ref to access the input
                    style={{ display: 'none' }} // Hide the input element
                    onChange={(event) =>
                        handleFileChange(
                            event,
                            accountDetails,
                            setAccountDetails
                        )
                    } // Handle file selection
                />
            </div>

            <div className='flex flex-col justify-between items-center px-4'>
                <Button
                    className={`my-2 ${isSmallScreen ? '' : 'w-28'}`}
                    hoverText={logo ? 'Change this logo' : 'Add new logo'}
                    text={isSmallScreen ? null : logo ? 'Edit' : 'Add'}
                    icon={logo ? faPencil : faAdd}
                    type={'success'}
                    onClick={() => handleButtonClick(fileInputRef)}
                />

                {logo && (
                    <Button
                        className={`my-2  ${isSmallScreen ? '' : 'w-28'}`}
                        hoverText={'Remove this logo'}
                        text={isSmallScreen ? null : 'Remove'}
                        icon={faClose}
                        type={'danger'}
                        onClick={() =>
                            handleVendorFieldUpdate(
                                'logo',
                                null,
                                accountDetails,
                                setAccountDetails
                            )
                        }
                    />
                )}
            </div>
        </div>
    );
};

const handleCopy = async (textData, openNotification) => {
    try {
        // Ensure permission query is executed before copying
        await navigator.permissions.query({ name: 'clipboard-write' });
        await navigator.clipboard.writeText(textData);

        const notificationDuration = 1.5; // sec
        const placement = 'top';
        const notification = 'copied!';

        openNotification(placement, notification, null, notificationDuration);
    } catch (err) {
        alert('Failed to copy text: ', err);
    }
};

const handleUpgrade = (accountDetails, setAccountDetails) => {
    const vendorDetails = {
        logo: null,
        company_name: null,
        contact_email: null,
        contact_number: null,
        company_domain: null,
        vendor_id: null,
        copy_right_text: null,
    };
    const accountData = { ...accountDetails, vendorDetails: vendorDetails };
    setAccountDetails(accountData);
};

const handleDelete = async (user, accountDetails, setAccountDetails) => {
    try {
        const dynamicUrl = getUrl(
            `http://${process.env.REACT_APP_FAST_API_HOST}:${process.env.REACT_APP_FAST_API_PORT}/customer_chat_routes/remove-vendor`
        );
        const encodedToken = encodeURIComponent(user.token);

        // Create FormData to send the token
        const formData = new FormData();
        formData.append('token', user.token);

        // Send the DELETE request with the FormData as the second argument
        await axios.delete(dynamicUrl, {
            data: formData,
            headers: {
                Authorization: encodedToken,
            },
        });

        const accountData = { ...accountDetails };
        delete accountData['vendorDetails'];
        setAccountDetails(accountData);
    } catch (error) {
        console.error('Error removing vendor:', error); // Add error logging for debugging
    }
};

const handleVendorFieldUpdate = (
    fieldName,
    value,
    accountDetails,
    setAccountDetails
) => {
    const accountData = { ...accountDetails };
    accountData.vendorDetails[fieldName] = value;
    setAccountDetails(accountData);
};

export const AccountSettingsComponent = ({
    user,
    accountDetails,
    setAccountDetails,
    SettingSections,
    isSmallScreen,
}) => {
    const { personalDetails, vendorDetails } = accountDetails;
    const [viewDeleteConfirmModal, setViewDeleteConfirmModal] = useState(false);
    const [encodedUrl, setEncodedUrl] = useState(null);
    const [api, contextHolder] = notification.useNotification();

    const openNotification = (placement, text, description, duration) => {
        api.success({
            message: text,
            description: description,
            placement,
            duration,
        });
    };

    useEffect(() => {
        if (
            accountDetails.vendorDetails &&
            accountDetails.vendorDetails.vendor_id
        ) {
            // const encodedId = compressAndEncodeJWT(
            //     accountDetails.vendorDetails.vendor_id
            // );

            const encodedId = accountDetails.vendorDetails.vendor_id;
            const url = `${process.env.REACT_APP_PRODUCT_SUPPORT_DOMAIN}/product-support/${encodedId}`;
            setEncodedUrl(url);
        } else {
            setEncodedUrl(null);
        }

        return () => {};
    }, [accountDetails]);

    return (
        <div className='mb-6'>
            {contextHolder}
            {viewDeleteConfirmModal && (
                <ConfirmModal
                    modalText={
                        'Delete this product support account and remove all file and product mappings related to this account?'
                    }
                    onCancel={() => setViewDeleteConfirmModal(false)}
                    onConfirm={() => {
                        handleDelete(user, accountDetails, setAccountDetails);
                        setViewDeleteConfirmModal(false);
                    }}
                />
            )}

            <div
                className={`flex items-center justify-between font-semibold mb-6 ${
                    !vendorDetails ? 'py-2' : 'py-3'
                }  px-4 text-texts-secondary300 uppercase bg-backgrounds-primary100 truncate`}
            >
                <div className='flex items-center'>
                    <FontAwesomeIcon icon={faUser} className='mr-2' />
                    <div className='font-semibold text-texts-secondary300 uppercase truncate'>
                        {SettingSections.ACCOUNT}
                    </div>
                </div>

                {(!vendorDetails ||
                    (vendorDetails &&
                        Object.keys(vendorDetails).length === 0)) && (
                    <Button
                        className={`ml-2`}
                        hoverText={'Upgrade to product support account'}
                        text={'Upgrade'}
                        icon={faShoppingCart}
                        type={'success'}
                        onClick={() =>
                            handleUpgrade(accountDetails, setAccountDetails)
                        }
                    />
                )}
            </div>

            <div className='px-6 relative'>
                <div className='flex items-center mb-6'>
                    <div className='w-1/4 font-semibold text-texts-secondary300'>
                        <label htmlFor='userName'>Name</label>
                    </div>

                    <div className='w-full md:w-1/4'>
                        <div className='relative'>
                            <Input
                                type={'text'}
                                value={
                                    personalDetails && personalDetails.username
                                        ? personalDetails.username
                                        : ''
                                }
                                disabled={true}
                            />
                        </div>
                    </div>
                </div>

                <div className='flex items-center mb-6'>
                    <div className='w-1/4 font-semibold text-texts-secondary300'>
                        <label htmlFor='email'>Email</label>
                    </div>

                    <div className='w-full md:w-1/4'>
                        <div className='relative'>
                            <Input
                                type={'text'}
                                value={
                                    personalDetails && personalDetails.email
                                        ? personalDetails.email
                                        : ''
                                }
                                disabled={true}
                            />
                        </div>
                    </div>
                </div>

                <div className='flex items-center mb-8'>
                    <div
                        className={`w-1/4 font-semibold text-texts-secondary300 ${
                            isSmallScreen ? 'pr-2' : ''
                        }`}
                    >
                        <label htmlFor='currency'>Currency</label>
                    </div>

                    <div className='w-full md:w-1/4'>
                        <div className='relative'>
                            <Input
                                type={'text'}
                                value={
                                    personalDetails && personalDetails.currency
                                        ? personalDetails.currency
                                        : ''
                                }
                                disabled={true}
                            />
                        </div>
                    </div>
                </div>

                {/* Vendor Settings Section*/}
                {vendorDetails && Object.keys(vendorDetails).length !== 0 && (
                    <div
                        className={`${
                            isSmallScreen ? '' : 'border rounded-xl'
                        } mb-6`}
                    >
                        <div
                            className={`flex items-center justify-between font-semibold mb-6 py-2 px-4 text-texts-secondary300 uppercase bg-backgrounds-primary100 truncate ${
                                isSmallScreen ? '' : 'rounded-t-xl'
                            }`}
                        >
                            <div className='flex items-center'>
                                <FontAwesomeIcon
                                    icon={faShoppingCart}
                                    className='mr-2'
                                />
                                <div className='font-semibold text-texts-secondary300 uppercase truncate'>
                                    Support Settings
                                </div>
                            </div>

                            <div>
                                <Button
                                    className={`ml-2`}
                                    hoverText={
                                        'Delete this Product support account'
                                    }
                                    text={'Delete'}
                                    icon={faTrash}
                                    type={'danger'}
                                    onClick={() =>
                                        setViewDeleteConfirmModal(true)
                                    }
                                />
                            </div>
                        </div>

                        <div className={isSmallScreen ? '' : 'px-6'}>
                            {/* Logo */}
                            <div className='flex items-center mb-6'>
                                <div className='w-2/4 font-semibold text-texts-secondary300'>
                                    <label htmlFor='companyName'>
                                        Company Logo
                                    </label>
                                </div>

                                <div className='w-full md:w-1/4'>
                                    <div className='relative'>
                                        <LogoComponent
                                            isSmallScreen={isSmallScreen}
                                            accountDetails={accountDetails}
                                            setAccountDetails={
                                                setAccountDetails
                                            }
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className='flex items-center mb-6'>
                                <div className='w-2/4 font-semibold text-texts-secondary300'>
                                    <label htmlFor='companyName'>
                                        Company Name
                                    </label>
                                </div>

                                <div className='w-full md:w-1/4'>
                                    <div className='relative'>
                                        <Input
                                            type={'text'}
                                            value={
                                                vendorDetails &&
                                                vendorDetails.company_name
                                                    ? vendorDetails.company_name
                                                    : ''
                                            }
                                            onChange={(e) =>
                                                handleVendorFieldUpdate(
                                                    'company_name',
                                                    e.target.value,
                                                    accountDetails,
                                                    setAccountDetails
                                                )
                                            }
                                            placeholder={'Required'}
                                            maxLength={maxCompanyNameLength}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className='flex items-center mb-6'>
                                <div className='w-2/4 font-semibold text-texts-secondary300'>
                                    <label htmlFor='contactEmail'>
                                        Contact Email
                                    </label>
                                </div>

                                <div className='w-full md:w-1/4'>
                                    <div className='relative'>
                                        <Input
                                            type={'text'}
                                            value={
                                                vendorDetails &&
                                                vendorDetails.contact_email
                                                    ? vendorDetails.contact_email
                                                    : ''
                                            }
                                            onChange={(e) =>
                                                handleVendorFieldUpdate(
                                                    'contact_email',
                                                    e.target.value,
                                                    accountDetails,
                                                    setAccountDetails
                                                )
                                            }
                                            placeholder={'Required'}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className='flex items-center mb-6'>
                                <div className='w-2/4 font-semibold text-texts-secondary300'>
                                    <label htmlFor='contactNumber'>
                                        Contact Number
                                    </label>
                                </div>

                                <div className='w-full md:w-1/4'>
                                    <div className='relative'>
                                        <Input
                                            type={'text'}
                                            value={
                                                vendorDetails &&
                                                vendorDetails.contact_number
                                                    ? vendorDetails.contact_number
                                                    : ''
                                            }
                                            onChange={(e) =>
                                                handleVendorFieldUpdate(
                                                    'contact_number',
                                                    e.target.value,
                                                    accountDetails,
                                                    setAccountDetails
                                                )
                                            }
                                            placeholder={'Required'}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className='flex items-center mb-6'>
                                <div className='w-2/4 font-semibold text-texts-secondary300'>
                                    <label htmlFor='companyDomain'>
                                        Company Domain
                                    </label>
                                </div>

                                <div className='w-full md:w-1/4'>
                                    <div className='relative'>
                                        <Input
                                            type={'text'}
                                            value={
                                                vendorDetails &&
                                                vendorDetails.company_domain
                                                    ? vendorDetails.company_domain
                                                    : ''
                                            }
                                            onChange={(e) =>
                                                handleVendorFieldUpdate(
                                                    'company_domain',
                                                    e.target.value,
                                                    accountDetails,
                                                    setAccountDetails
                                                )
                                            }
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className='flex items-center mb-6'>
                                <div className='w-2/4 font-semibold text-texts-secondary300'>
                                    <label htmlFor='copyRightText'>
                                        Copy Rights Text
                                    </label>
                                </div>

                                <div className='w-full md:w-1/4'>
                                    <div className='relative'>
                                        <Input
                                            type={'text'}
                                            value={
                                                vendorDetails &&
                                                vendorDetails.copy_right_text
                                                    ? vendorDetails.copy_right_text
                                                    : ''
                                            }
                                            onChange={(e) =>
                                                handleVendorFieldUpdate(
                                                    'copy_right_text',
                                                    e.target.value,
                                                    accountDetails,
                                                    setAccountDetails
                                                )
                                            }
                                            maxLength={maxCopyRightTextLength}
                                        />
                                    </div>
                                </div>
                            </div>

                            {vendorDetails && vendorDetails.vendor_id && (
                                <div className='flex items-center mb-6'>
                                    <div className='w-2/4 font-semibold text-texts-secondary300'>
                                        <label htmlFor='copyRightText'>
                                            Support Link
                                        </label>
                                    </div>

                                    <div className='w-full md:w-1/2'>
                                        <div className='relative flex items-center'>
                                            <div className='flex items-center relative w-1/2'>
                                                <Input
                                                    type={'text'}
                                                    value={
                                                        process.env
                                                            .REACT_APP_PRODUCT_SUPPORT_DOMAIN
                                                            ? encodedUrl
                                                            : ''
                                                    }
                                                    disabled={true}
                                                />
                                                <div className='absolute right-2'>
                                                    <Button
                                                        className={`ml-2`}
                                                        hoverText={'Copy link'}
                                                        text={null}
                                                        icon={faCopy}
                                                        type={'success'}
                                                        onClick={async () =>
                                                            await handleCopy(
                                                                encodedUrl,
                                                                openNotification
                                                            )
                                                        }
                                                    />
                                                </div>
                                            </div>

                                            <Button
                                                className={`ml-3`}
                                                hoverText={'Open link'}
                                                text={'Open'}
                                                icon={faExternalLink}
                                                type={'success'}
                                                onClick={() =>
                                                    window.open(
                                                        encodedUrl,
                                                        '_blank'
                                                    )
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};
