import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCircleXmark,
    faExclamationTriangle,
    faFileCirclePlus,
} from '@fortawesome/free-solid-svg-icons';
import { Button, Input, LoadingView } from '../reusableComponents';
import { hasDuplicateNames, isValidName } from '../../utils';
import { getUrl } from '../pdfDisplay/utils';

const AddLayoutFile = ({
    addNewMode,
    template,
    layoutsList,
    setSelectedLayout,
    setSelectedTemplate,
    getLayoutsList,
}) => {
    const buttonRef = useRef(null);

    const user = JSON.parse(localStorage.getItem('current'));
    const [layoutName, setLayoutName] = useState('');
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [loadingText, setLoadingText] = useState('');

    useEffect(() => {
        if (layoutsList.length === 0 && template && template.filename) {
            (async () => {
                setLoadingText('Loading Layout List...');
                setIsLoading(true);
                await getLayoutsList(template.filename);
                setIsLoading(false);
                setLoadingText('');
            })();
        }

        return () => {};
    }, []);

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            buttonRef.current.click(); // Programmatically click the button
        }
    };

    // Validate file types
    const isValid = async (layoutsList, layoutName) => {
        if (!layoutName) {
            setError('Please enter the new layout name');
            return false;
        }

        const isValidNameData = isValidName(layoutName, true);
        if (!isValidNameData.isValid) {
            setError(isValidNameData.error);
            return false;
        }

        if (
            hasDuplicateNames([...layoutsList, { name: `${layoutName}.json` }])
        ) {
            setError(`Layout with name "${layoutName}" already exist!`);
            return false;
        }

        return true;
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setError('');

        if (await isValid(layoutsList, layoutName)) {
            try {
                setIsLoading(true);
                setLoadingText('Adding new layout...');

                const dynamicUrl = getUrl(
                    `http://${process.env.REACT_APP_NODE_SERVER_HOST}:${process.env.REACT_APP_NODE_SERVER_PORT}`
                );

                const data = {
                    email: user.email,
                    templateName: template.filename,
                    layoutName: layoutName,
                };

                const encodedToken = encodeURIComponent(user.token);
                const response = await axios.post(
                    `${dynamicUrl}/createLayout`,
                    data,
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: encodedToken,
                        },
                    }
                );

                if (response) {
                    setSelectedLayout(null);
                    console.log(response.data.message);

                    setLoadingText('Loading Layout List...');
                    await getLayoutsList(template.filename);

                    setSelectedTemplate(template);
                    setSelectedLayout({ name: `${layoutName}.json` });
                }

                addNewMode(false);
                setIsLoading(false);
                setLoadingText('');
            } catch (error) {
                addNewMode(false);
                setIsLoading(false);
                setLoadingText('');
                console.error('Error creating layout:', error);
            }
        }
    };

    return (
        <div className='w-[60vw] min-w-[500px] flex-col px-6 py-6 rounded-xl bg-backgrounds-slate text-texts-secondary300'>
            {isLoading ? (
                <div className='w-full h-32 flex justify-center items-center w-full h-full py-6 my-1.5'>
                    <LoadingView loadingText={loadingText} inLine={false} />
                </div>
            ) : (
                <form onSubmit={handleSubmit}>
                    <div className='mb-5'>
                        <Input
                            className=''
                            type='text'
                            placeholder='New layout name'
                            value={layoutName}
                            maxLength={60}
                            onChange={(e) => {
                                setError('');
                                setLayoutName(e.target.value);
                            }}
                            onKeyDown={handleKeyDown}
                            autoFocus
                        />
                    </div>

                    {error && (
                        <div className='flex justify-center items-center text-buttons-alert400 mt-3'>
                            <FontAwesomeIcon
                                icon={faExclamationTriangle}
                                className='mr-2'
                            />
                            {error}
                        </div>
                    )}

                    <div className='flex items-center justify-center mt-4'>
                        <div className='flex items-center justify-between w-full'>
                            <Button
                                type='danger'
                                icon={faCircleXmark}
                                className='w-1/3'
                                text={'Cancel'}
                                tile={'Cancel and close the modal'}
                                onClick={() => {
                                    addNewMode(false);
                                }}
                            />
                            <button
                                ref={buttonRef}
                                className={`flex items-center justify-center rounded-full py-1.5 px-2.5 rounded-lg order bg-backgrounds-white shadow-inner border font-semibold w-1/3 ${
                                    error || !layoutName
                                        ? 'text-texts-secondary300 cursor-not-allowed'
                                        : `hover:bg-backgrounds-primary100 hover:border-backgrounds-primary200 text-backgrounds-primary400`
                                }`}
                                type='submit'
                                title='Add new layout file to collection'
                                disabled={error || !layoutName}
                            >
                                <FontAwesomeIcon
                                    icon={faFileCirclePlus}
                                    className='mr-2'
                                />
                                Add
                            </button>
                        </div>
                    </div>
                </form>
            )}
        </div>
    );
};

export default AddLayoutFile;
