import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faBars,
    faComments,
    faCreditCard,
    faFileExport,
    faGear,
    faHeadset,
    faListDots,
    faPieChart,
    faTableCells,
} from '@fortawesome/free-solid-svg-icons';
import { Button } from './reusableComponents';
import { appLogo, appName } from '../Constants';
import Sidebar from './sidebar.jsx';
import { SupportSideBar } from './support/supportSidebar.jsx';
import { SettingsSideBar } from './settings/settingsSidebar.jsx';
import { useGlobalCredits } from './GlobalStateContext';
import axios from 'axios';
import { NotificationBell } from './notifications/notificationBell.jsx';
import ReportLayoutsSideView from './reportLayouts/reportLayoutsSideView.jsx';
import { getUrl } from './pdfDisplay/utils.js';
import { message } from 'antd';
import { SUBSCRIPTION_PLANS } from '../utils/planUtils';
import { OrganizationAccessOptions } from '../constants/userAccessOptions.js';
//import Notifications from './notifications.jsx';
const Navbar = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const path = location.pathname;
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const { credits, setCredits } = useGlobalCredits();
    const { transactionMode, setTransactionMode } = useGlobalCredits();
    const user = JSON.parse(localStorage.getItem('current'));
    const authToken = encodeURIComponent(user.token);
    const [planName, setPlanName] = useState(SUBSCRIPTION_PLANS.FREE);
    const [isLoadingPlan, setIsLoadingPlan] = useState(true);
    const [canAccessBilling, setCanAccessBilling] = useState(false);

    useEffect(() => {
        const fetchUserDetails = async () => {
            setIsLoadingPlan(true);

            // Fetch current plan
            try {
                const planResponse = await axios.get(
                    getUrl(
                        `http://${process.env.REACT_APP_FAST_API_HOST}:${
                            process.env.REACT_APP_FAST_API_PORT
                        }/subscription_routes/current-plan/${encodeURIComponent(
                            user.email
                        )}`
                    ),
                    {
                        headers: {
                            Authorization: authToken,
                            'Content-Type': 'application/json',
                        },
                    }
                );

                if (planResponse.data && planResponse.data.name) {
                    setPlanName(planResponse.data.name);
                } else {
                    setPlanName(SUBSCRIPTION_PLANS.FREE);
                }
            } catch (planError) {
                console.error('Error fetching subscription plan');
                setPlanName(SUBSCRIPTION_PLANS.FREE);
                message.error(
                    'Failed to fetch subscription plan. Defaulting to FREE plan.'
                );
            }

            // Fetch org details
            try {
                const orgResponse = await axios.get(
                    getUrl(
                        `http://${process.env.REACT_APP_NODE_SERVER_HOST}:${process.env.REACT_APP_NODE_SERVER_PORT}/organization/details`
                    ),
                    {
                        headers: {
                            Authorization: authToken,
                            'Content-Type': 'application/json',
                        },
                    }
                );

                if (orgResponse.data?.details) {
                    // Allow all org members to access billing/subscription info
                    setCanAccessBilling(true);

                    // Store the user role for use in subscription dashboard
                    if (orgResponse.data.details.userRole) {
                        localStorage.setItem(
                            'userRole',
                            orgResponse.data.details.userRole
                        );
                    }
                } else {
                    setCanAccessBilling(true);
                }
            } catch (orgError) {
                console.error('Error fetching organization details');
                setCanAccessBilling(true);
                console.log('User is not part of an organization');
            }

            setIsLoadingPlan(false);
        };

        fetchUserDetails();
    }, []);

    //responsiveness related
    const [isSmallScreen, setIsSmallScreen] = useState(
        window.innerWidth <= 1024
    );

    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth <= 1024);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        (async () => {
            const headers = {
                Authorization: authToken,
                'Content-Type': 'application/json',
            };

            // Make GET request to fetch user credits
            await axios
                .get(
                    getUrl(
                        `http://${process.env.REACT_APP_NODE_SERVER_HOST}:${process.env.REACT_APP_NODE_SERVER_PORT}/getCredits`
                    ),
                    {
                        headers: headers,
                    }
                )
                .then((response) => {
                    console.log('*****************************************');
                    console.log(response.data.credits);
                    setCredits(response.data.credits);
                })
                .catch((error) => {
                    console.error('Error fetching user credits:', error);
                });
        })();
    }, []);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const closeMenu = () => {
        setIsMenuOpen(false);
    };

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    const closeSidebar = () => {
        setIsSidebarOpen(false);
    };

    return (
        <div className='rounded-xl bg-backgrounds-white border flex justify-between items-center h-14 py-0 px-3 select-none'>
            <div className='lg:hidden relative'>
                <button className='ml-2' onClick={toggleSidebar}>
                    <FontAwesomeIcon
                        icon={faListDots}
                        className='text-2xl text-backgrounds-primary200'
                    />
                </button>
            </div>
            <div className='lg:hidden relative flex-1 flex justify-center'>
                <img
                    className='w-10 h-10 '
                    src={appLogo}
                    alt={appName}
                    draggable='false'
                />
            </div>
            <div className='lg:flex'>
                <div className='hidden lg:flex justify-center items-center'>
                    <button
                        className={`p-2.5 border border-transparent mr-2 ${
                            path === '/dashboard'
                                ? 'bg-backgrounds-primary100 text-texts-tertiary shadow-inner border border-backgrounds-primary200'
                                : 'text-texts-secondary300 bg-backgrounds-white'
                        } font-semibold rounded-xl hover:bg-backgrounds-primary100 hover:border-backgrounds-primary200`}
                        title='Switch to dashboard section'
                        onClick={() => {
                            if (transactionMode) {
                                setTransactionMode(!transactionMode);
                            }
                            if (path !== '/dashboard') {
                                navigate('/dashboard');
                            }
                        }}
                    >
                        <FontAwesomeIcon
                            icon={faTableCells}
                            className='pr-1.5'
                        />
                        Dashboard
                    </button>

                    <button
                        className={`p-2.5 border border-transparent mr-2 ${
                            path === '/chats'
                                ? 'bg-backgrounds-primary100 text-texts-tertiary shadow-inner border border-backgrounds-primary200'
                                : 'text-texts-secondary300 bg-backgrounds-white'
                        } font-semibold rounded-xl hover:bg-backgrounds-primary100 hover:border-backgrounds-primary200`}
                        title='Switch to Q&A section'
                        onClick={() => {
                            if (transactionMode) {
                                setTransactionMode(!transactionMode);
                            }
                            if (path !== '/chats') {
                                navigate('/chats');
                            }
                        }}
                    >
                        <FontAwesomeIcon icon={faComments} className='pr-1.5' />
                        Chat
                    </button>

                    <button
                        className={`p-2.5 border border-transparent mr-2 ${
                            path === '/extraction'
                                ? 'bg-backgrounds-primary100 text-texts-tertiary shadow-inner border border-backgrounds-primary200'
                                : 'text-texts-secondary300 bg-backgrounds-white'
                        } font-semibold rounded-xl hover:bg-backgrounds-primary100 hover:border-backgrounds-primary200`}
                        title='Switch to Extraction section'
                        onClick={() => {
                            if (transactionMode) {
                                setTransactionMode(!transactionMode);
                            }
                            if (path !== '/extraction') {
                                navigate('/extraction');
                            }
                        }}
                    >
                        <FontAwesomeIcon
                            icon={faFileExport}
                            className='pr-1.5'
                        />
                        Extraction
                    </button>

                    <button
                        className={`p-2.5 border border-transparent mr-2 ${
                            path === '/templates-and-layouts'
                                ? 'bg-backgrounds-primary100 text-texts-tertiary shadow-inner border border-backgrounds-primary200'
                                : 'text-texts-secondary300 bg-backgrounds-white'
                        } font-semibold rounded-xl hover:bg-backgrounds-primary100 hover:border-backgrounds-primary200`}
                        title='Switch to Report Designer section'
                        onClick={() => {
                            if (transactionMode) {
                                setTransactionMode(!transactionMode);
                            }
                            if (path !== '/templates-and-layouts') {
                                navigate('/templates-and-layouts');
                            }
                        }}
                    >
                        <FontAwesomeIcon icon={faPieChart} className='pr-1.5' />
                        {/* Report Designer */}
                        Templates/Layouts
                    </button>

                    <button
                        className={`p-2.5 border border-transparent mr-2 ${
                            path === '/support'
                                ? 'bg-backgrounds-primary100 text-texts-tertiary shadow-inner border border-backgrounds-primary200'
                                : 'text-texts-secondary300 bg-backgrounds-white'
                        } font-semibold rounded-xl hover:bg-backgrounds-primary100 hover:border-backgrounds-primary200`}
                        title='Switch to Support section'
                        onClick={() => {
                            if (transactionMode) {
                                setTransactionMode(!transactionMode);
                            }
                            if (path !== '/support') {
                                navigate('/support');
                            }
                        }}
                    >
                        <FontAwesomeIcon icon={faHeadset} className='pr-1.5' />
                        Support
                    </button>
                </div>
            </div>

            <div className='lg:hidden relative'>
                <div className='flex items-center'>
                    {isSmallScreen && (
                        <NotificationBell
                            viewNotifications={props.viewNotifications}
                            setViewNotifications={props.setViewNotifications}
                            newNotification={props.newNotification}
                            setNewNotification={props.setNewNotification}
                            messages={props.messages}
                            socket={props.socket}
                        />
                    )}

                    {/* {viewPort === ViewPort.MOBILE ? (
                        <NotificationBell
                            showNotifications={showNotifications}
                            setShowNotifications={setShowNotifications}
                            newNotification={props.newNotification}
                            setNewNotification={props.setNewNotification}
                            messages={props.messages}
                            viewPort={ViewPort.MOBILE}
                        />
                    ) : (
                        <></>
                    )} */}

                    <button className='ml-auto' onClick={toggleMenu}>
                        <FontAwesomeIcon
                            icon={faBars}
                            className='text-2xl text-backgrounds-primary200'
                        />
                    </button>
                </div>

                {isMenuOpen && (
                    <div
                        className='fixed inset-0 bg-backgrounds-black bg-opacity-75 z-50'
                        onClick={closeMenu}
                    >
                        <div className='fixed top-0 right-0 bottom-0 w-64 bg-backgrounds-white shadow-lg z-50 overflow-y-auto'>
                            <div className='p-4 bg-backgrounds-primary100 font-semibold text-lg text-texts-secondary flex flex-row'>
                                ACTIONS
                                {canAccessBilling && (
                                    <Button
                                        className={`h-8 mr-1 ml-10 px-2 flex-end`}
                                        hoverText={
                                            !transactionMode
                                                ? 'Billing & Subscriptions '
                                                : 'Close Billing & Subscriptions'
                                        }
                                        text={
                                            isLoadingPlan
                                                ? 'Loading...'
                                                : planName.split('_')[0]
                                        }
                                        icon={faCreditCard}
                                        type={'success'}
                                        active={transactionMode}
                                        onClick={() => {
                                            setTransactionMode(
                                                !transactionMode
                                            );
                                        }}
                                        disabled={isLoadingPlan}
                                    />
                                )}
                            </div>
                            <button
                                className={`p-4 border-t border-backgrounds-border w-full text-left ${
                                    path === '/chats'
                                        ? 'bg-backgrounds-primary100 text-texts-tertiary'
                                        : 'text-texts-secondary300'
                                }`}
                                onClick={() => {
                                    if (transactionMode) {
                                        setTransactionMode(!transactionMode);
                                    }
                                    if (path !== '/chats') {
                                        navigate('/chats');
                                        setIsMenuOpen(false);
                                    }
                                }}
                            >
                                <FontAwesomeIcon
                                    icon={faComments}
                                    className='pr-1.5'
                                />
                                Chat
                            </button>
                            <button
                                className={`p-4 border-t border-backgrounds-border w-full text-left ${
                                    path === '/extraction'
                                        ? 'bg-backgrounds-primary100 text-texts-tertiary'
                                        : 'text-texts-secondary300'
                                }`}
                                onClick={() => {
                                    if (transactionMode) {
                                        setTransactionMode(!transactionMode);
                                    }
                                    if (path !== '/extraction') {
                                        navigate('/extraction');
                                        setIsMenuOpen(false);
                                    }
                                }}
                            >
                                <FontAwesomeIcon
                                    icon={faFileExport}
                                    className='pr-1.5'
                                />
                                Extraction
                            </button>

                            <button
                                className={`p-4 border-t border-backgrounds-border w-full text-left ${
                                    path === '/templates-and-layouts'
                                        ? 'bg-backgrounds-primary100 text-texts-tertiary'
                                        : 'text-texts-secondary300'
                                }`}
                                onClick={() => {
                                    if (transactionMode) {
                                        setTransactionMode(!transactionMode);
                                    }
                                    if (path !== '/templates-and-layouts') {
                                        navigate('/templates-and-layouts');
                                        setIsMenuOpen(false);
                                    }
                                }}
                            >
                                <FontAwesomeIcon
                                    icon={faPieChart}
                                    className='pr-1.5'
                                />
                                {/* Report Designer */}
                                Templates/Layouts
                            </button>

                            <button
                                className={`p-4 border-t border-backgrounds-border w-full text-left ${
                                    path === '/support'
                                        ? 'bg-backgrounds-primary100 text-texts-tertiary'
                                        : 'text-texts-secondary300'
                                }`}
                                onClick={() => {
                                    if (transactionMode) {
                                        setTransactionMode(!transactionMode);
                                    }
                                    if (path !== '/support') {
                                        navigate('/support');
                                        setIsMenuOpen(false);
                                    }
                                }}
                            >
                                <FontAwesomeIcon
                                    icon={faHeadset}
                                    className='pr-1.5'
                                />
                                Support
                            </button>

                            <button
                                className={`p-4 border-t border-backgrounds-border w-full text-left ${
                                    path === '/settings'
                                        ? 'bg-backgrounds-primary100 text-texts-tertiary'
                                        : ''
                                }`}
                                onClick={() => {
                                    if (transactionMode) {
                                        setTransactionMode(!transactionMode);
                                    }
                                    if (path !== '/settings') {
                                        navigate('/settings');
                                        setIsMenuOpen(false);
                                    }
                                }}
                            >
                                <FontAwesomeIcon
                                    icon={faGear}
                                    className='pr-1.5'
                                />
                                Settings
                            </button>
                        </div>
                    </div>
                )}
            </div>

            <div className='hidden lg:block'>
                <div className='ml-auto flex items-center justify-center'>
                    {/*<div className='relative'>
                        <Button
                            className={`w-9 h-9 mr-2 ${
                                isNotificationOpen
                                    ? 'bg-backgrounds-settings100 text-backgrounds-settings500 border-yellow-200'
                                    : ''
                            }`}
                            onClick={() => {
                                setIsNotificationOpen(!isNotificationOpen);
                            }}
                            hoverText={'Notifications'}
                            icon={faBell}
                            type={'warning'}
                        />
                        {notificationCount > 0 && (
                            <span className='absolute top-0 right-1 bg-buttons-alert400 text-white rounded-full px-1 py-0.3 text-xs'>
                                {notificationCount}
                            </span>
                        )}
                    </div>*/}

                    {canAccessBilling && (
                        <Button
                            className={`h-9 mr-2 px-2 text-backgrounds-primary300 `}
                            hoverText={
                                !transactionMode
                                    ? 'Billing & Subscriptions '
                                    : 'Close Billing & Subscriptions '
                            }
                            text={
                                isLoadingPlan
                                    ? 'Loading...'
                                    : planName.split('_')[0]
                            }
                            icon={faCreditCard}
                            type={'success'}
                            active={transactionMode}
                            onClick={() => {
                                setTransactionMode(!transactionMode);
                            }}
                            disabled={isLoadingPlan}
                        />
                    )}
                    {!isSmallScreen && (
                        <NotificationBell
                            viewNotifications={props.viewNotifications}
                            setViewNotifications={props.setViewNotifications}
                            newNotification={props.newNotification}
                            setNewNotification={props.setNewNotification}
                            messages={props.messages}
                            socket={props.socket}
                        />
                    )}

                    <Button
                        className={`w-9 h-9 ${
                            path === '/settings' //bg-backgrounds-primary100 text-texts-tertiary shadow-inner border border-backgrounds-primary200
                                ? 'bg-backgrounds-primary100 text-texts-primary100 shadow-inner border border-backgrounds-primary100'
                                : 'text-texts-secondary300'
                        }`}
                        onClick={() => {
                            if (transactionMode) {
                                setTransactionMode(!transactionMode);
                            }
                            if (path !== '/settings') {
                                navigate('/settings');
                            }
                        }}
                        hoverText={'Settings'}
                        icon={faGear}
                        type={'warning'}
                    />
                </div>
            </div>
            {/* Sidebar Overlay */}
            {isSidebarOpen && (
                <div
                    className='fixed inset-0 bg-backgrounds-white z-50 '
                    onClick={closeSidebar}
                ></div>
            )}
            {/* Render Sidebar */}
            {isSidebarOpen && (
                <>
                    <div
                        className='fixed inset-0 bg-backgrounds-white z-50'
                        onClick={closeSidebar}
                    ></div>
                    <div className='fixed inset-0 z-50'>
                        {path === '/chats' ? (
                            <Sidebar
                                isAdmin={props.isAdmin}
                                chatrepo={props.repo_to_chat}
                                chatrepofunction={props.updateRepoToChat}
                                set_fine_tuned={props.set_fine_tuned_chat}
                                fine_tune={props.fine_tuned_chat}
                                fileToChat={props.fileToChat}
                                setFileToChat={props.setFileToChat}
                                setIsRepoViewExpanded={
                                    props.setIsRepoViewExpanded
                                }
                                setIsAdmin={props.setIsAdmin}
                                mode={props.mode}
                                isSidebarOpen={isSidebarOpen}
                                closeSidebar={closeSidebar}
                                setIsSidebarOpen={setIsSidebarOpen}
                                processingRepos={props.processingRepos}
                                setViewNotifications={
                                    props.setViewNotifications
                                }
                                socket={props.socket}
                                togglePdfVisibility={props.togglePdfVisibility}
                                isSmallScreen={props.isSmallScreen}
                            />
                        ) : (
                            <>
                                {path === '/extraction' ? (
                                    <Sidebar
                                        chatrepo={props.chatrepo}
                                        chatrepofunction={
                                            props.chatrepofunction
                                        }
                                        set_fine_tuned={props.set_fine_tuned}
                                        fine_tune={props.fine_tune}
                                        fileToChat={props.fileToChat}
                                        setFileToChat={props.setFileToChat}
                                        setIsRepoViewExpanded={
                                            props.setIsRepoViewExpanded
                                        }
                                        isAdmin={props.isAdmin}
                                        setIsAdmin={props.setIsAdmin}
                                        mode={props.mode}
                                        isSidebarOpen={isSidebarOpen}
                                        closeSidebar={closeSidebar}
                                        setIsSidebarOpen={setIsSidebarOpen}
                                        processingRepos={props.processingRepos}
                                        setViewNotifications={
                                            props.setViewNotifications
                                        }
                                        socket={props.socket}
                                        togglePdfVisibility={
                                            props.togglePdfVisibility
                                        }
                                        isSmallScreen={props.isSmallScreen}
                                    />
                                ) : (
                                    <>
                                        {path === '/support' ? (
                                            <SupportSideBar
                                                topics={props.topics}
                                                activeSection={
                                                    props.activeSection
                                                }
                                                setActiveSection={
                                                    props.setActiveSection
                                                }
                                                setIsLeftViewExpanded={
                                                    props.setIsLeftViewExpanded
                                                }
                                                isSidebarOpen={isSidebarOpen}
                                                closeSidebar={closeSidebar}
                                                setIsSidebarOpen={
                                                    setIsSidebarOpen
                                                }
                                                socket={props.socket}
                                                displayFloatingButton={
                                                    props.displayFloatingButton
                                                }
                                                setDisplayFloatingButton={
                                                    props.setDisplayFloatingButton
                                                }
                                            />
                                        ) : (
                                            <>
                                                {path ===
                                                '/templates-and-layouts' ? (
                                                    <ReportLayoutsSideView
                                                        templateList={
                                                            props.templateList
                                                        }
                                                        selectedTemplate={
                                                            props.selectedTemplate
                                                        }
                                                        setSelectedTemplate={
                                                            props.setSelectedTemplate
                                                        }
                                                        selectedLayout={
                                                            props.selectedLayout
                                                        }
                                                        setSelectedLayout={
                                                            props.setSelectedLayout
                                                        }
                                                        setIsRepoViewExpanded={
                                                            props.setIsRepoViewExpanded
                                                        }
                                                        isSidebarOpen={
                                                            isSidebarOpen
                                                        }
                                                        closeSidebar={
                                                            closeSidebar
                                                        }
                                                        setIsSidebarOpen={
                                                            setIsSidebarOpen
                                                        }
                                                        socket={props.socket}
                                                    />
                                                ) : (
                                                    <div>
                                                        <SettingsSideBar
                                                            settingSections={
                                                                props.settingSections
                                                            }
                                                            activeSection={
                                                                props.activeSection
                                                            }
                                                            setActiveSection={
                                                                props.setActiveSection
                                                            }
                                                            setIsLeftViewExpanded={
                                                                props.setIsLeftViewExpanded
                                                            }
                                                            scrollToSettings={
                                                                props.scrollToSettings
                                                            }
                                                            isSidebarOpen={
                                                                isSidebarOpen
                                                            }
                                                            closeSidebar={
                                                                closeSidebar
                                                            }
                                                            setIsSidebarOpen={
                                                                setIsSidebarOpen
                                                            }
                                                        />
                                                    </div>
                                                )}
                                            </>
                                        )}
                                    </>
                                )}
                            </>
                        )}
                    </div>
                </>
            )}
        </div>
    );
};

export default Navbar;
