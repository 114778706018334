import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Brand } from './brand';
import { Input } from './reusableComponents';
import { PoweredByInfo } from './powereByComponent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { useAuth } from '../context/AuthContext';
import { useGoogleLogin } from '@react-oauth/google';
import googleLogo from '../images/google2.png';
import Toggle from './reusableComponents/Toggle';
import axios from 'axios';
import { Currency } from '../constants/currency';
import { getEmailPattern, isEmailValid } from '../utils/validateEmail';
import { validatePassword } from '../utils/validatePassword';
import { getUA } from 'react-device-detect';
import { getUrl } from './pdfDisplay/utils';
import { message } from 'antd';

const Signup = () => {
    const [userName, setUserName] = useState('');
    const [userNameValid, setUserNameValid] = useState(true);
    const [emailValid, setEmailValid] = React.useState(true);
    const [passwordValid, setPasswordValid] = React.useState(true);
    const [confirmPasswordValid, setConfirmPasswordValid] = useState(true);

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [currency, setCurrency] = useState('USD');
    const { login, authenticated } = useAuth();

    // Add a state to track if fields should be disabled
    const [isInvitedUser, setIsInvitedUser] = useState(false);

    // Add new state to store organization ID from invitation
    const [invitedOrgId, setInvitedOrgId] = useState(null);
    const [invitedAccessRights, setInvitedAccessRights] = useState(null);
    const [inviteToken, setInviteToken] = useState(null);

    const navigate = useNavigate();
    const location = useLocation();
    const path = location.pathname;
    const googleLogin = useGoogleLogin({
        onSuccess: (credentialResponse) => {
            async function sendData() {
                try {
                    const dynamicUrl = getUrl(
                        `http://${process.env.REACT_APP_NODE_SERVER_HOST}:${process.env.REACT_APP_NODE_SERVER_PORT}/google_signup`
                    );

                    const response = await axios.post(
                        dynamicUrl,
                        { ...credentialResponse, currency },
                        {
                            headers: {
                                'Content-Type': 'application/json',
                            },
                            credentials: 'include',
                        }
                    );

                    if (response.status === 200) {
                        const userData = response.data;
                        console.log('Signup successful', userData);
                        login(userData);
                    } else {
                        const errorData = response.data;
                        console.error('Signup failed', errorData);
                        alert(`Signup failed: ${errorData.message}`);
                    }
                } catch (err) {
                    alert(`Signup failed: ${err.response.data.message}`);
                }
            }
            sendData();
        },
        onError: () => {
            console.log('Login Failed');
        },
        flow: 'auth-code',
    });
    const signIn = async () => {
        try {
            const body = {
                username: userName,
                email,
                password,
                currency,
                organization_name: null,
                organization_address: null,
                organization_website: null,
                organization_industry: null,
                inviteToken: null,
                invitedOrgId: null,
                invitedAccessRights: null,
            };

            const dynamicUrl = getUrl(
                `http://${process.env.REACT_APP_NODE_SERVER_HOST}:${process.env.REACT_APP_NODE_SERVER_PORT}/new_signup`
            );

            if (isInvitedUser) {
                body.inviteToken = inviteToken;
                body.invitedOrgId = invitedOrgId;
                body.invitedAccessRights = invitedAccessRights;
            }

            const response = await axios.post(dynamicUrl, body, {
                headers: { 'Content-Type': 'application/json' },
                credentials: 'include',
            });

            if (response.status === 200) {
                const responsedata = response.data;

                console.log(responsedata);
                // localStorage.setItem('current', JSON.stringify(responsedata));
                // navigate('/chats');
                login(responsedata);
            } else {
                alert('the email already exists');
            }
        } catch (error) {
            alert(`Signup failed: ${error.response.data.message}`);
        }
    };

    function finalize(e) {
        e.preventDefault();
        const isPasswordValid = validatePassword(password).isValid;
        const isPasswordError = validatePassword(password).error;

        if (
            !userName &&
            (!email || !isEmailValid(email)) &&
            !isPasswordValid &&
            !confirmPassword
        ) {
            setUserNameValid(false);
            setEmailValid(false);
            setPasswordValid(false);
            setConfirmPasswordValid(false);
        } else if (
            (!email || !isEmailValid(email)) &&
            !isPasswordValid &&
            !confirmPassword
        ) {
            setEmailValid(false);
            setPasswordValid(false);
            setConfirmPasswordValid(false);
        } else if (!userName && !isPasswordValid && !confirmPassword) {
            setUserNameValid(false);
            setPasswordValid(false);
            setConfirmPasswordValid(false);
        } else if (
            !userName &&
            (!email || !isEmailValid(email)) &&
            !confirmPassword
        ) {
            setUserNameValid(false);
            setEmailValid(false);
            setConfirmPasswordValid(false);
        } else if (
            !userName &&
            (!email || !isEmailValid(email)) &&
            !isPasswordValid
        ) {
            setUserNameValid(false);
            setEmailValid(false);
            setPasswordValid(false);
        }

        if (!userName) {
            alert('User name field is empty!');
            setUserNameValid(false);
            return;
        } else {
            setUserNameValid(true);
        }

        if (!email) {
            alert('Email address field is empty!');
            setEmailValid(false);
            return;
        } else {
            setEmailValid(true);
        }
        if (isEmailValid(email)) {
            setEmailValid(true);
        } else {
            alert('Please enter a valid email address!');
            setEmailValid(false);
            return;
        }

        if (!password) {
            alert('Password field is empty!');
            setPasswordValid(false);
            return;
        } else {
            setPasswordValid(true);
        }
        if (!isPasswordValid) {
            alert(isPasswordError);
            setPasswordValid(false);
            return;
        } else {
            setPasswordValid(true);
        }

        if (!confirmPassword) {
            alert('Confirm Password field is empty!');
            setConfirmPasswordValid(false);
            return;
        } else {
            setConfirmPasswordValid(true);
        }

        if (password !== confirmPassword) {
            alert('Confirm password does not match with the password!');
            setConfirmPasswordValid(false);
            return;
        } else {
            setConfirmPasswordValid(true);
        }

        signIn();
    }

    // Update the useEffect that fetches invitation details
    useEffect(() => {
        const fetchInvitationDetails = async () => {
            try {
                const params = new URLSearchParams(location.search);
                const token = params.get('token');
                const orgId = params.get('org');
                const access_rights = params.get('access_rights');

                if (token && orgId) {
                    const dynamicUrl = getUrl(
                        `http://${process.env.REACT_APP_NODE_SERVER_HOST}:${process.env.REACT_APP_NODE_SERVER_PORT}/organization/invite-details`
                    );

                    const response = await axios.get(dynamicUrl, {
                        params: { token, org: orgId, access_rights },
                    });

                    const { organization, email: invitedEmail } = response.data;

                    // Store the organization ID and token
                    setInvitedOrgId(orgId);
                    setInviteToken(token);
                    setInvitedAccessRights(access_rights);

                    // Pre-fill the form
                    setEmail(invitedEmail);
                    setIsInvitedUser(true);
                }
            } catch (error) {
                message.error('Error fetching invite details');
                console.error('Error fetching invitation details');
            }
        };

        fetchInvitationDetails();
    }, [location.search]);

    return (
        <div>
            <div className='flex items-center justify-center min-h-screen  py-2 bg-gradient-primary'>
                <div className='w-full lg:w-1/2 max-w-[500px] flex flex-col border bg-backgrounds-white p-6 shadow-lg rounded-xl'>
                    <div
                        className='mb-8 w-full flex items-center justify-center'
                        onClick={() => {
                            if (path !== '/signup') {
                                navigate('/signup');
                            }
                        }}
                    >
                        <div className='w-fit h-fit'>
                            <Brand iconClassName={'w-20 h-20 mr-3'} />
                        </div>
                    </div>
                    <div className='flex flex-col text-sm rounded-md w-full'>
                        <form onSubmit={finalize}>
                            {/* Username */}
                            <div className='w-full mb-5'>
                                <Input
                                    className={`rounded-xl border p-3 hover:outline-none focus:outline-backgrounds-primary300 hover:border-backgrounds-primary300 shadow-sm ${
                                        !userNameValid && 'border-red-500'
                                    }`}
                                    type='text'
                                    placeholder={'Username'}
                                    value={userName}
                                    onChange={(e) =>
                                        setUserName(e.target.value)
                                    }
                                />
                            </div>

                            {/* Email */}
                            <div className='w-full mb-5'>
                                <Input
                                    className={`rounded-xl border p-3 hover:outline-none focus:outline-backgrounds-primary300 hover:border-backgrounds-primary300 shadow-sm ${
                                        !emailValid && 'border-red-500'
                                    }`}
                                    type='email'
                                    pattern={getEmailPattern().source}
                                    onInvalid={(e) =>
                                        e.target.setCustomValidity(
                                            'Please enter a valid email address'
                                        )
                                    }
                                    onInput={(e) =>
                                        e.target.setCustomValidity('')
                                    }
                                    placeholder='Email Id'
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    disabled={isInvitedUser}
                                />
                            </div>

                            {/* Currency */}
                            <div className='mb-2 text-texts-secondary300 font-semibold'>
                                Currency Preference
                            </div>
                            <select
                                className='w-full mb-5 rounded-xl border p-3 hover:outline-none focus:outline-backgrounds-primary300 hover:border-backgrounds-primary300 shadow-sm'
                                value={currency}
                                onChange={(e) => setCurrency(e.target.value)}
                                disabled={isInvitedUser}
                            >
                                {/* <option value=''>Currency Preference</option> */}

                                {Object.entries(Currency).map(
                                    ([key, { symbol }]) => (
                                        <option key={key} value={key}>
                                            {key} ({symbol})
                                        </option>
                                    )
                                )}
                            </select>
                            {/* Password */}
                            <div className='w-full mb-5'>
                                <Input
                                    className={`rounded-xl border p-3 hover:outline-none focus:outline-backgrounds-primary300 hover:border-backgrounds-primary300 shadow-sm ${
                                        !passwordValid && 'border-red-500'
                                    }`}
                                    type='password'
                                    placeholder='Set Password'
                                    value={password}
                                    onChange={(e) =>
                                        setPassword(e.target.value)
                                    }
                                />
                            </div>

                            {/* Confirm Password */}
                            <div className='w-full mb-5'>
                                <Input
                                    className={`rounded-xl border p-3 hover:outline-none focus:outline-backgrounds-primary300 hover:border-backgrounds-primary300 shadow-sm ${
                                        !confirmPasswordValid &&
                                        'border-red-500'
                                    }`}
                                    type='password'
                                    placeholder='Confirm Password'
                                    value={confirmPassword}
                                    onChange={(e) =>
                                        setConfirmPassword(e.target.value)
                                    }
                                />
                            </div>

                            {/* Sign up button */}
                            <button
                                className='w-full font-semibold items-center justify-center text-texts-primary bg-buttons-primary border border shadow-inner hover:bg-gradient-buttons hover:border-buttons-primary hover:text-texts-primary p-3 mt-2 rounded-lg'
                                type='submit'
                                // onClick={finalize}
                            >
                                <FontAwesomeIcon
                                    icon={faUserPlus}
                                    className='mr-1.5 drop-shadow-sm'
                                />
                                <span className='drop-shadow-sm'>Sign up</span>
                            </button>
                        </form>

                        {/* Sign up with Google button */}

                        <button
                            className='mt-5 w-full rounded-lg border px-3 py-2.5 flex justify-center items-center text-texts-secondary200 shadow-inner'
                            onClick={() => googleLogin()}
                        >
                            <span>
                                <img
                                    className='max-w-3.5 max-h-3.5 mr-2'
                                    src={googleLogo}
                                    alt='camera'
                                    draggable='false'
                                />
                            </span>
                            Sign up with Google
                        </button>
                    </div>

                    {/* Forgot password and Login links */}
                    <div className='mt-5 p-1 flex justify-between text-sm font-semibold text-texts-tertiary'>
                        <div></div>
                        <Link to='/login'>Login</Link>
                    </div>

                    {/* Powered By Info */}
                    <div className='w-full flex justify-center items-center mt-8'>
                        <PoweredByInfo />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Signup;
