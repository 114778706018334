import { message } from 'antd';

export const AlertType = Object.freeze({
    SUCCESS: 'success',
    ERROR: 'error',
    WARNING: 'warning',
});

export const alert = (type, content) => {
    message[type](content); // Use message.success, message.error, or message.warning
};
