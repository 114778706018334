import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCheckCircle,
    faCircleXmark,
    faExclamationTriangle,
} from '@fortawesome/free-solid-svg-icons';
import {
    alert,
    AlertType,
    Button,
    Input,
    LoadingView,
} from '../reusableComponents';
import { hasDuplicateNames, isValidName } from '../../utils';
import { getUrl } from '../pdfDisplay/utils';

const handleKeyDown = (event, buttonRef) => {
    if (event.key === 'Enter') {
        buttonRef.current.click(); // Programmatically click the button
    }
};

// Validate file types
const isValid = (dashboardsList, dashboardName, error, setError) => {
    if (!dashboardName) {
        setError('Please enter the dashboard name');
        return false;
    }

    const isValidNameData = isValidName(dashboardName, true);
    if (!isValidNameData.isValid) {
        setError(isValidNameData.error);
        return false;
    }

    if (hasDuplicateNames([...dashboardsList, { name: dashboardName }])) {
        setError(`Dashboard with name "${dashboardName}" already exist!`);
        return false;
    }

    if (error) {
        setError('');
    }
    return true;
};

const EditDashboardView = ({
    dashboardData,
    dashboardList,
    selectedDashboard,
    setSelectedDashboard,
    setEditMode,
    getDashboardList,
}) => {
    const buttonRef = useRef(null);

    const user = JSON.parse(localStorage.getItem('current'));
    const [dashboardName, setDashboardName] = useState(dashboardData.name);
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [loadingText, setLoadingText] = useState('');
    const [isEdited, setIsEdited] = useState(false);

    useEffect(() => {
        isValid(dashboardList, dashboardName, error, setError);
    }, [dashboardName]);

    const handleSubmit = async (event) => {
        event.preventDefault();

        try {
            setIsLoading(true);
            setLoadingText('Updating dashboard...');

            const dynamicUrl = getUrl(
                `http://${process.env.REACT_APP_FAST_API_HOST}:${process.env.REACT_APP_FAST_API_PORT}/dashboard_routes/update-dashboard-name`
            );

            const data = {
                dashboardId: dashboardData.id,
                new_name: dashboardName,
            };

            const encodedToken = encodeURIComponent(user.token);
            const response = await axios.put(`${dynamicUrl}`, data, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: encodedToken,
                },
            });

            if (response) {
                setLoadingText('Loading Dashboard List...');
                const list = await getDashboardList();

                if (selectedDashboard.id === dashboardData.id) {
                    const newData = list.find(
                        (dashboard) => dashboard.id === dashboardData.id
                    );
                    setSelectedDashboard(newData);
                }

                setEditMode(false);
            }
        } catch (err) {
            alert(AlertType.ERROR, `Error editing ${dashboardName} dashboard!`);
        } finally {
            setIsLoading(false);
            setLoadingText('');
        }
    };

    return (
        <div className='w-[60vw] min-w-[500px] flex-col px-6 py-6 rounded-xl bg-backgrounds-slate text-texts-secondary300'>
            {isLoading ? (
                <div className='w-full h-32 flex justify-center items-center w-full h-full py-6 my-1.5'>
                    <LoadingView loadingText={loadingText} inLine={false} />
                </div>
            ) : (
                <form>
                    <div className='mb-5'>
                        <Input
                            className=''
                            type='text'
                            placeholder='New dashboard name'
                            value={dashboardName}
                            maxLength={60}
                            onChange={(e) => {
                                if (!isEdited) {
                                    setIsEdited(true);
                                }

                                setDashboardName(e.target.value);
                            }}
                            onKeyDown={(event) =>
                                handleKeyDown(event, buttonRef)
                            }
                            autoFocus
                        />
                    </div>

                    {error && isEdited && (
                        <div className='flex justify-center items-center text-buttons-alert400 mt-3'>
                            <FontAwesomeIcon
                                icon={faExclamationTriangle}
                                className='mr-2'
                            />
                            {error}
                        </div>
                    )}

                    <div className='flex items-center justify-center mt-4'>
                        <div className='flex items-center justify-between w-full'>
                            <Button
                                type='danger'
                                icon={faCircleXmark}
                                className='w-1/3'
                                text={'Cancel'}
                                tile={'Cancel and close the modal'}
                                onClick={() => {
                                    setEditMode(false);
                                }}
                            />
                            <button
                                ref={buttonRef}
                                className={`flex items-center justify-center rounded-full py-1.5 px-2.5 rounded-lg order bg-backgrounds-white shadow-inner border font-semibold w-1/3 ${
                                    error || !dashboardName
                                        ? 'text-texts-secondary300 cursor-not-allowed'
                                        : `hover:bg-backgrounds-primary100 hover:border-backgrounds-primary200 text-backgrounds-primary400`
                                }`}
                                type='submit'
                                title='Add new dashboard file to collection'
                                disabled={error || !dashboardName}
                                onClick={!error ? handleSubmit : () => {}}
                            >
                                <FontAwesomeIcon
                                    icon={faCheckCircle}
                                    className='mr-2'
                                />
                                Update
                            </button>
                        </div>
                    </div>
                </form>
            )}
        </div>
    );
};

export default EditDashboardView;
