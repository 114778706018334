export const getUrl = (fullUrl) => {
    const useFrontendBuild = process.env.REACT_APP_USE_FRONTEND_BUILD;
    if (useFrontendBuild) {
        const url = new URL(fullUrl);
        return url.pathname + url.search + url.hash;
    } else {
        const useSecuredConnection =
            process.env.REACT_APP_USE_SECURED_CONNECTION;
        if (useSecuredConnection) {
            return fullUrl.replace('http:', 'https:');
        }
        return fullUrl;
    }
};
