import { FILE_EXTENSIONS } from '../constants/fileExtensions';

export const isImageFile = (fileExtension) => {
    if (!fileExtension) {
        return false;
    }

    const extension = fileExtension.toLowerCase();
    return (
        extension === FILE_EXTENSIONS.JPG ||
        extension === FILE_EXTENSIONS.JPEG ||
        extension === FILE_EXTENSIONS.PNG ||
        extension === FILE_EXTENSIONS.BMP ||
        extension === FILE_EXTENSIONS.SVG
    );
};
