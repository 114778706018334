import {
    faArrowRight,
    faFileDownload,
    faFileExport,
    faFolder,
    faHandPointLeft,
    faTable,
    faArrowDown,
    faHandPointUp,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

const BeforeSelectInfo = () => {
    const isSmallScreen = window.innerWidth < 1024;

    return (
        <div className='bg-backgrounds-slate w-full h-full rounded-xl'>
            {!isSmallScreen ? (
                <div className='w-full h-full'>
                    <div className='flex h-4/6 w-full items-center justify-center text-texts-secondary300'>
                        <div className='flex flex-col items-center justify-center m-[3vw]'>
                            <FontAwesomeIcon
                                icon={faFolder}
                                className='w-[8vw] h-[8vw] mb-2 drop-shadow text-icons-primary100'
                            />
                            <div>Select Repository</div>
                        </div>

                        <FontAwesomeIcon
                            icon={faArrowRight}
                            className='w-[5vw] h-[5vw] m-[3vw] drop-shadow text-icons-tertiary100'
                        />

                        <div className='flex flex-col m-[3vw]'>
                            <FontAwesomeIcon
                                icon={faFileExport}
                                className='w-[8vw] h-[8vw] mb-2 drop-shadow text-icons-primary100 items-center justify-center'
                            />
                            <div className='ml-3'>Extract Data</div>
                        </div>

                        <FontAwesomeIcon
                            icon={faArrowRight}
                            className='w-[5vw] h-[5vw] m-[3vw] drop-shadow text-icons-tertiary100'
                        />
                        <div>
                            <div className='flex flex-col items-center justify-center m-[3vw]'>
                                <FontAwesomeIcon
                                    icon={faFileDownload}
                                    className='w-[5vw] h-[5vw] mb-2 drop-shadow text-icons-primary100'
                                />
                                <div>Download Data</div>
                            </div>

                            <div className='flex flex-col items-center justify-center m-[3vw]'>
                                <FontAwesomeIcon
                                    icon={faTable}
                                    className='w-[5vw] h-[5vw] mb-2 drop-shadow text-icons-primary100'
                                />
                                <div>View Data</div>
                            </div>
                        </div>
                    </div>

                    <div className='flex items-center justify-center text-texts-secondary300 text-xl pt-10'>
                        <FontAwesomeIcon
                            icon={faHandPointLeft}
                            className='mr-3 text-icons-primary'
                        />
                        <div>
                            Select a repository from repositories section for
                            data extraction, data download and view the data
                        </div>
                    </div>
                </div>
            ) : (
                <div className='flex flex-col items-center'>
                    <div className='flex items-center justify-center text-texts-secondary300 text-xl pt-5 pl-2 pr-2'>
                        <FontAwesomeIcon
                            icon={faHandPointUp}
                            className='mr-3 text-icons-primary'
                        />
                        <h1>
                            Select a repository for data extraction, data
                            download and view the data
                        </h1>
                    </div>
                    <div className='flex flex-col items-center justify-center h-4/6 text-texts-secondary300 mt-20'>
                        <div className='flex flex-col items-center justify-center mb-2 drop-shadow'>
                            <FontAwesomeIcon
                                icon={faFolder}
                                className='w-20 h-20 md:w-24 md:h-24 drop-shadow text-icons-primary100'
                            />
                            <div className='md:ml-3 text-base md:text-lg'>
                                Select Repository
                            </div>
                        </div>

                        <FontAwesomeIcon
                            icon={faArrowDown}
                            className='w-10 h-10 md:w-12 md:h-12 my-2 drop-shadow text-icons-tertiary100'
                        />

                        <div className='flex flex-col mb-2  drop-shadow'>
                            <FontAwesomeIcon
                                icon={faFileExport}
                                className='w-20 h-20 md:w-24 md:h-24 drop-shadow text-icons-tertiary items-center justify-center'
                            />
                            <div className='md:ml-3 text-base md:text-lg'>
                                Extract Data
                            </div>
                        </div>

                        <FontAwesomeIcon
                            icon={faArrowDown}
                            className='w-10 h-10 md:w-12 md:h-12 my-2 drop-shadow text-icons-tertiary100'
                        />

                        <div className='flex h-4/6 w-full items-center justify-center text-texts-secondary300'>
                            <div className='flex flex-col items-center justify-center m-[3vw]'>
                                <FontAwesomeIcon
                                    icon={faFileDownload}
                                    className='w-20 h-20 md:w-24 md:h-24 drop-shadow text-icons-primary100'
                                />
                                <div>Download Data</div>
                            </div>

                            <div className='flex flex-col items-center justify-center m-[3vw]'>
                                <FontAwesomeIcon
                                    icon={faTable}
                                    className='w-20 h-20 md:w-24 md:h-24 drop-shadow text-icons-primary100'
                                />
                                <div>View Data</div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default BeforeSelectInfo;
