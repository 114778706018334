import React, { useEffect, useState } from 'react';
import 'react-reflex/styles.css';
import axios from 'axios';
import { ReflexContainer, ReflexElement, ReflexSplitter } from 'react-reflex';
import { CollapsedSideBar } from '../collapsedSideBar';
import { alert, AlertType, NoInteractionView } from '../reusableComponents';
import { faTableCells } from '@fortawesome/free-solid-svg-icons';
import Navbar from '../navbar';

import BeforeSelectView from './beforeSelectView';
import TransactionList from '../Transactionlist';
import { useGlobalCredits } from '../GlobalStateContext';
import { getUrl } from '../pdfDisplay/utils';
import DashboardSideView from './dashboardSideView';
import DashboardMainView from './dashboardMainView';

const minWindowSize = 50;

const getRepositoryList = async ({
    user,
    setLoadingText,
    setIsLoading,
    setRepositoryList,
}) => {
    try {
        setLoadingText('Loading Repository List...');
        setIsLoading(true);

        const dynamicUrl = getUrl(
            `http://${process.env.REACT_APP_FAST_API_HOST}:${process.env.REACT_APP_FAST_API_PORT}/dashboard_routes/repos/${user.email}`
        );

        const encodedToken = encodeURIComponent(user.token);
        const response = await axios.get(dynamicUrl, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: encodedToken,
            },
        });

        if (response) {
            const repositoryList = response.data;

            setRepositoryList(repositoryList);
        }
    } catch (error) {
        alert(AlertType.ERROR, error.message);
    } finally {
        setIsLoading(false);
        setLoadingText('');
    }
};

export const DashboardView = ({
    newNotification,
    setNewNotification,
    viewNotifications,
    setViewNotifications,
    messages,
    socket,
    isSideViewExpanded,
    setIsSideViewExpanded,
}) => {
    const user = JSON.parse(localStorage.getItem('current'));
    const [selectedDashboard, setSelectedDashboard] = useState(null);
    const [repositoryList, setRepositoryList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [loadingText, setLoadingText] = useState(false);
    const { transactionMode, setTransactionMode } = useGlobalCredits();
    const [isSmallScreen, setIsSmallScreen] = useState(false);
    const [addNewDashboardMode, setAddNewDashboardMode] = useState(false);
    const [editDashboardMode, setEditDashboardMode] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth <= 1024); // Change 1024 to your desired breakpoint
        };

        handleResize(); // Initial check
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        (async () => {
            await getRepositoryList({
                user,
                setLoadingText,
                setIsLoading,
                setRepositoryList,
            });
        })();
    }, []);

    if (isLoading) {
        return <NoInteractionView viewText={loadingText} />;
    }

    return (
        <div className='z-0 w-[99vw] h-[99vh] m-auto pt-2'>
            <ReflexContainer orientation='vertical'>
                {!isSmallScreen && (
                    <ReflexElement
                        className='left-pane mr-0.5'
                        minSize={isSideViewExpanded ? 250 : minWindowSize}
                        size={isSideViewExpanded ? 350 : minWindowSize}
                        maxSize={isSideViewExpanded ? 700 : minWindowSize}
                    >
                        <div className='pane-content w-full h-full bg-transparent'>
                            {isSideViewExpanded ? (
                                <div className='w-full h-full border border-2 overflow-auto bg-backgrounds-slate rounded-xl p-2 z-10'>
                                    <DashboardSideView
                                        user={user}
                                        selectedDashboard={selectedDashboard}
                                        setSelectedDashboard={
                                            setSelectedDashboard
                                        }
                                        setIsSideViewExpanded={
                                            setIsSideViewExpanded
                                        }
                                        addNewDashboardMode={
                                            addNewDashboardMode
                                        }
                                        setAddNewDashboardMode={
                                            setAddNewDashboardMode
                                        }
                                        editDashboardMode={editDashboardMode}
                                        setEditDashboardMode={
                                            setEditDashboardMode
                                        }
                                    />
                                </div>
                            ) : (
                                <CollapsedSideBar
                                    icon={faTableCells}
                                    iconColor={'icons-primary'}
                                    title={'Dashboards'}
                                    setIsRepoViewExpanded={
                                        setIsSideViewExpanded
                                    }
                                />
                            )}
                        </div>
                    </ReflexElement>
                )}

                <ReflexSplitter
                    style={{
                        border: 0,
                        background: 'white',
                        width: '6px',
                        zIndex: 10,
                        height: '98vh',
                    }}
                />

                <ReflexElement className='right-pane ml-0.5'>
                    <div className='pane-content w-full h-full bg-transparent'>
                        <div className='w-full h-full border border-2 overflow-auto bg-backgrounds-slate rounded-xl z-0'>
                            <ReflexContainer orientation='horizontal'>
                                <ReflexElement
                                    className='header'
                                    style={{
                                        minHeight: '4rem',
                                        height: '4rem',
                                        maxHeight: '4rem',
                                    }}
                                >
                                    <div className='pane-content w-full h-full p-2'>
                                        <Navbar
                                            newNotification={newNotification}
                                            setNewNotification={
                                                setNewNotification
                                            }
                                            viewNotifications={
                                                viewNotifications
                                            }
                                            setViewNotifications={
                                                setViewNotifications
                                            }
                                            messages={messages}
                                            socket={socket}
                                            selectedDashboard={
                                                selectedDashboard
                                            }
                                            setSelectedDashboard={
                                                setSelectedDashboard
                                            }
                                            setIsRepoViewExpanded={
                                                setIsSideViewExpanded
                                            }
                                        />
                                    </div>
                                </ReflexElement>

                                <ReflexElement flex={1}>
                                    <div className='pane-content h-full w-full p-2'>
                                        {transactionMode ? (
                                            <TransactionList />
                                        ) : (
                                            <>
                                                {selectedDashboard === null ? (
                                                    <BeforeSelectView
                                                        isSmallScreen={
                                                            isSmallScreen
                                                        }
                                                    />
                                                ) : (
                                                    <DashboardMainView
                                                        user={user}
                                                        repositoryList={
                                                            repositoryList
                                                        }
                                                        selectedDashboard={
                                                            selectedDashboard
                                                        }
                                                        isSmallScreen={
                                                            isSmallScreen
                                                        }
                                                    />
                                                )}
                                            </>
                                        )}
                                    </div>
                                </ReflexElement>
                            </ReflexContainer>
                        </div>
                    </div>
                </ReflexElement>
            </ReflexContainer>
        </div>
    );
};
