import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faClose,
    faBoxOpen,
    faSquareCheck,
    faCaretUp,
    faCaretDown,
    faSquare,
    faCircle,
} from '@fortawesome/free-solid-svg-icons';
import { Button } from './button';
import { Popover } from 'antd';

export const DropdownListType = Object.freeze({
    RADIO: 'radio',
    CHECKBOX: 'checkbox',
});

const Title = ({ title, handleOpenChange, showCloseButton }) => {
    return (
        <div className='min-w-38 z-50 flex justify-between items-center pb-1 border-b text-base select-none'>
            <div className='text-texts-secondary300 ml-1 mr-3'>{title}</div>
            {showCloseButton && (
                <Button
                    onClick={(e) => {
                        handleOpenChange(false);
                        e.stopPropagation();
                    }}
                    className='w-6 h-6'
                    title='Close'
                    hoverText={'Close the dropdown'}
                    icon={faClose}
                    type={'danger'}
                />
            )}
        </div>
    );
};

const ListType = ({ listType, isSelected }) => {
    if (listType === DropdownListType.CHECKBOX) {
        if (isSelected) {
            return (
                <FontAwesomeIcon
                    icon={faSquareCheck}
                    className='ml-4 text-texts-tertiary border p-0.5 rounded shadow-inner'
                />
            );
        }
        return (
            <FontAwesomeIcon
                icon={faSquare}
                className='ml-4 text-icons-tertiary100 border p-0.5 rounded shadow-inner'
            />
        );
    }

    if (listType === DropdownListType.RADIO) {
        if (isSelected) {
            return (
                <FontAwesomeIcon
                    icon={faCircle}
                    className='ml-4 text-texts-tertiary border p-0.5 rounded-full shadow-inner'
                />
            );
        }
        return (
            <FontAwesomeIcon
                icon={faCircle}
                className='ml-4 text-icons-tertiary100 border p-0.5 rounded-full shadow-inner'
            />
        );
    }

    if (isSelected) {
        return (
            <FontAwesomeIcon
                icon={faCircle}
                className='ml-4 text-texts-tertiary border p-0.5 rounded-full shadow-inner'
            />
        );
    }
    return <></>;
};

const renderDropDownList = (
    dropDownList,
    handleOpenChange,
    disabled,
    listType,
    listClassName
) => {
    return dropDownList.map((item, index) => {
        return (
            <a
                href='#ref'
                key={index}
                title={disabled || item.disabled ? 'Disabled' : ''}
                className={`px-2 py-1.5 block w-full whitespace-no-wrap rounded-lg text-base ${
                    disabled || item.disabled
                        ? 'text-texts-secondary300 bg-background-slate cursor-not-allowed'
                        : 'text-texts-secondary300 hover:bg-backgrounds-primary100'
                }`}
                onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    if (!disabled && !item.disabled) {
                        item.action();
                        if (
                            !(
                                listType === DropdownListType.CHECKBOX ||
                                listType === DropdownListType.RADIO
                            )
                        ) {
                            handleOpenChange(false);
                        }
                    }
                }}
            >
                <div className='flex items-center justify-between'>
                    {item.icon ? (
                        <div className='flex items-center'>
                            <FontAwesomeIcon
                                icon={item.icon}
                                className={`mr-2 ${
                                    item.disabled
                                        ? 'text-texts-secondary300'
                                        : 'text-texts-tertiary200'
                                } drop-shadow-sm`}
                                color={item.iconColor}
                            />
                            <div className='max-w-60 truncate text-base'>
                                {item.label}
                            </div>
                        </div>
                    ) : (
                        <div className='max-w-60 truncate text-base'>
                            {item.label}
                        </div>
                    )}

                    <ListType listType={listType} isSelected={item.selected} />
                </div>
            </a>
        );
    });
};

const Content = ({
    dropDownList,
    handleOpenChange,
    disabled,
    listType,
    listClassName,
}) => {
    if (dropDownList && dropDownList.length) {
        return (
            <div className='w-full h-fit max-h-[50vh] max-w-[70vw] overflow-auto'>
                {renderDropDownList(
                    dropDownList,
                    handleOpenChange,
                    disabled,
                    listType,
                    listClassName
                )}
            </div>
        );
    } else {
        return (
            <div className='w-full flex flex-col py-2 justify-center items-center'>
                <FontAwesomeIcon
                    icon={faBoxOpen}
                    size='2x'
                    className='text-texts-secondary300'
                />
                <div className='font-semibold text-texts-secondary300'>
                    No Data
                </div>
            </div>
        );
    }
};

const DropDownButton = ({
    buttonTitle,
    disabled,
    buttonClassName,
    buttonHoverText,
    showButtonText,
    icon,
    bgColor,
    buttonStr,
    showDropdown,
    additionalButtonAction,
    customClass,
}) => {
    return (
        <div
            className='flex items-center justify-center w-fit'
            onClick={(e) => {
                if (additionalButtonAction) {
                    additionalButtonAction();
                }
            }}
        >
            {buttonTitle && (
                <div className='px-2 w-max text-texts-secondary300 font-semibold shadow-inner rounded-full rounded-r-none pl-3 py-1.5 border border-r-none bg-backgrounds-primary100 text-base'>
                    {buttonTitle}
                </div>
            )}
            <div
                className={
                    customClass
                        ? buttonClassName // Apply only the buttonClassName if customClass is true
                        : `font-semibold flex justify-center items-center rounded-full px-3 py-1.5 border shadow-inner outline-none focus:outline-backgrounds-primary200 ${
                              disabled
                                  ? 'opacity-50 cursor-not-allowed text-texts-secondary bg-backgrounds-white'
                                  : bgColor === 'white'
                                  ? `hover:bg-backgrounds-primary100 hover:border-backgrounds-primary200 text-texts-tertiary bg-backgrounds-white cursor-pointer`
                                  : `hover:bg-backgrounds-secondary hover:border-backgrounds-secondary text-texts-tertiary bg-backgrounds-secondary cursor-pointer`
                          } ${buttonClassName} ${
                              buttonTitle ? 'rounded-l-none' : ''
                          }`
                }
                style={{ transition: 'all .15s ease' }}
                disabled={disabled}
                title={buttonHoverText ? buttonHoverText : 'More options'}
            >
                <div
                    className='w-full flex justify-between items-center select-none'
                    draggable='false'
                >
                    {showButtonText ? (
                        <div className='truncate'>
                            <div className='mr-2 max-w-48 truncate text-base'>
                                {buttonStr ? buttonStr : 'More options'}
                            </div>
                        </div>
                    ) : (
                        ''
                    )}

                    {icon ? (
                        icon
                    ) : (
                        <FontAwesomeIcon
                            icon={showDropdown ? faCaretUp : faCaretDown}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export const Dropdown = ({
    dropDownList,
    showButtonText,
    buttonText,
    buttonTitle,
    buttonHoverText,
    additionalButtonAction,
    icon,
    listTitleText,
    textColor,
    backgroundColor,
    buttonClassName,
    listClassName,
    disabled,
    placement,
    closeSignal,
    customClass,
    showCloseButton,
    listType,
}) => {
    const [showDropdown, setShowDropdown] = useState(false);

    const handleOpenChange = (show) => {
        setShowDropdown(show && !disabled && !closeSignal);
    };

    const bgColor = backgroundColor ? backgroundColor : 'white';
    const color = textColor ? textColor : 'blue-200';
    const title = listTitleText ? listTitleText : 'More Options';
    const buttonStr = buttonText ? buttonText : null;

    useEffect(() => {
        if (closeSignal) {
            handleOpenChange(true);
        }

        return () => {};
    }, [closeSignal]);

    return (
        <Popover
            content={
                <Content
                    disabled={disabled}
                    dropDownList={dropDownList}
                    handleOpenChange={handleOpenChange}
                    listType={listType}
                    listClassName={{ listClassName }}
                />
            }
            title={
                <Title
                    title={title}
                    handleOpenChange={handleOpenChange}
                    showCloseButton={showCloseButton}
                />
            }
            trigger='click'
            placement={placement ? placement : 'bottomRight'}
            open={!disabled && showDropdown && !closeSignal}
            onOpenChange={handleOpenChange}
        >
            <div onClick={(e) => e.stopPropagation()}>
                <DropDownButton
                    additionalButtonAction={additionalButtonAction}
                    buttonTitle={buttonTitle}
                    disabled={disabled}
                    buttonClassName={buttonClassName}
                    buttonHoverText={buttonHoverText}
                    showButtonText={showButtonText}
                    icon={icon}
                    bgColor={bgColor}
                    buttonStr={buttonStr}
                    showDropdown={showDropdown}
                    customClass={customClass}
                    listType={listType}
                />
            </div>
        </Popover>
    );
};
