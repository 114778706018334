import { Chart } from 'react-google-charts';
import { ChartTypes, defaultChartOptions } from './constants';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isNumeric, toSentenceCase } from '../../reportLayouts/utils';

const CardView = ({
    title = 'Sample Title',
    data = '12,345',
    options = {
        color: 'grey',
        backgroundColor: 'white',
    },
    isDemoPreview,
}) => {
    const modifiedData = isNumeric(data) ? data.toFixed(3) : data;
    return (
        <div
            className='w-full h-full flex-col justify-center items-center rounded-xl truncate'
            style={{
                backgroundColor: options.backgroundColor,
                color: options.color,
            }}
        >
            {/* Card Header */}
            {isDemoPreview && (
                <div
                    className={`w-full flex justify-between items-center p-2 rounded-t-xl border-b`}
                >
                    <div className='font-semibold p-1 drop-shadow-sm truncate'>
                        <span className='w-full truncate'>
                            {toSentenceCase(title)}
                        </span>
                    </div>
                </div>
            )}

            {/* Card Body */}
            <div
                className={`w-full h-[60%] flex justify-center items-center rounded-b-xl truncate ${
                    isDemoPreview ? 'min-h-[100px]' : ''
                }`}
            >
                <div className='font-semibold text-2xl truncate p-2'>
                    {modifiedData}
                </div>
            </div>
        </div>
    );
};

export const ChartView = ({
    chartData,
    chartName,
    showNoDataWarning = true,
    usingDemoData = false,
    isDemoPreview = false,
    isFullScreen = false,
    enableEditing,
}) => {
    const options = { ...defaultChartOptions };
    options.title = chartName;

    if (showNoDataWarning && (!chartData || !chartData.chart)) {
        return (
            <div className='flex w-full h-[120px] justify-center items-center'>
                <FontAwesomeIcon
                    icon={faTriangleExclamation}
                    className='mr-2 text-backgrounds-settings500'
                />
                <span className='text-texts-secondary300 font-semibold text-outline drop-shadow-sm'>
                    No Chart Selected
                </span>
            </div>
        );
    }

    if (
        showNoDataWarning &&
        (!chartData || !chartData.chart || chartData.data.length === 0)
    ) {
        return (
            <div className='flex w-full h-[120px] justify-center items-center'>
                <FontAwesomeIcon
                    icon={faTriangleExclamation}
                    className='mr-2 text-backgrounds-settings500'
                />
                <span className='text-texts-secondary300 font-semibold text-outline drop-shadow-sm'>
                    Non Executed Widget
                </span>
            </div>
        );
    }

    return (
        <div
            className={`w-full h-full text-base min-h-[150px]`}
            style={{
                filter: usingDemoData && !enableEditing ? 'blur(2px)' : '',
            }}
        >
            {chartData.chart !== ChartTypes.CARD ? (
                <Chart
                    chartType={chartData.chart}
                    width='100%'
                    height={
                        chartData.chart === ChartTypes.TABLE
                            ? 'h-fit'
                            : isFullScreen
                            ? '99.99%'
                            : '90%'
                    }
                    data={chartData.data}
                    options={chartData.options ?? options}
                    chartPackages={['corechart', 'controls']}
                />
            ) : (
                <CardView
                    title={
                        chartName ??
                        toSentenceCase(chartData.data[0][0]) ??
                        'No Title'
                    }
                    data={chartData.data[1][0] ?? 'No Data'}
                    options={chartData.options}
                    isDemoPreview={isDemoPreview}
                />
            )}
        </div>
    );
};
