import React, { useEffect, useState } from 'react';
import 'react-reflex/styles.css';
import { ReflexContainer, ReflexElement, ReflexSplitter } from 'react-reflex';
import { CollapsedSideBar } from '../collapsedSideBar';
import {
    alert,
    AlertType,
    Container,
    InformationModal,
    NoInteractionView,
} from '../reusableComponents';
import {
    faFileLines,
    faFolder,
    faSave,
} from '@fortawesome/free-solid-svg-icons';
import Navbar from '../navbar';
import ReportLayoutsDesignerView from './reportLayoutsDesignerView';
import ReportLayoutsSideView from './reportLayoutsSideView';
import BeforeSelectView from './beforeSelectView';
import TemplateManager from '../extractionTemplateDesigner/templateManager';
import TransactionList from '../Transactionlist';
import { useGlobalCredits } from '../GlobalStateContext';
import { getUrl } from '../pdfDisplay/utils';
import isEqual from 'lodash.isequal';
const minWindowSize = 50;
export const ReportLayoutsView = ({
    setActiveTemplate,
    templateList,
    setTemplateList,
    fetchTemplateList,
    newNotification,
    setNewNotification,
    viewNotifications,
    setViewNotifications,
    messages,
    socket,
    isSideViewExpanded,
    setIsSideViewExpanded,
    openWithTemplateDesigner,
    setOpenWithTemplateDesigner,
    selectedTemplate,
    setSelectedTemplate,
    selectedLayout,
    setSelectedLayout,
    addNewTemplateMode,
    setAddNewTemplateMode,
    templateData,
    setTemplateData,
    retrievedTemplateData,
    setRetrievedTemplateData,
    templateIsSuccessful,
    setTemplateIsSuccessful,
}) => {
    const user = JSON.parse(localStorage.getItem('current'));
    const [isLoading, setIsLoading] = useState(false);
    const { transactionMode, setTransactionMode } = useGlobalCredits();
    const [isMobile, setIsMobile] = useState(false);
    const [selectedTemplateNew, setSelectedTemplateNew] = useState('');
    const [templateDescription, setTemplateDescription] = useState('');
    const [templateSheetName, setTemplateSheetName] = useState('');

    useEffect(() => {
        if (openWithTemplateDesigner) {
            setAddNewTemplateMode(true);
            setOpenWithTemplateDesigner(false); // Set it to false after it's been processed
        }
    }, [
        openWithTemplateDesigner,
        setAddNewTemplateMode,
        setOpenWithTemplateDesigner,
    ]);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 1024); // Change 1024 to your desired breakpoint
        };

        handleResize(); // Initial check
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (isMobile) {
            setAddNewTemplateMode(false);
        }
    }, [isMobile]);

    useEffect(() => {
        (async () => {
            setIsLoading(true);
            await fetchTemplateList(user, setActiveTemplate, setTemplateList);
            setIsLoading(false);
        })();
        return () => {};
    }, []);

    if (isLoading) {
        return <NoInteractionView viewText={'Loading ...'} />;
    }

    // Function to clear templateData and load a new template from the API
    const handleLoadTemplate = async (email, templateName) => {
        try {
            setTemplateData(null); // Clear templateData before loading new data
            const response = await fetch(
                getUrl(
                    `http://${process.env.REACT_APP_FAST_API_HOST}:${process.env.REACT_APP_FAST_API_PORT}/read_template?email=${email}&template_name=${templateName}`
                ),
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );

            if (response.ok) {
                const data = await response.json();
                setRetrievedTemplateData(data); // Store the response in templateData
                console.log('Template data loaded successfully:', data);
            } else {
                console.error('Failed to load the template.');
            }
        } catch (error) {
            console.error(
                'An error occurred while loading the template:',
                error
            );
        }
    };
    const deepEqual = (obj1, obj2) => {
        console.log('OBJ1:', obj1);
        console.log('OBJ2:', obj2);
        return isEqual(obj1, obj2);
    };

    const handleSaveTemplate = async () => {
        if (
            !selectedTemplateNew ||
            !templateDescription ||
            !templateSheetName
        ) {
            // Check which field(s) are empty and show respective alert messages
            if (!selectedTemplateNew) {
                alert(
                    AlertType.ERROR,
                    'Selected Template name cannot be empty.'
                );
            } else if (!templateDescription) {
                alert(AlertType.ERROR, 'Template Description cannot be empty.');
            } else if (!templateSheetName) {
                alert(
                    AlertType.ERROR,
                    'Template collection name cannot be empty.'
                );
            }
            return; // Exit the function if any field is invalid
        }
        if (!templateData) {
            alert('No template data to save.');
            return;
        }

        // Parse the templateData to extract the last key-value pair
        const parsedData = JSON.parse(templateData);
        const lastKey = Object.keys(parsedData).pop();

        const lastTemplateData = { [lastKey]: parsedData[lastKey] };

        // Check if template_description and template_data are empty
        if (
            !lastTemplateData[lastKey].template_description ||
            lastTemplateData[lastKey].template_data.length === 0
        ) {
            alert('Template description and data are required.');
            return;
        }
        // Compare templateData with retrievedTemplateData
        if (
            retrievedTemplateData &&
            deepEqual(lastTemplateData, retrievedTemplateData)
        ) {
            alert(
                'Existing Template already exists.Please make some changes in the template to save the edits.'
            );
            return; // Abort saving since there is no difference
        }

        console.log('Sending data to generate CSV.');
        try {
            // Call the Python API running on localhost:8000
            const response = await fetch(
                getUrl(
                    `http://${process.env.REACT_APP_FAST_API_HOST}:${process.env.REACT_APP_FAST_API_PORT}/generate_csv`
                ),
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(lastTemplateData),
                }
            );

            if (response.ok) {
                console.log('CSV generated and saved successfully!');

                // alert('Template generated and saved successfully!');
                setTemplateIsSuccessful(true);
                //setTemplateData(null);
                //setAddNewTemplateMode(false);
            } else {
                console.error('Failed to generate the CSV.');
                // Handle error (e.g., show an error message)
            }
        } catch (error) {
            console.error('An error occurred while generating the CSV:', error);
        }
    };

    const templateActions = [
        {
            text: 'Save',
            icon: faSave,
            action: handleSaveTemplate,
            hoverText: 'Save the template for extraction',
            type: 'success',
        },
    ];

    return (
        <div className='z-0 w-[99vw] h-[99vh] m-auto pt-2'>
            <ReflexContainer orientation='vertical'>
                {!isMobile && (
                    <ReflexElement
                        className='left-pane mr-0.5'
                        minSize={isSideViewExpanded ? 250 : minWindowSize}
                        size={isSideViewExpanded ? 350 : minWindowSize}
                        maxSize={isSideViewExpanded ? 700 : minWindowSize}
                    >
                        <div className='pane-content w-full h-full bg-transparent'>
                            {isSideViewExpanded ? (
                                <div className='w-full h-full border border-2 overflow-auto bg-backgrounds-slate rounded-xl p-2 z-10'>
                                    <ReportLayoutsSideView
                                        templateList={templateList}
                                        setTemplateList={setTemplateList}
                                        selectedTemplate={selectedTemplate}
                                        setSelectedTemplate={
                                            setSelectedTemplate
                                        }
                                        selectedLayout={selectedLayout}
                                        setSelectedLayout={setSelectedLayout}
                                        setIsRepoViewExpanded={
                                            setIsSideViewExpanded
                                        }
                                        setAddNewTemplateMode={
                                            setAddNewTemplateMode
                                        }
                                        templateData={templateData}
                                        setTemplateData={setTemplateData}
                                        handleLoadTemplate={handleLoadTemplate}
                                        setRetrievedTemplateData={
                                            setRetrievedTemplateData
                                        }
                                    />
                                </div>
                            ) : (
                                <CollapsedSideBar
                                    icon={faFileLines}
                                    iconColor={'icons-primary'}
                                    title={'Templates/Layouts'}
                                    setIsRepoViewExpanded={
                                        setIsSideViewExpanded
                                    }
                                />
                            )}
                        </div>
                    </ReflexElement>
                )}

                <ReflexSplitter
                    style={{
                        border: 0,
                        background: 'white',
                        width: '6px',
                        zIndex: 10,
                        height: '98vh',
                    }}
                />

                <ReflexElement className='right-pane ml-0.5'>
                    <div className='pane-content w-full h-full bg-transparent'>
                        <div className='w-full h-full border border-2 overflow-auto bg-backgrounds-slate rounded-xl z-0'>
                            <ReflexContainer orientation='horizontal'>
                                <ReflexElement
                                    className='header'
                                    style={{
                                        minHeight: '4rem',
                                        height: '4rem',
                                        maxHeight: '4rem',
                                    }}
                                >
                                    <div className='pane-content w-full h-full p-2'>
                                        <Navbar
                                            newNotification={newNotification}
                                            setNewNotification={
                                                setNewNotification
                                            }
                                            viewNotifications={
                                                viewNotifications
                                            }
                                            setViewNotifications={
                                                setViewNotifications
                                            }
                                            messages={messages}
                                            socket={socket}
                                            templateList={templateList}
                                            selectedTemplate={selectedTemplate}
                                            setSelectedTemplate={
                                                setSelectedTemplate
                                            }
                                            selectedLayout={selectedLayout}
                                            setSelectedLayout={
                                                setSelectedLayout
                                            }
                                            setIsRepoViewExpanded={
                                                setIsSideViewExpanded
                                            }
                                        />
                                    </div>
                                </ReflexElement>

                                <ReflexElement flex={1}>
                                    <div className='pane-content h-full w-full p-2'>
                                        {transactionMode ? (
                                            <TransactionList />
                                        ) : addNewTemplateMode ? ( // Add this condition
                                            <Container
                                                title='Template Designer'
                                                icon={faFolder}
                                                iconColor={'icons-primary'}
                                                actions={templateActions}
                                            >
                                                <TemplateManager
                                                    selectedTemplate={
                                                        selectedTemplateNew
                                                    }
                                                    setSelectedTemplate={
                                                        setSelectedTemplateNew
                                                    }
                                                    templateDescription={
                                                        templateDescription
                                                    }
                                                    setTemplateDescription={
                                                        setTemplateDescription
                                                    }
                                                    templateSheetName={
                                                        templateSheetName
                                                    }
                                                    setTemplateSheetName={
                                                        setTemplateSheetName
                                                    }
                                                    retrievedTemplateData={
                                                        retrievedTemplateData
                                                    }
                                                    setRetrievedTemplateData={
                                                        setRetrievedTemplateData
                                                    }
                                                    onTemplateDataChange={
                                                        setTemplateData
                                                    }
                                                    templateIsSuccessful={
                                                        templateIsSuccessful
                                                    }
                                                    setTemplateIsSuccessful={
                                                        setTemplateIsSuccessful
                                                    }
                                                />
                                            </Container>
                                        ) : (
                                            // Render TemplateManager if addNewTemplateMode is true
                                            <>
                                                {selectedLayout === null ? (
                                                    <BeforeSelectView />
                                                ) : (
                                                    <ReportLayoutsDesignerView
                                                        user={user}
                                                        selectedLayout={
                                                            selectedLayout
                                                        }
                                                        selectedTemplate={
                                                            selectedTemplate
                                                        }
                                                    />
                                                )}
                                            </>
                                        )}
                                    </div>
                                </ReflexElement>
                            </ReflexContainer>
                        </div>
                    </div>
                </ReflexElement>
            </ReflexContainer>
            {templateIsSuccessful && (
                <InformationModal
                    modalText={
                        'Template has been generated successfully. You can now access the template content from the sidebar and in settings or extract data using the same.'
                    }
                    close={'true'}
                    setModalClose={setTemplateIsSuccessful}
                />
            )}
        </div>
    );
};
