import React, { useState, useEffect } from 'react';

import 'react-reflex/styles.css';
import { ReflexContainer, ReflexElement, ReflexSplitter } from 'react-reflex';
import { CollapsedSideBar } from '../collapsedSideBar';
import {
    faComments,
    faFileExport,
    faGear,
    faGears,
    faUser,
} from '@fortawesome/free-solid-svg-icons';
import Navbar from '../navbar';
import { SettingsContainer } from './settingsContainer';
import { SettingsSideBar } from './settingsSidebar';
import TransactionList from '../Transactionlist';
import { useGlobalCredits } from '../GlobalStateContext';

export const SettingSections = Object.freeze({
    ACCOUNT: 'Account Settings',
    GENERAL: 'General Settings',
    CHATS: 'Chat Settings',
    EXTRACTION: 'Extraction Settings',
    ORGANIZATION: 'Organization Settings',
});

const scrollToSettings = (ref) => {
    if (ref && ref.current) {
        ref.current.scrollIntoView({ behavior: 'smooth' });
    }
};

export const SettingsView = ({
    activeTemplate,
    setActiveTemplate,
    templateList,
    setTemplateList,
    fetchTemplateList,
    newNotification,
    setNewNotification,
    viewNotifications,
    setViewNotifications,
    messages,
    socket,
    isAdmin,
    isSideViewExpanded,
    setIsSideViewExpanded,
}) => {
    const accountSettingsRef = React.createRef();
    const generalSettingsRef = React.createRef();
    const chatsSettingsRef = React.createRef();
    const extractionSettingsRef = React.createRef();
    const [isMobile, setIsMobile] = useState(false);
    const [activeSection, setActiveSection] = useState(null);
    const { transactionMode, setTransactionMode } = useGlobalCredits();
    const settingSectionsData = [
        {
            name: SettingSections.ACCOUNT,
            icon: faUser,
            hoverText: 'Account settings',
            ref: accountSettingsRef,
        },
        {
            name: SettingSections.GENERAL,
            icon: faGears,
            hoverText: 'Global basic settings',
            ref: generalSettingsRef,
        },
        {
            name: SettingSections.CHATS,
            icon: faComments,
            hoverText: 'Global Chat settings',
            ref: chatsSettingsRef,
        },
        {
            name: SettingSections.EXTRACTION,
            icon: faFileExport,
            hoverText: 'Global extraction settings',
            ref: extractionSettingsRef,
        },
    ];
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 1024); // Change 1024 to your desired breakpoint
        };

        handleResize(); // Initial check
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div className='z-0 w-[99vw] h-[99vh] m-auto pt-2'>
            <ReflexContainer orientation='vertical'>
                {!isMobile && (
                    <ReflexElement
                        className='left-pane mr-0.5'
                        minSize={isSideViewExpanded ? 250 : 50}
                        size={isSideViewExpanded ? 350 : 50}
                        maxSize={isSideViewExpanded ? 700 : 50}
                    >
                        <div className='pane-content w-full h-full bg-transparent'>
                            {isSideViewExpanded ? (
                                <div className='w-full h-full border border-2 overflow-auto bg-backgrounds-slate rounded-xl p-2 z-10'>
                                    <SettingsSideBar
                                        settingSections={settingSectionsData}
                                        activeSection={activeSection}
                                        setActiveSection={setActiveSection}
                                        setIsLeftViewExpanded={
                                            setIsSideViewExpanded
                                        }
                                        scrollToSettings={scrollToSettings}
                                    />
                                </div>
                            ) : (
                                <CollapsedSideBar
                                    icon={faGear}
                                    iconColor={'icons-primary'}
                                    title={'Setting Sections'}
                                    setIsRepoViewExpanded={
                                        setIsSideViewExpanded
                                    }
                                />
                            )}
                        </div>
                    </ReflexElement>
                )}
                <ReflexSplitter
                    style={{
                        border: 0,
                        background: 'white',
                        width: '6px',
                        zIndex: 10,
                        height: '98vh',
                    }}
                />

                <ReflexElement className='right-pane ml-0.5'>
                    <div className='pane-content w-full h-full bg-transparent'>
                        <div className='w-full h-full border border-2 overflow-auto bg-backgrounds-slate rounded-xl z-0'>
                            <ReflexContainer orientation='horizontal'>
                                <ReflexElement
                                    className='header'
                                    style={{
                                        minHeight: '4rem',
                                        height: '4rem',
                                        maxHeight: '4rem',
                                    }}
                                >
                                    <div className='pane-content w-full h-full p-2'>
                                        <Navbar
                                            settingSections={
                                                settingSectionsData
                                            }
                                            activeSection={activeSection}
                                            setActiveSection={setActiveSection}
                                            setIsLeftViewExpanded={
                                                setIsSideViewExpanded
                                            }
                                            scrollToSettings={scrollToSettings}
                                            //Navbar needs to be modified
                                            newNotification={newNotification}
                                            setNewNotification={
                                                setNewNotification
                                            }
                                            viewNotifications={
                                                viewNotifications
                                            }
                                            setViewNotifications={
                                                setViewNotifications
                                            }
                                            messages={messages}
                                            socket={socket}
                                        />
                                    </div>
                                </ReflexElement>

                                <ReflexElement flex={1}>
                                    <div className='pane-content h-full w-full p-2'>
                                        {transactionMode ? (
                                            <>
                                                <TransactionList />
                                            </>
                                        ) : (
                                            <SettingsContainer
                                                activeTemplate={activeTemplate}
                                                setActiveTemplate={
                                                    setActiveTemplate
                                                }
                                                templateList={templateList}
                                                setTemplateList={
                                                    setTemplateList
                                                }
                                                fetchTemplateList={
                                                    fetchTemplateList
                                                }
                                                accountSettingsRef={
                                                    accountSettingsRef
                                                }
                                                generalSettingsRef={
                                                    generalSettingsRef
                                                }
                                                chatsSettingsRef={
                                                    chatsSettingsRef
                                                }
                                                extractionSettingsRef={
                                                    extractionSettingsRef
                                                }
                                                setActiveSection={
                                                    setActiveSection
                                                }
                                                isAdmin={isAdmin}
                                            />
                                        )}
                                    </div>
                                </ReflexElement>
                            </ReflexContainer>
                        </div>
                    </div>
                </ReflexElement>
            </ReflexContainer>
        </div>
    );
};
