import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faArrowRight,
    faExpand,
    faFilePen,
    faHandPointLeft,
    faMobileAlt,
    faExclamationTriangle,
    faTableCellsLarge,
} from '@fortawesome/free-solid-svg-icons';

const BeforeSelectView = ({ isSmallScreen }) => {
    if (isSmallScreen) {
        return (
            <div className='bg-backgrounds-slate w-full h-full flex items-center justify-center'>
                <div className='flex flex-col items-center text-center text-texts-secondary p-4'>
                    <div className='relative'>
                        <FontAwesomeIcon
                            icon={faMobileAlt}
                            size='5x'
                            className='mb-4 text-icons-primary100'
                        />
                        <FontAwesomeIcon
                            icon={faExclamationTriangle}
                            size='2x'
                            className='mb-2 text-texts-red400 absolute top-4 left-3.5'
                        />
                    </div>

                    <h1 className='text-xl font-semibold'>
                        Page not available on small screen mode
                    </h1>
                    <p className='text-lg mt-2'>
                        Please switch to a larger screen to access this content.
                    </p>
                </div>
            </div>
        );
    }

    return (
        <div className='bg-backgrounds-slate w-full h-full rounded-xl flex items-center justify-center'>
            <div className='w-full h-full flex flex-col items-center'>
                <div className='flex h-4/6 w-full items-center justify-center text-texts-secondary300'>
                    <div className='flex flex-col items-center justify-center m-[3vw]'>
                        <FontAwesomeIcon
                            icon={faTableCellsLarge}
                            className='w-[8vw] h-[8vw] mb-2 drop-shadow text-icons-primary100'
                        />
                        <div>Select Dashboard</div>
                    </div>

                    <FontAwesomeIcon
                        icon={faArrowRight}
                        className='w-[5vw] h-[5vw] m-[3vw] drop-shadow text-icons-tertiary100'
                    />

                    <div className='flex flex-col items-center justify-center m-[3vw]'>
                        <FontAwesomeIcon
                            icon={faFilePen}
                            className='w-[8vw] h-[8vw] mb-2 drop-shadow text-icons-primary100'
                        />
                        <div>Design/Edit Dashboard</div>
                    </div>
                </div>
                <div className='flex items-center justify-center text-texts-secondary300 text-xl pt-10'>
                    <FontAwesomeIcon
                        icon={faHandPointLeft}
                        className='mr-3 text-icons-primary'
                    />
                    <div>
                        Select a dashboard from the Collection to start
                        designing/editing that dashboard
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BeforeSelectView;
