import React, { useEffect, useState } from 'react';
import { Input } from '../reusableComponents/input';
import { Button, Dropdown, NoInteractionView } from '../reusableComponents';
import { faSave, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { isEmailValid } from '../../utils';
import { OrganizationAccessOptions } from '../../constants/userAccessOptions';

import { getUrl } from '../pdfDisplay/utils';
import { OrganizationMemberListComponent } from '../shareRepository/userListComponent';
import { message } from 'antd';

export const ManageOrganizationMembers = ({
    user,
    setViewOrganizationModal,
}) => {
    const [newUserEmail, setNewUserEmail] = useState('');
    const [memberList, setMemberList] = useState([]);
    const [addedMemberList, setAddedMemberList] = useState([]);
    const [accessRights, setAccessRights] = useState(
        OrganizationAccessOptions.ORGANISATION_USER
    );
    const [enableSaving, setEnableSaving] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [usersToRemove, setUsersToRemove] = useState([]);
    const encodedToken = encodeURIComponent(user.token);

    async function getOrganizationMembers() {
        try {
            setIsLoading(true);
            const response = await fetch(
                getUrl(
                    `http://${process.env.REACT_APP_NODE_SERVER_HOST}:${process.env.REACT_APP_NODE_SERVER_PORT}/organization/members`
                ),
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: encodedToken,
                    },
                }
            );

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const result = await response.json();
            const membersData = result.members;

            // Sort members: admins first, then by email
            membersData.sort((a, b) => {
                if (
                    a.access_rights ===
                        OrganizationAccessOptions.ORGANISATION_ADMIN &&
                    b.access_rights !==
                        OrganizationAccessOptions.ORGANISATION_ADMIN
                )
                    return -1;
                if (
                    a.access_rights !==
                        OrganizationAccessOptions.ORGANISATION_ADMIN &&
                    b.access_rights ===
                        OrganizationAccessOptions.ORGANISATION_ADMIN
                )
                    return 1;
                return a.email.localeCompare(b.email);
            });

            setMemberList(membersData);
            setIsLoading(false);
        } catch (error) {
            message.error('Failed to fetch organization members');
            console.error('Error fetching organization members');
        }
    }

    useEffect(() => {
        getOrganizationMembers();
    }, []);

    if (isLoading || isSaving) {
        return (
            <NoInteractionView
                viewText={
                    isSaving
                        ? 'Saving organization members...'
                        : 'Loading organization members...'
                }
            />
        );
    }

    const addNewMember = () => {
        const newEmail = newUserEmail.toLowerCase();

        // Check if the user is already in the list
        const combinedMemberList = [...memberList, ...addedMemberList];
        for (let member of combinedMemberList) {
            if (member.email.toLowerCase() === newEmail) {
                alert('User already present in organization members list');
                return;
            }
        }

        if (user.email.toLowerCase() === newEmail) {
            alert("Can't add yourself!");
            return;
        }

        const newMemberData = {
            email: newEmail,
            access_rights: accessRights,
        };

        console.log(newMemberData);

        const updatedMemberList = [newMemberData, ...addedMemberList];
        setAddedMemberList(updatedMemberList);
        setNewUserEmail('');
        setEnableSaving(true);
    };

    const dropDownList = Object.entries(OrganizationAccessOptions).map(
        ([key, value], index) => ({
            key: index,
            label: value, // Display the actual access rights ("user" or "admin")
            disabled: false,
            selected: accessRights === value,
            action: () => {
                if (value && accessRights !== value) {
                    setAccessRights(value);
                }
            },
        })
    );

    const updateOrganizationList = async () => {
        try {
            const response = await fetch(
                getUrl(
                    `http://${process.env.REACT_APP_NODE_SERVER_HOST}:${process.env.REACT_APP_NODE_SERVER_PORT}/organization/manage-users`
                ),
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: encodedToken,
                    },
                    body: JSON.stringify({
                        users: [...addedMemberList, ...memberList],
                        usersToRemove: usersToRemove,
                    }),
                }
            );

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const result = await response.json();
            await getOrganizationMembers();
            setAddedMemberList([]);
            setUsersToRemove([]);
            setEnableSaving(false);

            return result;
        } catch (error) {
            message.error('Failed to update organization members');
            console.error('Error updating organization members:', error);
            throw error;
        }
    };

    const saveOrganizationMembers = async () => {
        setIsSaving(true);
        // Call the function to update the organization member list
        await updateOrganizationList();
        setIsSaving(false);
        setViewOrganizationModal(false);
    };

    return (
        <div className='w-[90vw] min-w-[90px] md:w-[60vw] md:min-w-[500px] flex-col p-2 overflow-y-auto'>
            <div className='flex border-b-2 border-white justify-center items-center pt-3 pb-4'>
                <Input
                    type={'email'}
                    placeholder={'Email'}
                    value={newUserEmail}
                    className={'mr-3 w-full truncate'}
                    onChange={(e) => setNewUserEmail(e.target.value)}
                />
                <Dropdown
                    dropDownList={dropDownList}
                    showButtonText={true}
                    buttonText={accessRights}
                    buttonHoverText={'Change access rights of user'}
                    textColor={
                        accessRights ===
                        OrganizationAccessOptions.ORGANISATION_ADMIN
                            ? 'backgrounds-settings100'
                            : 'icons-primary'
                    }
                    backgroundColor={
                        accessRights ===
                        OrganizationAccessOptions.ORGANISATION_ADMIN
                            ? 'backgrounds-settings100'
                            : 'icons-primary'
                    }
                    buttonClassName={'mr-3 ml-3 w-24'}
                    listClassName={''}
                    disabled={false}
                />
                <Button
                    className=''
                    text={'Add'}
                    onClick={(event) => {
                        addNewMember();
                        event.stopPropagation();
                    }}
                    disabled={!isEmailValid(newUserEmail)}
                    hoverText={'Add new member to organization'}
                    icon={faUserPlus}
                    type={'success'}
                />
            </div>

            {addedMemberList && addedMemberList.length !== 0 && (
                <div className='border-b-2 border-white'>
                    <div className='flex justify-between items-center font-semibold mt-2 px-2 py-2 text-texts-secondary300'>
                        <div>New Members</div>
                        <div className='mr-2'>Actions</div>
                    </div>

                    <div className='max-h-48 overflow-auto w-full'>
                        <OrganizationMemberListComponent
                            memberList={addedMemberList}
                            stateAction={setAddedMemberList}
                            enableSaving={enableSaving}
                            setEnableSaving={setEnableSaving}
                        />
                    </div>
                </div>
            )}

            <div>
                <div className='flex justify-between items-center font-semibold mt-2 px-2 py-2 text-texts-secondary300'>
                    <div>Existing Members</div>
                    <div className='mr-2'>Actions</div>
                </div>

                <div className='max-h-52 overflow-auto w-full'>
                    <OrganizationMemberListComponent
                        memberList={memberList}
                        stateAction={setMemberList}
                        enableSaving={enableSaving}
                        setEnableSaving={setEnableSaving}
                        setUsersToRemove={setUsersToRemove}
                        currentUserEmail={user.email}
                    />
                </div>
            </div>

            <div className='flex border-t-2 border-white pt-2 justify-end items-center'>
                <Button
                    className='whitespace-nowrap overflow-hidden text-ellipsis w-32'
                    text={addedMemberList.length ? 'Save' : 'Update'}
                    onClick={async (event) => {
                        await saveOrganizationMembers();
                        event.stopPropagation();
                    }}
                    hoverText={'Save organization members'}
                    icon={faSave}
                    type={'success'}
                    disabled={!enableSaving}
                />
            </div>
        </div>
    );
};
