import React, { useEffect, useRef, useState } from 'react';
import Viewer from 'react-viewer';
import './styles.css';

// Custom toolbar function
const customToolbar = (defaultToolbars) => {
    // Filter out "Next Image" and "Previous Image" buttons
    return defaultToolbars.filter(
        (toolbar) => toolbar.key !== 'prev' && toolbar.key !== 'next'
    );
};

export const ImageViewer = ({ fileName, fileUrl }) => {
    console.log(fileName, fileUrl);
    const [images, setImages] = useState([]);
    const containerRef = useRef(null); // Create a React ref for the container

    useEffect(() => {
        if (fileName && fileUrl) {
            const imagesData = [
                {
                    src: fileUrl,
                    alt: fileName,
                },
            ];

            setImages(imagesData);
        }
    }, [fileName, fileUrl]);

    return (
        <div className='w-full h-full'>
            <div ref={containerRef} className='container' />

            {/* Viewer */}
            {containerRef && containerRef.current && (
                <Viewer
                    visible={true}
                    // onClose={closeViewer}
                    images={images}
                    // activeIndex={activeIndex}
                    scalable // Enables zooming
                    zoomable // Enables zoom controls
                    rotatable // Enables rotation
                    changeable // Allows switching between images
                    noNavbar={false} // Show navigation bar
                    container={containerRef.current} // Use ref.current for the container
                    attribute={false} // Show image attribute
                    customToolbar={customToolbar}
                />
            )}
        </div>
    );
};
