import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import {
    getFileFormat,
    getFileIcon,
    getFileIconColor,
    isImageFile,
} from '../utils';
import { developerMode } from '../Constants';
import { Button, Modal } from './reusableComponents';
import {
    faArrowUpRightFromSquare,
    faBugSlash,
    faComments,
    faFile,
} from '@fortawesome/free-solid-svg-icons';
import { useGlobalCredits } from './GlobalStateContext';
import { DebugViewerContainer } from './debugViewerContainer';

export const FilesListView = ({
    repo,
    allReposFilesData,
    setWorkOnRepo,
    repoId,
    email,
    chatrepofunction,
    folderName,
    workOnRepo,
    mode,
    fileToChat,
    setFileToChat,
    isAdmin,
    closeSidebar,
    togglePdfVisibility,
}) => {
    const [showDebugModal, setShowDebugModal] = useState(false);
    const [fileName, setFileName] = useState(null);
    const { transactionMode, setTransactionMode } = useGlobalCredits();
    const BREAKPOINT_WIDTH = 1024;

    const repoFileData =
        allReposFilesData && allReposFilesData.size
            ? allReposFilesData.get(repoId)
            : null;

    if (repoFileData) {
        const isExpanded = repoFileData.isExpanded;
        const filesData = repoFileData.filesData;

        const list = filesData.map((item, index) => {
            const extension = getFileFormat(item.name);
            const isImage = isImageFile(extension);

            return (
                <div
                    key={`${item.document_id} ${index}`}
                    className='flex justify-between items-end'
                >
                    <div className='truncate pt-5 border-l-2 pr-2'>
                        <span
                            className='mr-2 font-bold'
                            style={{ color: ' #e6e6e8' }}
                        >
                            -
                        </span>
                        <FontAwesomeIcon
                            icon={getFileIcon(item.name)}
                            color={getFileIconColor(item.name)}
                            className='mr-2 drop-shadow-sm'
                        />
                        <span className='truncate text-base'>{item.name}</span>
                    </div>
                    {item.name.endsWith('.db') ? (
                        <div></div>
                    ) : (
                        <div className='pt-1 flex justify-centre items-centre'>
                            {developerMode && isAdmin && !isImage ? (
                                <Button
                                    className='ml-2'
                                    onClick={(event) => {
                                        setFileName(item.name);
                                        setShowDebugModal(true);
                                        event.stopPropagation();
                                    }}
                                    hoverText={
                                        "Debug file's chapter, chunks and table"
                                    }
                                    icon={faBugSlash}
                                    type={'info'}
                                />
                            ) : (
                                <Button
                                    className='ml-2'
                                    onClick={(event) => {
                                        setFileName(item.name);
                                        setShowDebugModal(true);
                                        event.stopPropagation();
                                    }}
                                    hoverText={'View the file'}
                                    icon={faArrowUpRightFromSquare}
                                    type={'info'}
                                />
                            )}
                            {item.name.endsWith('.db') ||
                            mode === 'info' ? null : workOnRepo ? (
                                <Button
                                    className='ml-2'
                                    onClick={(event) => {
                                        if (transactionMode) {
                                            setTransactionMode(
                                                !transactionMode
                                            );
                                        }
                                        setFileToChat(item.name);
                                        setWorkOnRepo(repoId);
                                        chatrepofunction(repo);
                                        event.stopPropagation();
                                        if (
                                            window.innerWidth < BREAKPOINT_WIDTH
                                        ) {
                                            togglePdfVisibility('reset');
                                            closeSidebar(); // Call the closeSidebar function passed from navbar.jsx
                                        }
                                    }}
                                    hoverText={
                                        'Question and answer on particular file'
                                    }
                                    icon={faComments}
                                    type={'success'}
                                />
                            ) : (
                                <Button
                                    className='ml-2'
                                    onClick={(event) => {
                                        if (transactionMode) {
                                            setTransactionMode(
                                                !transactionMode
                                            );
                                        }
                                        event.stopPropagation();
                                        if (
                                            window.innerWidth < BREAKPOINT_WIDTH
                                        ) {
                                            togglePdfVisibility('reset');
                                            closeSidebar(); // Call the closeSidebar function passed from navbar.jsx
                                        }
                                    }}
                                    hoverText={
                                        'Question and answer on particular file (first click on repo name)'
                                    }
                                    icon={faComments}
                                    type={'success'}
                                />
                            )}
                        </div>
                    )}
                </div>
            );
        });

        const extension = getFileFormat(fileName);
        const isImage = isImageFile(extension);

        if (isExpanded) {
            return (
                <div>
                    <div
                        className={`${
                            workOnRepo === repoId
                                ? 'bg-backgrounds-primary100'
                                : 'bg-backgrounds-white'
                        } rounded-b-xl pl-4 pr-2 pb-3 truncate shadow-sm border`}
                    >
                        {list}
                    </div>
                    {showDebugModal && (
                        <Modal
                            title={
                                isAdmin && !isImage
                                    ? 'Debug Diagnostics'
                                    : 'File viewer'
                            }
                            titleIcon={
                                isAdmin && !isImage ? faBugSlash : faFile
                            }
                            iconColor={'sky'}
                            onClose={() => setShowDebugModal(false)}
                            className={''}
                        >
                            <DebugViewerContainer
                                repoId={repoId}
                                email={email}
                                folderName={folderName}
                                fileName={fileName}
                                mode={mode}
                                isAdmin={isAdmin}
                            />
                        </Modal>
                    )}
                </div>
            );
        }

        return <></>;
    }
};
