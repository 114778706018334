import React, { useState } from 'react';
import { Button, TextArea } from '../reusableComponents';
import { getUrl } from '../pdfDisplay/utils';

export const CreateOrganizationForm = ({ onClose, onSuccess }) => {
    const [name, setName] = useState('');
    const [website, setWebsite] = useState('');
    const [address, setAddress] = useState('');
    const [industry, setIndustry] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        setError('');

        try {
            if (name.trim() === '') {
                setError('Organization name cannot be empty');
                setIsLoading(false);
                return;
            }

            const user = JSON.parse(localStorage.getItem('current'));
            const response = await fetch(
                getUrl(
                    `http://${process.env.REACT_APP_NODE_SERVER_HOST}:${process.env.REACT_APP_NODE_SERVER_PORT}/organization/create`
                ),
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: encodeURIComponent(user.token),
                    },
                    body: JSON.stringify({
                        name,
                        website,
                        address,
                        industry,
                    }),
                }
            );

            const data = await response.json();

            if (!response.ok) {
                throw new Error(
                    data.message || 'Failed to create organization'
                );
            }

            // Call the success callback instead of reloading
            if (onSuccess) {
                onSuccess(data);
            }
            onClose();
        } catch (err) {
            setError(err.message);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className='p-6'>
            {' '}
            {/* Add padding around the entire form */}
            <form onSubmit={handleSubmit} className='space-y-6'>
                {' '}
                {/* Increased spacing between elements */}
                <div className='space-y-2'>
                    <label className='block text-sm font-medium text-gray-700'>
                        Organization Name *
                    </label>
                    <input
                        type='text'
                        required
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        className='mt-1 block w-full rounded-xl border p-3 focus:outline-backgrounds-primary300' // Increased padding
                    />
                </div>
                <div className='space-y-2'>
                    <label className='block text-sm font-medium text-gray-700'>
                        Website
                    </label>
                    <input
                        type='url'
                        value={website}
                        onChange={(e) => setWebsite(e.target.value)}
                        className='mt-1 block w-full rounded-xl border p-3 focus:outline-backgrounds-primary300' // Increased padding
                    />
                </div>
                <div className='space-y-2'>
                    <label className='block text-sm font-medium text-gray-700'>
                        Address
                    </label>
                    <TextArea
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                        className='mt-1 block w-full rounded-xl border p-3 focus:outline-backgrounds-primary300' // Increased padding
                    />
                </div>
                <div className='space-y-2'>
                    <label className='block text-sm font-medium text-gray-700'>
                        Industry
                    </label>
                    <input
                        type='text'
                        value={industry}
                        onChange={(e) => setIndustry(e.target.value)}
                        className='mt-1 block w-full rounded-xl border p-3 focus:outline-backgrounds-primary300' // Increased padding
                    />
                </div>
                {error && <div className='text-red-500 text-sm'>{error}</div>}
                <div className='flex justify-end space-x-4 mt-6'>
                    {' '}
                    {/* Increased spacing and added margin */}
                    <Button text='Cancel' onClick={onClose} type='danger' />
                    <Button
                        text={isLoading ? 'Creating...' : 'Create Organization'}
                        type='success'
                        disabled={isLoading}
                        submit={true}
                    />
                </div>
            </form>
        </div>
    );
};
